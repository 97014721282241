# ./frontend/src/views/AdminSellersView.vue
<template>
  <v-container class="pa-md-12">
    <section>
      <h3 class="d-flex justify-space-between align-center text-subtitle-1 font-weight-bold">
        Sellers
        <v-btn
          class="text-none"
          color="primary"
          prepend-icon="mdi-plus"
          rounded="lg"
          slim
          text="Add New Seller"
          variant="flat"
          @click="openDialog"
        />
      </h3>
      <div class="text-body-2 text-medium-emphasis mb-4 w-100 w-md-75">
        A list of all the sellers registered in the system.
      </div>
      <v-data-table
        class="bg-transparent"
        :headers="headers"
        :items="sellers"
        :items-per-page="10"
      >
        <template #[`item.isActive`]="{ item }">
          <v-switch
            v-model="item.isActive"
            @change="updateSellerStatus(item)"
          ></v-switch>
        </template>
        <template #[`item.userId`]="{ item }">
          {{ getUserInfo(item.userId) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            class="text-none mr-2"
            color="primary"
            min-width="0"
            slim
            text="Edit"
            variant="text"
            @click="editItem(item)"
          />
          <v-btn
            class="text-none"
            color="error"
            min-width="0"
            slim
            text="Delete"
            variant="text"
            @click="deleteItem(item)"
          />
        </template>
      </v-data-table>
    </section>

    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="save" ref="form">
            <v-text-field 
              v-model="editedItem.name" 
              label="Name" 
              :rules="[v => !!v || 'Name is required']"
              required
              @input="generateUrl"
            ></v-text-field>

            <v-text-field 
              v-model="editedItem.url" 
              label="URL" 
              :rules="[
                v => !!v || 'URL is required',
                v => /^[a-z0-9-]+$/.test(v) || 'URL can only contain lowercase letters, numbers, and hyphens',
                v => !urlExists || 'This URL is already taken'
              ]"
              required
              hint="URL will be automatically generated from name, but can be customized"
              persistent-hint
              @input="checkUrlAvailability"
            ></v-text-field>
            
            <v-text-field 
              v-model="editedItem.type" 
              label="Type" 
              :rules="[v => !!v || 'Type is required']" 
              required
            ></v-text-field>
            
            <v-text-field 
              v-model="editedItem.email" 
              label="Email" 
              :rules="[v => !!v || 'Email is required']" 
              required
            ></v-text-field>
            
            <v-select
              v-model="editedItem.userId"
              :items="userOptions"
              item-title="name"
              item-value="value"
              label="Associated User"
              @change="handleUserChange"
            ></v-select>

            <!-- Profile Image Upload -->
            <v-file-input
              v-model="profileImage"
              label="Profile Image"
              accept="image/*"
              @change="onProfileImageChange"
              hint="Upload a profile image for the seller"
              persistent-hint
            ></v-file-input>
            <v-img 
              v-if="profileImagePreview" 
              :src="profileImagePreview" 
              max-width="200" 
              max-height="200" 
              class="mt-2 mb-4"
            ></v-img>
            <v-btn 
              v-if="profileImagePreview" 
              color="error" 
              @click="removeProfileImage"
              class="mb-4"
            >
              Remove Profile Image
            </v-btn>

            <!-- Cover Photo Upload -->
            <v-file-input
              v-model="coverPhoto"
              label="Cover Photo"
              accept="image/*"
              @change="onCoverPhotoChange"
              class="mt-4"
            ></v-file-input>
            <v-img 
              v-if="coverPhotoPreview" 
              :src="coverPhotoPreview" 
              max-width="200" 
              max-height="200" 
              class="mt-2"
            ></v-img>
            <v-btn 
              v-if="coverPhotoPreview" 
              color="error" 
              @click="removeCoverPhoto"
              class="mb-4"
            >
              Remove Cover Photo
            </v-btn>

            <!-- Location Information -->
            <v-text-field v-model="editedItem.location.street" label="Street"></v-text-field>
            <v-text-field v-model="editedItem.location.unit" label="Unit"></v-text-field>
            <v-text-field v-model="editedItem.location.city" label="City"></v-text-field>
            <v-text-field v-model="editedItem.location.provinceTerritory" label="Province/Territory"></v-text-field>
            <v-text-field v-model="editedItem.location.postalCode" label="Postal Code"></v-text-field>
            <v-text-field v-model="editedItem.location.phoneNumber" label="Phone Number"></v-text-field>
            <v-switch v-model="editedItem.location.visible" label="Location Visible"></v-switch>
            
            <v-textarea v-model="editedItem.description" label="Description"></v-textarea>
            <v-combobox v-model="editedItem.bulkProducts" label="Bulk Products" multiple chips small-chips></v-combobox>
            
            <v-switch v-model="editedItem.pickupAvailable" label="Pickup Available"></v-switch>
            <v-switch v-model="editedItem.deliveryAvailable" label="Delivery Available"></v-switch>

            <!-- Pickup Schedule Section -->
            <div v-if="editedItem.pickupAvailable">
              <v-divider class="my-4"></v-divider>
              <v-subheader>Pickup Schedule</v-subheader>
              <div v-if="editedItem.pickupSchedule && editedItem.pickupSchedule.length > 0">
                <v-list>
                  <v-list-item v-for="(schedule, index) in editedItem.pickupSchedule" :key="index">
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="schedule.pickupDay" label="Day"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field v-model="schedule.pickupStartTime" label="Start Time"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field v-model="schedule.pickupEndTime" label="End Time"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="schedule.pickupLocation" label="Location"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="schedule.pickupInstructions" label="Instructions"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon color="error" @click="removePickupSchedule(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
              <v-btn color="primary" @click="addPickupSchedule" class="mb-4">Add Pickup Schedule</v-btn>
            </div>

            <!-- Delivery Schedule Section -->
            <div v-if="editedItem.deliveryAvailable">
              <v-divider class="my-4"></v-divider>
              <v-subheader>Delivery Schedule</v-subheader>
              <div v-if="editedItem.deliverySchedule && editedItem.deliverySchedule.length > 0">
                <v-list>
                  <v-list-item v-for="(schedule, index) in editedItem.deliverySchedule" :key="index">
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="schedule.dropOffDay" label="Drop-off Day"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field v-model="schedule.dropOffStartTime" label="Start Time"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field v-model="schedule.dropOffEndTime" label="End Time"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="schedule.dropOffLocation" label="Location"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="schedule.deliveryContactPhoneNumber" label="Contact Phone"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="schedule.readyForDeliveryDay" label="Ready for Delivery Day"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon color="error" @click="removeDeliverySchedule(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
              <v-btn color="primary" @click="addDeliverySchedule" class="mb-4">Add Delivery Schedule</v-btn>
            </div>

            <v-switch v-model="editedItem.allowFarmTours" label="Allow Farm Tours"></v-switch>
            <v-switch v-model="editedItem.isActive" label="Is Active"></v-switch>

            <!-- Other Images Section -->
            <v-subheader>Other Images</v-subheader>
            <v-btn small color="secondary" @click="addOtherImageInput" class="mb-2">Add Image</v-btn>
            <div v-for="(image, index) in otherImages" :key="index" class="mb-2">
              <v-file-input
                v-model="otherImages[index]"
                label="Other Image"
                accept="image/*"
                @change="(event) => onOtherImageChange(event, index)"
              ></v-file-input>
              <v-img 
                v-if="otherImagesPreview[index]" 
                :src="otherImagesPreview[index]" 
                max-width="100" 
                max-height="100"
              ></v-img>
              <v-btn small color="error" @click="removeOtherImage(index)">Remove</v-btn>
            </div>

            <v-subheader>Existing Other Images</v-subheader>
            <div v-for="(imageUrl, index) in editedItem.images" :key="'existing-'+index" class="mb-2">
              <v-img :src="imageUrl" max-width="100" max-height="100"></v-img>
              <v-btn small color="error" @click="removeExistingOtherImage(index)">Remove</v-btn>
            </div>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
  name: 'AdminSellersView',
  
  setup() {
    const store = useStore();
    const dialog = ref(false);
    const urlExists = ref(false);
    const urlCheckTimeout = ref(null);
    const form = ref(null);

    const headers = [
      { title: 'Name', key: 'name' },
      { title: 'Type', key: 'type' },
      { title: 'URL', key: 'url' },
      { title: 'Associated User', key: 'userId' },
      { title: 'Location', key: 'location.city' },
      { title: 'Is Active', key: 'isActive' },
      { title: 'Actions', key: 'actions', sortable: false }
    ];

    const editedIndex = ref(-1);
    const profileImage = ref(null);
    const profileImagePreview = ref('');
    const profileImageToDelete = ref(null);
    const coverPhoto = ref(null);
    const coverPhotoPreview = ref('');
    const otherImages = ref([]);
    const otherImagesPreview = ref([]);
    const imageToDelete = ref(null);
    const otherImagesToDelete = ref([]);

    const editedItem = ref({
      name: '',
      url: '',
      type: '',
      email: '',
      userId: null,
      profileImage: '',
      location: {
        street: '',
        unit: '',
        city: '',
        provinceTerritory: '',
        postalCode: '',
        phoneNumber: '',
        visible: true,
        localDeliveryZone: null
      },
      description: '',
      bulkProducts: [],
      events: [],
      pickupAvailable: false,
      deliveryAvailable: false,
      pickupSchedule: [],
      deliverySchedule: [],
      allowFarmTours: false,
      isActive: false,
      images: []
    });

    const defaultItem = { ...editedItem.value };

    const formTitle = computed(() => editedIndex.value === -1 ? 'New Seller' : 'Edit Seller');
    const sellers = computed(() => store.state.sellers);
    const users = computed(() => store.state.users);

    const userOptions = computed(() => [
      { name: 'None', value: null },
      ...users.value.map(user => ({ name: user.name, value: user._id }))
    ]);

    const addPickupSchedule = () => {
      if (!editedItem.value.pickupSchedule) {
        editedItem.value.pickupSchedule = [];
      }
      editedItem.value.pickupSchedule.push({
        pickupDay: '',
        pickupStartTime: '',
        pickupEndTime: '',
        pickupLocation: '',
        pickupInstructions: ''
      });
    };

    const removePickupSchedule = (index) => {
      editedItem.value.pickupSchedule.splice(index, 1);
    };

    const addDeliverySchedule = () => {
      if (!editedItem.value.deliverySchedule) {
        editedItem.value.deliverySchedule = [];
      }
      editedItem.value.deliverySchedule.push({
        dropOffDay: '',
        dropOffStartTime: '',
        dropOffEndTime: '',
        dropOffLocation: '',
        deliveryContactPhoneNumber: '',
        readyForDeliveryDay: ''
      });
    };

    const removeDeliverySchedule = (index) => {
      editedItem.value.deliverySchedule.splice(index, 1);
    };

    const generateUrl = () => {
      if (editedItem.value.name && !editedItem.value.url) {
        const url = editedItem.value.name
          .toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^a-z0-9-]/g, '');
        editedItem.value.url = url;
        checkUrlAvailability();
      }
    };

    const checkUrlAvailability = async () => {
      if (urlCheckTimeout.value) {
        clearTimeout(urlCheckTimeout.value);
      }

      urlCheckTimeout.value = setTimeout(async () => {
        if (!editedItem.value.url) {
          urlExists.value = false;
          return;
        }

        try {
          const response = await axios.get(`${store.state.apiUrl}/sellers/check-url/${editedItem.value.url}`);
          urlExists.value = !response.data.available;
        } catch (error) {
          console.error('Error checking URL availability:', error);
        }
      }, 500);
    };

    const getUserInfo = (userId) => {
      const user = users.value.find(u => u._id === userId);
      return user ? `${user.name} (${user.email})` : 'N/A';
    };

    const onProfileImageChange = (event) => {
      if (event && event.target && event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        profileImage.value = file;
        profileImagePreview.value = URL.createObjectURL(file);
      } else {
        profileImage.value = null;
        profileImagePreview.value = '';
      }
    };

    const removeProfileImage = () => {
      if (editedItem.value.profileImage) {
        profileImageToDelete.value = editedItem.value.profileImage;
      }
      profileImage.value = null;
      profileImagePreview.value = '';
      editedItem.value.profileImage = '';
    };

    const onCoverPhotoChange = (event) => {
      if (event && event.target && event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        coverPhoto.value = file;
        coverPhotoPreview.value = URL.createObjectURL(file);
      } else {
        coverPhoto.value = null;
        coverPhotoPreview.value = '';
      }
    };

    const removeCoverPhoto = () => {
      if (editedItem.value.coverPhoto) {
        imageToDelete.value = editedItem.value.coverPhoto;
      }
      coverPhoto.value = null;
      coverPhotoPreview.value = '';
      editedItem.value.coverPhoto = '';
    };

    const addOtherImageInput = () => {
      otherImages.value.push(null);
      otherImagesPreview.value.push(null);
    };

    const onOtherImageChange = (event, index) => {
      if (event && event.target && event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        otherImages.value[index] = file;
        otherImagesPreview.value[index] = URL.createObjectURL(file);
      } else {
        otherImages.value[index] = null;
        otherImagesPreview.value[index] = null;
      }
    };

    const removeOtherImage = (index) => {
      otherImages.value.splice(index, 1);
      otherImagesPreview.value.splice(index, 1);
    };

    const removeExistingOtherImage = (index) => {
      const removedImage = editedItem.value.images[index];
      otherImagesToDelete.value.push(removedImage);
      editedItem.value.images.splice(index, 1);
    };

    const handleUserChange = async (userId) => {
      if (userId) {
        try {
          const existingSeller = await store.dispatch('fetchSellersByUserID', userId);
          if (existingSeller.length > 0) {
            alert('This user is already associated with a seller. Please choose a different user.');
            editedItem.value.userId = null;
          }
        } catch (error) {
          console.error('Error checking existing seller:', error);
        }
      }
    };

    const openDialog = () => {
      editedIndex.value = -1;
      editedItem.value = Object.assign({}, defaultItem);
      dialog.value = true;
    };

    const editItem = (item) => {
      editedIndex.value = sellers.value.indexOf(item);
      editedItem.value = Object.assign({}, item);
      profileImagePreview.value = editedItem.value.profileImage || '';
      coverPhotoPreview.value = editedItem.value.coverPhoto || '';
      dialog.value = true;
    };

    const close = () => {
      dialog.value = false;
      editedItem.value = Object.assign({}, defaultItem);
      editedIndex.value = -1;
      profileImage.value = null;
      profileImagePreview.value = '';
      profileImageToDelete.value = null;
      coverPhoto.value = null;
      coverPhotoPreview.value = '';
      otherImages.value = [];
      otherImagesPreview.value = [];
      imageToDelete.value = null;
      otherImagesToDelete.value = [];
    };

    const save = async () => {
      try {
        const formData = new FormData();
        formData.append('seller', JSON.stringify(editedItem.value));
        
        if (profileImage.value) {
          formData.append('profileImage', profileImage.value);
        }
        if (coverPhoto.value) {
          formData.append('coverPhoto', coverPhoto.value);
        }
        otherImages.value.forEach((image, index) => {
          if (image) {
            formData.append('otherImages', image);
          }
        });
        
        if (profileImageToDelete.value) {
          formData.append('profileImageToDelete', profileImageToDelete.value);
        }
        if (imageToDelete.value) {
          formData.append('imageToDelete', imageToDelete.value);
        }
        if (otherImagesToDelete.value.length > 0) {
          formData.append('otherImagesToDelete', JSON.stringify(otherImagesToDelete.value));
        }

        if (editedIndex.value > -1) {
          const oldSeller = sellers.value[editedIndex.value];
          await store.dispatch('updateSeller', { 
            id: editedItem.value._id, 
            formData, 
            oldUserId: oldSeller.userId 
          });
        } else {
          await store.dispatch('createSeller', formData);
        }
        close();
        await store.dispatch('fetchSellers');
      } catch (error) {
        console.error('Error saving seller:', error);
      }
    };

    const deleteItem = async (item) => {
      if (confirm('Are you sure you want to delete this seller?')) {
        try {
          await store.dispatch('deleteSeller', item._id);
          await store.dispatch('fetchSellers');
        } catch (error) {
          console.error('Error deleting seller:', error);
        }
      }
    };

    const updateSellerStatus = async (item) => {
      try {
        const formData = new FormData();
        formData.append('seller', JSON.stringify({ isActive: item.isActive }));
        await store.dispatch('updateSeller', { id: item._id, formData });
        await store.dispatch('fetchSellers');
      } catch (error) {
        console.error('Error updating seller status:', error);
      }
    };

    onMounted(async () => {
      await store.dispatch('fetchSellers');
      await store.dispatch('fetchUsers');
    });

    return {
      dialog,
      headers,
      editedIndex,
      editedItem,
      formTitle,
      sellers,
      users,
      userOptions,
      form,
      profileImage,
      profileImagePreview,
      coverPhoto,
      coverPhotoPreview,
      otherImages,
      otherImagesPreview,
      urlExists,
      openDialog,
      editItem,
      close,
      save,
      deleteItem,
      updateSellerStatus,
      onProfileImageChange,
      removeProfileImage,
      onCoverPhotoChange,
      removeCoverPhoto,
      addOtherImageInput,
      onOtherImageChange,
      removeOtherImage,
      removeExistingOtherImage,
      imageToDelete,
      otherImagesToDelete,
      handleUserChange,
      getUserInfo,
      generateUrl,
      checkUrlAvailability,
      addPickupSchedule,
      removePickupSchedule,
      addDeliverySchedule,
      removeDeliverySchedule
    };
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>