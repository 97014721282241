import axios from '@/utils/axios';

const state = {
  currentLocation: null,
  lat: null,
  lng: null,
  localShippingZone: null,
  locationError: null,
  isLoadingLocation: false,
  showLocationDialog: false
};

const mutations = {
  SET_LOCATION(state, { location, lat, lng, localShippingZone }) {
    state.currentLocation = location;
    state.lat = lat;
    state.lng = lng;
    state.localShippingZone = localShippingZone;
    state.locationError = null;
  },
  SET_LOCATION_ERROR(state, error) {
    state.locationError = error;
  },
  SET_LOADING_LOCATION(state, isLoading) {
    state.isLoadingLocation = isLoading;
  },
  CLEAR_LOCATION(state) {
    state.currentLocation = null;
    state.lat = null;
    state.lng = null;
    state.localShippingZone = null;
    state.locationError = null;
  },
  SET_LOCATION_DIALOG(state, value) {
    state.showLocationDialog = value;
  }
};

const actions = {
  async setLocation({ commit }, { location, lat, lng }) {
    commit('SET_LOADING_LOCATION', true);
    try {
      if (!location) {
        throw new Error('Location is required');
      }

      let localShippingZone = null;
      if (location.match(/^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/)) {
        const rsaCode = location.substring(0, 3).toUpperCase();
        const response = await axios.get(`/api/municipalities/by-rsa/${rsaCode}`);
        if (response.data?.localShippingZone) {
          // Store only the ID instead of the full object
          localShippingZone = response.data.localShippingZone._id;
        }
      } else {
        const [city, province] = location.split(',').map(part => part.trim());
        const response = await axios.get('/api/municipalities/find', {
          params: { 
            name: city,
            province
          }
        });
        
        if (response.data?.localShippingZone) {
          // Store only the ID instead of the full object
          localShippingZone = response.data.localShippingZone._id;
        }
      }

      const locationData = {
        location,
        lat,
        lng,
        localShippingZone
      };

      commit('SET_LOCATION', locationData);
      localStorage.setItem('userLocation', JSON.stringify(locationData));

      return localShippingZone;

    } catch (error) {
      console.error('Error setting location:', error);
      commit('SET_LOCATION_ERROR', error.message || 'Error setting location');
      throw error;
    } finally {
      commit('SET_LOADING_LOCATION', false);
    }
  },

  async updateLocalShippingZone({ commit, state }) {
    if (!state.currentLocation) return null;

    try {
      const rsaCode = state.currentLocation.substring(0, 3).toUpperCase();
      const response = await axios.get(`/api/municipalities/by-rsa/${rsaCode}`);
      // Store only the ID instead of the full object
      const localShippingZone = response.data?.localShippingZone?._id || null;

      const locationData = {
        location: state.currentLocation,
        lat: state.lat,
        lng: state.lng,
        localShippingZone
      };

      commit('SET_LOCATION', locationData);
      localStorage.setItem('userLocation', JSON.stringify(locationData));

      return localShippingZone;
    } catch (error) {
      console.error('Error updating shipping zone:', error);
      return null;
    }
  },

  clearLocation({ commit }) {
    commit('CLEAR_LOCATION');
    localStorage.removeItem('userLocation');
  },

  initializeLocation({ commit }) {
    try {
      const savedLocation = localStorage.getItem('userLocation');
      if (savedLocation) {
        const locationData = JSON.parse(savedLocation);
        commit('SET_LOCATION', locationData);
      }
    } catch (error) {
      console.error('Error initializing location:', error);
      localStorage.removeItem('userLocation');
    }
  },

  openLocationDialog({ commit }) {
    commit('SET_LOCATION_DIALOG', true);
  },

  closeLocationDialog({ commit }) {
    commit('SET_LOCATION_DIALOG', false);
  }
};

const getters = {
  getCurrentLocation: state => state.currentLocation,
  getCoordinates: state => ({
    lat: state.lat,
    lng: state.lng
  }),
  getLocalShippingZone: state => state.localShippingZone,
  hasLocation: state => !!state.currentLocation,
  locationError: state => state.locationError,
  isLoadingLocation: state => state.isLoadingLocation,
  showLocationDialog: state => state.showLocationDialog
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};