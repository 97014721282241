<!-- ./frontend/src/components/ProductCard.vue -->
<template>
  <v-card flat class="h-100">
    <div class="product-image-container">
      <v-img
        :src="product.productImage"
        height="200"
        cover
        class="rounded"
        @click="navigateToProduct"
      >
        <template v-if="cartQuantity > 0">
          <div class="cart-controls d-flex flex-column align-start" @click.stop>
            <v-chip
              v-if="showBulkDiscount"
              color="error"
              size="x-small"
              variant="flat"
              @click.stop="handleBulkAdd"
              class="bulk-chip mb-1"
            >
              <v-icon start icon="mdi-tag" size="x-small"/>
              Add {{ amountUntilNextDiscount }} to save ${{ formatPrice(nextDiscountMinSavings) }}
            </v-chip>
            <v-btn
              color="white"
              class="cart-pill"
              variant="flat"
              density="comfortable"
            >
              <v-icon
                :icon="cartQuantity === 1 ? 'mdi-trash-can' : 'mdi-minus'"
                color="white"
                @click.stop="handleQuantityDecrease"
                class="control-icon"
              />
              <span class="mx-6 text-white">{{ cartQuantity }}</span>
              <v-icon
                icon="mdi-plus"
                color="white"
                @click.stop="handleQuantityIncrease"
                class="control-icon"
              />
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div class="cart-controls" @click.stop>
            <v-btn
              variant="text"
              icon
              class="add-btn"
              density="comfortable"
              color="primary"
              @click.stop="handleAddToCart"
            >
              <v-icon icon="mdi-plus" color="white" />
            </v-btn>
          </div>
        </template>
      </v-img>
    </div>

    <div class="pa-2 cursor-pointer" @click="navigateToProduct">
      <div 
        v-if="product.seller?.name" 
        class="text-overline mb-0 font-weight-bold seller" 
        style="color: #D2B48C"
      >
        {{ product.seller.name.toUpperCase() }}
      </div>
      <div class="product-title mb-0">{{ product.name }}</div>
      <div class="mb-0 d-flex align-center">
        <template v-if="cartQuantity > 0 && currentDiscount">
          <span class="font-weight-bold text-error text-decoration-line-through mr-2">
            ${{ formatPrice(product.price) }}
          </span>
          <span class="font-weight-bold" style="color: #D2B48C">
            ${{ formatPrice(discountedPrice) }}
          </span>
          <span v-if="product.weightAdjusted" class="ml-2 text-caption">
            (est {{ product.averageWeight }} {{ product.weightMeasure }})
          </span>
          <span class="ml-2 text-error">
            Saved ${{ formatPrice(savedAmount) }}
          </span>
        </template>
        <template v-else>
          <span class="font-weight-bold" style="color: #D2B48C">
            ${{ formatPrice(product.price) }}
          </span>
          <span v-if="product.weightAdjusted" class="ml-2 text-caption">
            (est {{ product.averageWeight }} {{ product.weightMeasure }})
          </span>
        </template>
      </div>
    </div>

    <v-snackbar
      v-model="showError"
      color="error"
      timeout="3000"
    >
      {{ errorMessage }}
      <template #actions>
        <v-btn
          color="white"
          variant="text"
          @click="showError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'ProductCard',
  
  props: {
    product: {
      type: Object,
      required: true,
      validator(value) {
        return value && value._id && value.name && value.productImage;
      }
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()
    const showError = ref(false)
    const errorMessage = ref('')

    const category = computed(() => 
      store.getters['category/getCategoryById'](props.product.category._id)
    )

    const cartQuantity = computed(() => 
      store.getters['cart/getCartItemQuantity'](props.product._id)
    )

    const currentDiscount = computed(() => {
      if (!props.product.bulkPriceGrid?.length) return null
      
      return props.product.bulkPriceGrid
        .filter(grid => grid.minQuantity <= cartQuantity.value)
        .sort((a, b) => b.discountPerUnit - a.discountPerUnit)[0]
    })

    const nextDiscountTier = computed(() => {
      if (!props.product.bulkPriceGrid?.length) return null
      
      return props.product.bulkPriceGrid
        .filter(grid => grid.minQuantity > cartQuantity.value)
        .sort((a, b) => a.minQuantity - b.minQuantity)[0]
    })

    const discountedPrice = computed(() => {
      if (!currentDiscount.value) return props.product.price
      return props.product.price - currentDiscount.value.discountPerUnit
    })

    const savedAmount = computed(() => {
      if (!currentDiscount.value || !cartQuantity.value) return 0
      return currentDiscount.value.discountPerUnit * cartQuantity.value
    })

    const showBulkDiscount = computed(() => {
      return nextDiscountTier.value !== null && amountUntilNextDiscount.value > 0
    })

    const amountUntilNextDiscount = computed(() => {
      if (!nextDiscountTier.value) return 0
      return nextDiscountTier.value.minQuantity - cartQuantity.value
    })

    const nextDiscountMinSavings = computed(() => {
      if (!nextDiscountTier.value) return 0
      return nextDiscountTier.value.discountPerUnit * nextDiscountTier.value.minQuantity
    })

    const formatPrice = (price) => {
      return Number(price).toFixed(2)
    }

    const categoryUrl = computed(() => {
      console.log('Product category:', props.product.category)
      const url = store.getters['category/getCategoryUrl'](props.product.category)
      console.log('Retrieved category URL:', url)
      return url
    })

    const navigateToProduct = () => {
      console.log('Navigating with category URL:', categoryUrl.value)
      if (!categoryUrl.value) {
        console.error('Category URL is required for navigation')
        return
      }
      
      router.push({
        name: 'ProductOverview',
        params: { 
          category: categoryUrl.value,
          id: props.product._id 
        }
      })
    }

    const showErrorMessage = (message) => {
      errorMessage.value = message
      showError.value = true
    }

    const handleAddToCart = async () => {
      try {
        await store.dispatch('cart/addToCart', props.product)
      } catch (error) {
        console.error('Error adding product to cart:', error)
        showErrorMessage('Could not add item to cart. Please try again.')
      }
    }

    const handleQuantityIncrease = async () => {
      try {
        await store.dispatch('cart/updateCartItemQuantity', {
          productId: props.product._id,
          quantity: cartQuantity.value + 1
        })
      } catch (error) {
        console.error('Error increasing quantity:', error)
        showErrorMessage('Could not update quantity. Please try again.')
      }
    }

    const handleQuantityDecrease = async () => {
      try {
        if (cartQuantity.value === 1) {
          await store.dispatch('cart/removeFromCart', props.product._id)
        } else {
          await store.dispatch('cart/updateCartItemQuantity', {
            productId: props.product._id,
            quantity: cartQuantity.value - 1
          })
        }
      } catch (error) {
        console.error('Error decreasing quantity:', error)
        showErrorMessage('Could not update quantity. Please try again.')
      }
    }

    const handleBulkAdd = async () => {
      try {
        await store.dispatch('cart/updateCartItemQuantity', {
          productId: props.product._id,
          quantity: cartQuantity.value + amountUntilNextDiscount.value
        })
      } catch (error) {
        console.error('Error adding bulk quantity:', error)
        showErrorMessage('Could not add bulk quantity. Please try again.')
      }
    }

    return {
      cartQuantity,
      showBulkDiscount,
      amountUntilNextDiscount,
      nextDiscountMinSavings,
      currentDiscount,
      discountedPrice,
      savedAmount,
      formatPrice,
      navigateToProduct,
      handleAddToCart,
      handleQuantityIncrease,
      handleQuantityDecrease,
      handleBulkAdd,
      showError,
      errorMessage
    }
  }
}
</script>

<style scoped>
.product-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.cart-controls {
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 1;
  transition: transform 0.2s ease-in-out;
}

.price-info {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
  z-index: 1;
}

.cart-pill {
  min-width: 140px;
  height: 36px !important;
  border-radius: 20px;
  background: rgb(var(--v-theme-primary)) !important;
  padding: 0 16px;
  transition: background-color 0.2s ease;
}

.bulk-chip {
  font-size: 0.625rem;
  height: 20px;
  opacity: 0.9;
  white-space: nowrap;
}

.cart-pill:hover {
  background: rgb(var(--v-theme-primary-darken-1)) !important;
}

.control-icon {
  margin: 0 4px;
  transition: transform 0.2s ease;
}

.control-icon:hover {
  transform: scale(1.1);
}

.add-btn {
  height: 36px !important;
  width: 36px !important;
  background: rgb(var(--v-theme-primary)) !important;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.add-btn:hover {
  transform: scale(1.05);
  background: rgb(var(--v-theme-primary-darken-1)) !important;
}

.product-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.87);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: color 0.2s ease;
}

.product-title:hover {
  color: rgb(var(--v-theme-primary));
}

.seller {
  font-size: 1rem;
  transition: color 0.2s ease;
}

.seller:hover {
  color: #A0826D !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 600px) {
  .cart-pill {
    min-width: 120px;
    height: 32px !important;
  }

  .add-btn {
    height: 32px !important;
    width: 32px !important;
  }

  .product-title {
    font-size: 0.875rem;
  }

  .seller {
    font-size: 0.875rem;
  }
}
</style>