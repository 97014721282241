// ./frontend/src/store/modules/user.js

import axiosInstance from '../../utils/axios';

const state = {
  user: null,
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  CLEAR_USER(state) {
    state.user = null;
  },
  UPDATE_USER(state, userData) {
    state.user = { ...state.user, ...userData };
  },
  SET_EMAIL_VERIFIED(state, isVerified) {
    if (state.user) {
      state.user.emailVerified = isVerified;
    }
  },
  SET_PHONE_VERIFIED(state, isVerified) {
    if (state.user) {
      state.user.phoneVerified = isVerified;
    }
  },
};

const actions = {
  async login({ commit }, { email, password, rememberMe }) {
    try {
      const response = await axiosInstance.post('/api/auth/login', { email, password, rememberMe });
      const { user, token } = response.data;

      if (rememberMe) {
        localStorage.setItem('token', token);
      } else {
        sessionStorage.setItem('token', token);
      }

      commit('SET_USER', user);
      commit('SET_AUTH', true);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },
  async googleLogin({ commit }, idToken) {
    try {
      const response = await axiosInstance.post('/api/auth/google', { idToken });
      const { user, token } = response.data;
      localStorage.setItem('token', token);
      commit('SET_USER', user);
      commit('SET_AUTH', true);
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  },
  async facebookLogin({ commit }, facebookUser) {
    try {
      // You might want to send this data to your backend to create/update the user
      const response = await axiosInstance.post('/api/auth/facebook', {
        name: facebookUser.name,
        email: facebookUser.email,
        facebookId: facebookUser.id,
        profileImage: facebookUser.picture.data.url
      });

      const { user, token } = response.data;
      localStorage.setItem('token', token);
      commit('SET_USER', user);
      commit('SET_AUTH', true);
    } catch (error) {
      console.error('Facebook login failed:', error);
      throw error;
    }
  },
  async signup({ commit }, userData) {
    try {
      const response = await axiosInstance.post('/api/users/signup', userData);
      localStorage.setItem('token', response.data.token);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      commit('SET_USER', response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('Signup failed:', error);
      throw error;
    }
  },
  async logout({ commit }) {
    try {
      await axiosInstance.post('/api/auth/logout');
      localStorage.removeItem('token');
      delete axiosInstance.defaults.headers.common['Authorization'];
      commit('CLEAR_USER');
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  },
  async resetPassword({ commit }, email) {
    try {
      await axiosInstance.post('/api/auth/reset-password', { email });
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  },
  async confirmResetPassword({ commit }, { token, password }) {
    try {
      await axiosInstance.post('/api/auth/reset-password-confirm', { token, password });
      // You might want to commit a mutation here if you need to update the state
    } catch (error) {
      console.error('Confirm password reset error:', error);
      throw error;
    }
  },
  async fetchUserDetails({ commit }) {
    try {
      const response = await axiosInstance.get('/api/users/profile');
      commit('SET_USER', response.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
      throw error;
    }
  },
  async updateUserProfile({ commit, state }, userData) {
    try {
      const response = await axiosInstance.put('/api/users/profile', userData);

      // If email was changed, set emailVerified to false
      if (userData.email && userData.email !== state.user.email) {
        response.data.emailVerified = false;
      }

      commit('UPDATE_USER', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  },
  async setEmailVerified({ commit }, isVerified) {
    try {
      await axiosInstance.setEmailVerified(isVerified);
      commit('SET_EMAIL_VERIFIED', isVerified);
    } catch (error) {
      console.error('Error setting email verified status:', error);
      throw error;
    }
  },
  initializeAuth({ dispatch }) {
    const token = localStorage.getItem('token');
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      dispatch('fetchUserDetails');
    }
  },
};

const getters = {
  isLoggedIn: state => !!state.user,
  isAdmin: state => state.user && state.user.isAdmin,
  isPremiumUser: state => state.user && state.user.subscription && state.user.subscription.status === 'active',
  isEmailVerified: (state) => state.user && state.user.emailVerified,
  userProfileImage: (state) => state.user?.profileImage,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
