// ./frontend/src/router/index.js

import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Import components
import Home from '../views/core/HomePage.vue'
import MeatPage from '../views/MeatPage.vue'
import BulkMeatPage from '../views/BulkMeatPage.vue'
import CheckoutPage from '../views/core/CheckoutPage.vue'
import ComingSoon from '../views/ComingSoon.vue'
import BulkOrderLanding from '../views/BulkOrderLanding.vue'
import BulkOrderInfo from '../views/BulkOrderInfo.vue'
import InvitePage from '../views/InvitePage.vue'
import AdminBulkOrdersView from '../views/admin/AdminBulkOrdersView.vue'
import AdminUsersView from '../views/admin/AdminUsersView.vue'
import AdminSellersView from '../views/admin/AdminSellersView.vue'
import AdminBulkMeatProductsView from '../views/admin/AdminBulkMeatProductsView.vue'
import AdminLocalShippingZonesView from '../views/admin/AdminLocalShippingZones.vue'
import AdminProductsPage from '../views/admin/AdminProductsPage.vue'
import AdminCategoriesView from '../views/admin/AdminCategoriesView.vue'
import AdminSearchHistory from '../views/admin/AdminSearchHistory.vue'
import AdminPremiumSubscriptionsView from '../views/admin/AdminPremiumSubscriptionsView.vue'
import AdminMunicipalityPage from '../views/admin/AdminMunicipalityPage.vue'
import AdminTrendPage from '../views/admin/AdminTrendPage.vue'
import ProductOverviewPage from '../views/core/ProductOverviewPage.vue'
import AdminRecommendationsPage from '../views/admin/AdminRecommendationsPage.vue'
import AdminOrdersView from '../views/admin/AdminOrdersView.vue'
import OrdersPage from '../views/user/OrdersPage.vue'
import OrderConfirmationPage from '../views/core/OrderConfirmationPage.vue'
import AccountPage from '../views/user/AccountPage.vue'
import CartPage from '../views/core/CartPage.vue'
import SavedItemsPage from '../views/user/SavedItemsPage.vue'
import SearchPage from '../views/core/SearchPage.vue'
import SellerPage from '../views/seller/SellerPage.vue'
import SellerInfoPage from '../views/seller/SellerInfoPage.vue'
import SavedRecommendedItemsPage from '../views/user/SavedRecommendedItemsPage.vue'
import SubscriptionDetailsPage from '../views/user/SubscriptionDetailsPage.vue'
import NotificationsPage from '../views/user/NotificationsPage.vue'
import PrivacyPolicyPage from '../views/core/PrivacyPolicyPage.vue'
import TermsOfServicePage from '../views/core/TermsOfServicePage.vue'
import ResetPasswordPage from '../views/core/ResetPasswordPage.vue'
import BrowsePage from '../views/core/BrowsePage.vue'
import CategoryPage from '../views/core/CategoryPage.vue'
import SubcategoryPage from '../views/core/SubcategoryPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/browse',
    name: 'Browse',
    component: BrowsePage
  },
  {
    path: '/category/:category',
    name: 'Category',
    component: CategoryPage,
    props: true
  },
  {
    path: '/subcategory/:subcategory',
    name: 'Subcategory',
    component: SubcategoryPage,
    props: true,
    meta: {
      title: route => `${route.params.subcategory} | Loquo`
    }
  },
  {
    path: '/subscription',
    name: 'SubscriptionDetails',
    component: SubscriptionDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: NotificationsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/seller-info',
    name: 'SellerInfo',
    component: SellerInfoPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/seller/:url',
    name: 'SellerPage',
    component: SellerPage,
    props: true
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchPage
  },
  {
    path: '/cart',
    name: 'Cart',
    component: CartPage
  },
  {
    path: '/saved-recommended-items',
    name: 'SavedRecommendedItems',
    component: SavedRecommendedItemsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/meat',
    name: 'Meat',
    component: MeatPage,
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProducts', { category: 'Meat' }).then(() => next())
    }
  },
  {
    path: '/bulk-meat',
    name: 'BulkMeat',
    component: BulkMeatPage,
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchBulkOrders', { category: 'Meat' }).then(() => next())
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutPage
  },
  {
    path: '/order-confirmation/:orderId',
    name: 'OrderConfirmation',
    component: OrderConfirmationPage
  },
  {
    path: '/health-practitioners',
    name: 'HealthPractitioners',
    component: ComingSoon
  },
  {
    path: '/pyo',
    name: 'PickYourOwn',
    component: ComingSoon
  },
  {
    path: '/farm-tours',
    name: 'FarmTours',
    component: ComingSoon
  },
  {
    path: '/farmers-markets',
    name: 'FarmersMarkets',
    component: ComingSoon
  },
  {
    path: '/bulk-order-landing/:inviteCode',
    name: 'BulkOrderLanding',
    component: BulkOrderLanding,
    props: true
  },
  {
    path: '/bulk-order-info/:id',
    name: 'BulkOrderInfo',
    component: BulkOrderInfo,
    props: true
  },
  {
    path: '/invite/:id',
    name: 'Invite',
    component: InvitePage,
    props: true
  },
  {
    path: '/:category/:id',
    name: 'ProductOverview',
    component: ProductOverviewPage,
    props: true
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicyPage
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: TermsOfServicePage
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPasswordPage
  },
  {
    path: '/admin/trending',
    name: 'AdminTrending',
    component: AdminTrendPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/premium-subscriptions',
    name: 'AdminPremiumSubscriptions',
    component: AdminPremiumSubscriptionsView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/bulk-orders',
    name: 'AdminBulkOrders',
    component: AdminBulkOrdersView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: AdminUsersView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/sellers',
    name: 'AdminSellers',
    component: AdminSellersView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/bulk-meat-products',
    name: 'AdminBulkMeatProducts',
    component: AdminBulkMeatProductsView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/local-shipping-zones',
    name: 'AdminLocalShippingZones',
    component: AdminLocalShippingZonesView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/products',
    name: 'AdminProducts',
    component: AdminProductsPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/orders',
    name: 'AdminOrders',
    component: AdminOrdersView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/search-history',
    name: 'AdminSearchHistory',
    component: AdminSearchHistory,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/categories',
    name: 'AdminCategories',
    component: AdminCategoriesView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/recommendations',
    name: 'AdminRecommendations',
    component: AdminRecommendationsPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/search-history',
    name: 'AdminSearchHistory',
    component: AdminSearchHistory,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/municipalities',
    name: 'AdminMunicipalities',
    component: AdminMunicipalityPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['user/isLoggedIn']) {
      next({ name: 'Home' })
    } else {
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        try {
          // Fetch user details to ensure we have the latest admin status
          await store.dispatch('user/fetchUserDetails')
          if (store.getters['user/isAdmin']) {
            next()
          } else {
            next({ name: 'Home' })
          }
        } catch (error) {
          console.error('Error fetching user details:', error)
          next({ name: 'Home' })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
