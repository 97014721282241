<!-- ./frontend/src/components/search/SearchDistanceFilter.vue -->
<template>
  <div class="distance-filter-container d-flex me-2">
    <!-- Left Pill - Toggle -->
    <v-chip
      :color="modelValue ? 'primary' : undefined"
      :variant="modelValue ? 'flat' : 'outlined'"
      class="toggle-pill me-n1 filter-chip"
      @click="toggleDistanceFilter"
    >
      <div class="d-flex align-center">
        <v-checkbox
          :model-value="modelValue"
          @update:model-value="toggleDistanceFilter"
          hide-details
          density="compact"
          class="me-2"
        />
        <span class="pe-2">Distance from me</span>
      </div>
    </v-chip>

    <!-- Right Pill - Distance -->
    <v-chip
      class="type-pill filter-chip"
      :class="{
        'connected-active': modelValue,
        'connected-inactive': !modelValue
      }"
      :variant="!modelValue ? 'outlined' : undefined"
      :disabled="!modelValue"
      @click="showDialog = true"
    >
      <div class="d-flex align-center px-2">
        <span class="distance-text me-1">
          {{ distanceFilterRadius }}km
        </span>
        <v-icon size="small">
          mdi-pencil
        </v-icon>
      </div>
    </v-chip>

    <!-- Distance Selection Dialog -->
    <v-dialog v-model="showDialog" max-width="600">
      <v-card>
        <v-card-title class="text-h6 pa-4">Set Distance</v-card-title>
        
        <v-card-text>
          <LocationSearch
            v-model="searchLocation"
            :error-message="errorMessage"
            @select-municipality="selectMunicipality"
          />

          <v-btn
            block
            color="secondary"
            height="48"
            rounded="lg"
            class="mt-4 mb-4 custom-button custom-button--secondary"
            @click="updateLocation"
            :disabled="!searchLocation"
          >
            UPDATE LOCATION
          </v-btn>

          <!-- Map Container -->
          <div class="map-container my-4">
            <GMapMap
              ref="mapRef"
              :center="mapCenter"
              :zoom="mapZoom"
              @zoom_changed="handleMapZoomChange"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
              }"
              class="map"
            >
              <!-- User Location Marker -->
              <GMapMarker :position="mapCenter" />

              <!-- Search Radius Circle -->
              <GMapCircle
                :center="mapCenter"
                :radius="radius * 1000"
                :options="{
                  strokeColor: '#4CAF50',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: '#4CAF50',
                  fillOpacity: 0.15
                }"
              />

              <!-- Seller Markers -->
              <GMapMarker
                v-for="seller in visibleSellers"
                :key="seller._id"
                :position="{ lat: seller.geoLocation.lat, lng: seller.geoLocation.lng }"
                :options="seller.profileImage ? {
                  icon: {
                    url: seller.profileImage,
                    scaledSize: { 
                      width: getMarkerSize(mapZoom), 
                      height: getMarkerSize(mapZoom)
                    },
                    anchor: { 
                      x: getMarkerSize(mapZoom) / 2, 
                      y: getMarkerSize(mapZoom) / 2 
                    }
                  },
                  shape: {
                    coords: [
                      getMarkerSize(mapZoom) / 2, 
                      getMarkerSize(mapZoom) / 2, 
                      getMarkerSize(mapZoom) / 2
                    ],
                    type: 'circle'
                  }
                } : {}"
                @click="navigateToSeller(seller)"
              />
            </GMapMap>
          </div>

          <!-- Distance Slider -->
          <v-slider
            v-model="radius"
            :min="1"
            :max="500"
            :step="1"
            thumb-label
            class="mt-4"
          >
            <template #prepend>
              <v-text-field
                v-model="radius"
                type="number"
                style="width: 100px"
                density="compact"
                hide-details
                class="mt-0 pt-0 slider-input"
                bg-color="transparent"
              />
            </template>
            <template #append>
              <span class="text-body-2 text-medium-emphasis ms-1">km</span>
            </template>
          </v-slider>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-btn
            block
            color="primary"
            height="48"
            rounded="lg"
            class="custom-button custom-button--primary"
            @click="applyDistance"
          >
            APPLY
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

# Script Section
<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import LocationSearch from '@/components/textField/LocationSearch.vue'
import axios from 'axios'

export default defineComponent({
  name: 'SearchDistanceFilter',

  components: {
    LocationSearch
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    distanceFilterRadius: {
      type: Number,
      default: 100
    }
  },

  emits: ['update:modelValue', 'update:distanceFilterRadius'],

  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const showDialog = ref(false)
    const radius = ref(props.distanceFilterRadius)
    const searchLocation = ref('')
    const errorMessage = ref('')
    const mapZoom = ref(8)
    const selectedMunicipality = ref(null)
    const mapRef = ref(null)
    const isUpdatingFromZoom = ref(false)
    
    const mapCenter = computed(() => ({
      lat: store.getters['location/getCoordinates'].lat || 0,
      lng: store.getters['location/getCoordinates'].lng || 0
    }))

    const visibleSellers = computed(() => store.getters['search/visibleSellers'])

    const getMarkerSize = (zoom) => {
      // Base size is 40px at zoom level 15
      const baseSize = 40
      const baseZoom = 15
      
      // Calculate size based on zoom level
      // Decrease size as zoom level decreases
      const size = Math.max(
        20, // Minimum size
        Math.min(
          40, // Maximum size
          Math.round(baseSize * (zoom / baseZoom))
        )
      )
      
      return size
    }

    const calculateZoomFromRadius = (radiusValue) => {
      return Math.round(11 - Math.log2(radiusValue / 10))
    }

    const calculateRadiusFromZoom = (zoomValue) => {
      return Math.round(10 * Math.pow(2, 11 - zoomValue))
    }

    const handleMapZoomChange = () => {
      if (mapRef.value && !isUpdatingFromZoom.value) {
        const newZoom = mapRef.value.$mapObject.getZoom()
        const newRadius = calculateRadiusFromZoom(newZoom)
        
        if (newRadius >= 1 && newRadius <= 500) {
          isUpdatingFromZoom.value = true
          radius.value = newRadius
          isUpdatingFromZoom.value = false
        }
      }
    }

    const navigateToSeller = (seller) => {
      if (seller && seller.url) {
        const url = `${window.location.origin}/seller/${seller.url}`
        window.open(url, '_blank')
      }
    }

    const toggleDistanceFilter = () => {
      if (!props.modelValue && !store.getters['location/hasLocation']) {
        showDialog.value = true
        return
      }
      emit('update:modelValue', !props.modelValue)
    }

    const selectMunicipality = (municipality) => {
      const municipalityString = `${municipality.name}, ${municipality.province}`
      searchLocation.value = municipalityString
      selectedMunicipality.value = municipality
    }

    const updateLocation = async () => {
      if (!searchLocation.value) {
        return
      }

      errorMessage.value = ''
      
      const parts = searchLocation.value.split(',')
      if (parts.length === 2) {
        const name = parts[0].trim()
        const province = parts[1].trim()

        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/municipalities/find`, {
            params: { 
              name: name,
              province: province
            }
          })
          
          if (response.data && response.data.geoLocation) {
            await store.dispatch('location/setLocation', {
              location: searchLocation.value,
              lat: response.data.geoLocation.lat,
              lng: response.data.geoLocation.lng
            })
            mapZoom.value = calculateZoomFromRadius(radius.value)
            errorMessage.value = ''
            return
          }
        } catch (error) {
          console.error('Error fetching municipality:', error)
          errorMessage.value = 'Unable to verify this municipality. Please try a different location.'
          return
        }
      }

      errorMessage.value = 'Please select a municipality from the suggestions.'
    }

    const applyDistance = () => {
      if (!store.getters['location/hasLocation']) {
        errorMessage.value = 'Please set your location first'
        return
      }
      emit('update:distanceFilterRadius', radius.value)
      emit('update:modelValue', true)
      showDialog.value = false
    }

    watch(radius, (newRadius) => {
      if (!isUpdatingFromZoom.value) {
        const newZoom = calculateZoomFromRadius(newRadius)
        isUpdatingFromZoom.value = true
        mapZoom.value = newZoom
        isUpdatingFromZoom.value = false
      }
    })

    watch(
      () => store.getters['location/getCurrentLocation'],
      (newLocation) => {
        if (newLocation) {
          searchLocation.value = newLocation
        }
      }
    )

    onMounted(() => {
      const savedLocation = store.getters['location/getCurrentLocation']
      if (savedLocation) {
        searchLocation.value = savedLocation
      }
      mapZoom.value = calculateZoomFromRadius(radius.value)
    })

    return {
      showDialog,
      radius,
      searchLocation,
      errorMessage,
      mapCenter,
      mapZoom,
      mapRef,
      visibleSellers,
      getMarkerSize,
      toggleDistanceFilter,
      selectMunicipality,
      updateLocation,
      applyDistance,
      handleMapZoomChange,
      navigateToSeller
    }
  }
})
</script>

<style scoped>
.distance-filter-container {
  display: inline-flex;
  align-items: center;
  min-width: 200px;
}

.toggle-pill {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 36px;
  z-index: 1;
  background-color: white !important;
  min-width: 180px !important;
}

.toggle-pill.v-chip--variant-flat {
  background-color: rgb(var(--v-theme-primary)) !important;
}

.type-pill {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 36px;
  margin-left: -1px;
  min-width: 100px !important;
}

.connected-active {
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-on-primary));
}

.connected-inactive {
  background-color: rgba(var(--v-theme-surface-variant), 0.25);
  border-left: none !important;
}

.distance-text {
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 48px;
  text-align: center;
}

.filter-chip {
  height: 36px !important;
  padding-right: 8px !important;
}

.filter-chip :deep(.v-checkbox) {
  margin: 0 !important;
  padding: 0 !important;
}

.filter-chip :deep(.v-selection-control) {
  min-height: unset !important;
  width: auto !important;
}

.filter-chip :deep(.v-selection-control__wrapper) {
  margin-right: 4px !important;
  width: auto !important;
}

.filter-chip :deep(.v-checkbox-btn) {
  width: auto !important;
}

/* Map styles remain the same */
.map-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.slider-input :deep(.v-field__input) {
  background-color: transparent !important;
}

.custom-button {
  color: white !important;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}

.custom-button--primary {
  background-color: rgb(var(--v-theme-primary)) !important;
}

.custom-button--secondary {
  background-color: rgb(var(--v-theme-secondary)) !important;
}

@media (max-width: 599px) {
  .distance-filter-container {
    max-width: 100%;
    margin-right: 0.5rem !important;
    min-width: 100%;
  }

  .toggle-pill,
  .type-pill {
    flex: 1;
    justify-content: center;
  }

  .distance-text {
    min-width: 48px;
    text-align: center;
  }
}
</style>