<template>
  <div class="d-flex gap-4 mb-6">
    <!-- Delivery Card -->
    <v-card
      class="flex-1 pa-4 cursor-pointer"
      :class="{ 'border-primary': isDeliverySelected }"
      elevation="2"
      :disabled="!canDeliverLocal && !canDeliverCanadaWide"
      @click="selectDelivery"
    >
      <div class="text-h6 mb-2 d-flex align-center">
        <v-icon class="mr-2">mdi-truck-fast-outline</v-icon>
        <strong>
          <template v-if="canDeliverLocal">
            Delivery to 
            <a 
              href="#" 
              @click.stop.prevent="openLocationDialog" 
              class="text-decoration-underline location-link"
            >
              {{ currentLocation }}
            </a>
          </template>
          <template v-else-if="canDeliverCanadaWide">
            Canada Wide Delivery
          </template>
          <template v-else>
            Delivery
          </template>
        </strong>
      </div>
      <template v-if="canDeliverLocal || canDeliverCanadaWide">
        <div class="text-body-2 mb-1">
          Order by {{ formatOrderBy(nextOrderByDate, nextOrderByTime) }}
        </div>
        <div class="text-subtitle-1">
          <template v-if="canDeliverLocal">
            Arrives by {{ formatDate(nextLocalDeliveryDay) }}
          </template>
          <template v-else>
            Arrives {{ formatDate(nextNationalDeliveryEarlyEstimateDay) }} - {{ formatDate(nextNationalDeliveryLateEstimateDay) }}
          </template>
        </div>
      </template>
      <template v-else>
        <div class="text-subtitle-1">
          Delivery not available
        </div>
      </template>
    </v-card>

    <!-- Pickup Card -->
    <v-card
      class="flex-1 pa-4 cursor-pointer"
      :class="{ 'border-primary': isPickupSelected }"
      elevation="2"
      :disabled="!canPickup"
      @click="selectPickup"
    >
      <div class="text-h6 mb-2 d-flex align-center">
        <v-icon class="mr-2">mdi-barn</v-icon>
        <strong>Pickup</strong>
      </div>
      <template v-if="canPickup && seller?.pickupSchedule?.length">
        <v-list density="compact" class="pickup-list">
          <v-list-item
            v-for="(schedule, i) in seller.pickupSchedule"
            :key="i"
            class="px-0 py-1 pickup-item"
          >
            <template #title>
              <div class="d-flex justify-space-between align-center">
                <span>{{ schedule.pickupDay }}</span>
                <span class="text-body-2">{{ schedule.pickupStartTime }} - {{ schedule.pickupEndTime }}</span>
              </div>
            </template>
          </v-list-item>
        </v-list>
      </template>
      <template v-else>
        <div class="text-subtitle-1">
          Pickup not available
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'DeliveryPickupCards',

  props: {
    seller: {
      type: Object,
      required: true
    },
    productId: {
      type: String,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore()
    const isDeliverySelected = ref(false)
    const isPickupSelected = ref(false)

    const currentLocation = computed(() => store.getters['location/getCurrentLocation'])
    const canDeliverLocal = computed(() => store.getters['delivery/canDeliverLocal'])
    const canDeliverCanadaWide = computed(() => store.getters['delivery/canDeliverCanadaWide'])
    const canPickup = computed(() => store.getters['delivery/canPickup'])
    const isInCart = computed(() => store.getters['cart/isItemInCart'](props.productId))
    const currentCartItem = computed(() => store.getters['cart/getCartItemById'](props.productId))
    const nextLocalDeliveryDay = computed(() => store.getters['delivery/nextLocalDeliveryDay'])
    const nextNationalDeliveryEarlyEstimateDay = computed(() => store.getters['delivery/nextNationalDeliveryEarlyEstimateDay'])
    const nextNationalDeliveryLateEstimateDay = computed(() => store.getters['delivery/nextNationalDeliveryLateEstimateDay'])
    const nextOrderByDate = computed(() => store.getters['delivery/nextOrderByDate'])
    const nextOrderByTime = computed(() => store.getters['delivery/nextOrderByTime'])

    const loadDeliveryStatus = async () => {
      try {
        console.log('Fetching delivery status for product:', props.productId)
        const status = await store.dispatch('delivery/getDeliveryStatus', {
          productId: props.productId
        })
        console.log('Delivery status response:', status)
        console.log('Current delivery options:', {
          canDeliverLocal: canDeliverLocal.value,
          canDeliverCanadaWide: canDeliverCanadaWide.value,
          canPickup: canPickup.value
        })
      } catch (error) {
        console.error('Error loading delivery status:', error)
      }
    }

    const selectDelivery = async () => {
      isDeliverySelected.value = true
      isPickupSelected.value = false
      
      if (isInCart.value) {
        await store.dispatch('cart/updateCartItemDeliveryOption', {
          productId: props.productId,
          deliveryOption: canDeliverLocal.value ? 'LocalDelivery' : 'CanadaWideDelivery'
        })
      }
      emit('select-delivery', canDeliverLocal.value ? 'local' : 'canada-wide')
    }

    const selectPickup = async () => {
      isDeliverySelected.value = false
      isPickupSelected.value = true
      
      if (isInCart.value) {
        await store.dispatch('cart/updateCartItemDeliveryOption', {
          productId: props.productId,
          deliveryOption: 'Pickup'
        })
      }
      emit('select-pickup')
    }

    const openLocationDialog = () => {
      store.dispatch('location/openLocationDialog')
    }

    const formatDate = (date) => {
      if (!date) return ''
      return new Date(date).toLocaleDateString('en-US', { 
        month: 'short',
        day: 'numeric' 
      })
    }

    const formatOrderBy = (date, time) => {
      if (!date || !time) return ''
      const today = new Date().toDateString()
      const orderDate = new Date(date).toDateString()
      
      // Convert 24hr time string to 12hr format
      const [hours, minutes] = time.split(':')
      const hour = parseInt(hours)
      const ampm = hour >= 12 ? 'PM' : 'AM'
      const hour12 = hour % 12 || 12
      const timeStr = `${hour12}:${minutes} ${ampm}`
      
      return orderDate === today 
        ? `today at ${timeStr}`
        : `${formatDate(date)} at ${timeStr}`
    }

    const autoSelectDeliveryOption = async () => {
      if (currentCartItem.value) {
        const option = currentCartItem.value.deliveryOption
        isDeliverySelected.value = ['LocalDelivery', 'CanadaWideDelivery'].includes(option)
        isPickupSelected.value = option === 'Pickup'
      } else if (canDeliverLocal.value || canDeliverCanadaWide.value) {
        await selectDelivery()
      } else if (canPickup.value) {
        await selectPickup()
      }
    }

    // Log delivery dates when they change
    watch(() => props.productId, async (newId) => {
      if (newId) {
        await loadDeliveryStatus()
        await autoSelectDeliveryOption()
      }
    })

    watch(
      [canDeliverLocal, canDeliverCanadaWide, canPickup, currentCartItem], 
      autoSelectDeliveryOption
    )

    onMounted(async () => {
      if (props.productId) {
        await loadDeliveryStatus()
        await autoSelectDeliveryOption()
      }
    })

    return {
      currentLocation,
      nextLocalDeliveryDay,
      nextNationalDeliveryEarlyEstimateDay,
      nextNationalDeliveryLateEstimateDay,
      nextOrderByDate,
      nextOrderByTime,
      selectDelivery,
      selectPickup,
      openLocationDialog,
      formatDate,
      formatOrderBy,
      isDeliverySelected,
      isPickupSelected,
      canDeliverLocal,
      canDeliverCanadaWide,
      canPickup
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.gap-4 {
  gap: 1rem;
}

.flex-1 {
  flex: 1;
}

.pickup-list {
  max-height: 300px;
  overflow-y: auto;
  background: transparent !important;
}

.pickup-item {
  min-height: unset !important;
}

.border-primary {
  border: 2px solid rgb(var(--v-theme-primary)) !important;
}

:deep(.v-list-item__content) {
  color: inherit;
}

:deep(.v-list-item-title) {
  color: inherit !important;
}

.location-link {
  color: inherit;
}

.location-link:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
</style>