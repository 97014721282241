<!-- ./frontend/src/views/core/ProductOverviewPage.vue -->
<template>
 <v-container class="pa-md-6" fluid v-if="product">
   <v-breadcrumbs :items="breadcrumbItems">
     <template #divider>
       <v-icon icon="mdi-chevron-right" size="small" />
     </template>
   </v-breadcrumbs>

   <v-row>
     <v-col cols="12" md="6">
       <v-img
         :src="currentImage"
         height="400"
         cover
         class="rounded mb-4"
       />

       <v-row class="mb-4" v-if="allImages.length > 1">
         <v-col v-for="(image, i) in allImages" 
           :key="i" 
           cols="3"
         >
           <v-img
             :src="image"
             height="80"
             cover
             class="rounded cursor-pointer"
             :class="{ 'border': selectedImage === i }"
             @click="selectedImage = i"
           />
         </v-col>
       </v-row>
     </v-col>

     <v-col cols="12" md="6">
       <div class="d-flex justify-space-between mb-2">
         <h1 class="text-h4 product-name">{{ product.name }}</h1>
         <div class="text-right">
           <div class="text-h4">${{ product.price }}</div>
           <span v-if="product.weightAdjusted" class="text-caption" style="margin-top: -4px; display: block">
             (est {{ product.averageWeight }} {{ product.weightMeasure }})
           </span>
         </div>
       </div>

       <v-chip-group class="mb-4">
         <v-chip v-if="product.category">{{ product.category }}</v-chip>
         <v-chip v-if="product.subcategory">{{ product.subcategory }}</v-chip>
         <v-chip v-if="product.halal">Halal</v-chip>
         <v-chip v-if="product.certifiedOrganic">Organic</v-chip>
       </v-chip-group>

       <div class="d-flex align-center mb-4">
         <v-avatar 
           size="48" 
           class="mr-4 cursor-pointer"
           @click="navigateToSeller"
         >
           <v-img :src="product.seller?.profileImage" />
         </v-avatar>
         <div 
           class="text-subtitle-1 cursor-pointer"
           @click="navigateToSeller"
         >
           {{ product.seller?.name }}
         </div>
       </div>

       <delivery-pickup-cards
         v-if="product.seller"
         :seller="product.seller"
         :product-id="product._id"
       />

       <p class="text-body-1 mb-6">{{ product.description }}</p>

       <v-select
         v-if="product.options?.length"
         v-model="selectedOption"
         :items="product.options"
         item-title="option"
         label="Select Option"
         class="mb-4"
       />

       <cart-controls-button
         :product="product"
         :selected-option="selectedOption"
         @show-message="handleShowMessage"
         class="mb-6"
       />

       <product-action-buttons
         v-model:is-saved="isSaved"
         v-model:is-recommended="isRecommended"
         :product-id="product._id"
         :product-name="product.name"
         @show-message="handleShowMessage"
         class="mb-6"
       />

       <v-expansion-panels variant="accordion">
         <v-expansion-panel title="Description">
           <template #text>
             <div class="text-body-1">{{ product.description }}</div>
           </template>
         </v-expansion-panel>

         <v-expansion-panel 
           v-if="product.bulkPriceGrid?.length > 0"
           title="Discount Information"
         >
           <template #text>
             <div v-for="(tier, index) in product.bulkPriceGrid" :key="index">
               <div class="d-flex align-center mb-2">
                 <v-icon icon="mdi-tag" color="primary" class="mr-2" size="small" />
                 <span>Buy {{ tier.minQuantity }}+ items: Save ${{ tier.discountPerUnit.toFixed(2) }} per item</span>
               </div>
             </div>
           </template>
         </v-expansion-panel>

         <v-expansion-panel 
           v-if="product.seller?.pickupSchedule?.length > 0"
           title="Pickup Information"
         >
           <template #text>
             <div v-for="(schedule, index) in product.seller.pickupSchedule" :key="index" class="mb-4">
               <div class="font-weight-medium mb-2">{{ schedule.pickupDay }}</div>
               <div class="d-flex align-center mb-1">
                 <v-icon icon="mdi-clock-outline" size="small" class="mr-2" />
                 {{ schedule.pickupStartTime }} - {{ schedule.pickupEndTime }}
               </div>
               <div class="d-flex align-center mb-1">
                 <v-icon icon="mdi-map-marker" size="small" class="mr-2" />
                 {{ schedule.pickupLocation }}
               </div>
               <div v-if="schedule.pickupInstructions" class="text-caption">
                 {{ schedule.pickupInstructions }}
               </div>
             </div>
           </template>
         </v-expansion-panel>

         <v-expansion-panel 
           v-if="product.countRecommendations > 0 && productRecommendations.length > 0"
           title="Recommendations"
         >
           <template #text>
             <div v-for="rec in productRecommendations" :key="rec._id" class="mb-4">
               <div class="d-flex align-center mb-2">
                 <v-avatar size="32" class="mr-2">
                   <v-img :src="rec.user?.profileImage || '/default-avatar.png'" />
                 </v-avatar>
                 <span class="font-weight-medium">{{ rec.user?.name }}</span>
               </div>
               <div class="text-body-2">{{ rec.comment }}</div>
             </div>
           </template>
         </v-expansion-panel>
       </v-expansion-panels>
     </v-col>
   </v-row>

   <v-snackbar
     v-model="showMessage"
     :color="messageType"
     :timeout="3000"
   >
     {{ message }}
   </v-snackbar>
 </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import DeliveryPickupCards from '@/components/DeliveryPickupCards.vue'
import ProductActionButtons from '@/components/ProductActionButtons.vue'
import CartControlsButton from '@/components/CartControlsButton.vue'

export default {
 name: 'ProductOverviewPage',
 
 components: {
   DeliveryPickupCards,
   ProductActionButtons,
   CartControlsButton
 },

 setup() {
   const store = useStore()
   const route = useRoute()
   const router = useRouter()

   const product = ref(null)
   const selectedImage = ref(0)
   const isSaved = ref(false)
   const isRecommended = ref(false)
   const showMessage = ref(false)
   const message = ref('')
   const messageType = ref('success')
   const selectedOption = ref(null)
   const viewRecorded = ref(false)
   const productRecommendations = ref([])
   
   const allImages = computed(() => {
     if (!product.value) return []
     const images = [product.value.productImage]
     if (product.value.otherImages) {
       images.push(...product.value.otherImages)
     }
     return images.filter(Boolean)
   })

   const currentImage = computed(() => {
     return allImages.value[selectedImage.value] || '/placeholder.jpg'
   })

   const breadcrumbItems = computed(() => [
     { title: 'Home', to: '/' },
     { title: product.value?.category || 'Products', 
       to: `/category/${store.getters['category/getCategoryUrl'](product.value?.category)}` },
     { title: product.value?.name || 'Product', disabled: true }
   ])

   const fetchProductData = async (productId) => {
     try {
       const productData = await store.dispatch('product/fetchProductById', productId)
       product.value = productData

       if (!viewRecorded.value) {
         await store.dispatch('trend/recordView', {
           type: 'product',
           itemId: productId
         })
         viewRecorded.value = true
       }

       const savedItems = await store.dispatch('fetchSavedItems')
       isSaved.value = savedItems.some(item => item._id === productId)

       const [userRecs, productRecs] = await Promise.all([
         store.dispatch('getUserRecommendations', productId),
         store.dispatch('fetchRecommendationsBySeller', product.value.seller._id)
       ])
       
       isRecommended.value = userRecs.some(r => r.type === 'recommendation')
       productRecommendations.value = productRecs.filter(r => r.comment)

     } catch (error) {
       showError('Error loading product')
       router.push('/404')
     }
   }

   const navigateToSeller = () => {
     if (product.value?.seller?.url) {
       router.push(`/seller/${product.value.seller.url}`)
     }
   }

   const handleShowMessage = ({ text, type }) => {
     message.value = text
     messageType.value = type
     showMessage.value = true
   }

   const showError = (msg) => {
     message.value = msg
     messageType.value = 'error'
     showMessage.value = true
   }

   onMounted(() => {
     fetchProductData(route.params.id)
   })

   watch(() => route.params.id, (newId, oldId) => {
     if (newId && newId !== oldId) {
       viewRecorded.value = false
       fetchProductData(newId)
     }
   })

   return {
     product,
     selectedImage,
     isSaved,
     isRecommended,
     showMessage,
     message, 
     messageType,
     selectedOption,
     allImages,
     currentImage,
     breadcrumbItems,
     productRecommendations,
     navigateToSeller,
     handleShowMessage
   }
 }
}
</script>

<style scoped>
.cursor-pointer {
 cursor: pointer;
}

.border {
 border: 2px solid var(--v-primary-base);
}

@media (max-width: 600px) {
 .cart-pill {
   min-width: 120px;
   height: 32px !important;
 }
}
</style>