<!-- ./frontend/src/components/AppMenu.vue -->
<template>
  <v-navigation-drawer v-model="drawerModel" app width="240" :color="'#10341A'" class="d-flex flex-column">
    <v-list-item class="px-2 py-1">
      <div class="d-flex align-center">
        <v-btn icon @click="closeDrawer" class="mr-2" color="#10341A" flat elevation="0">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
        <router-link to="/" class="text-decoration-none">
          <v-img
            :src="newLogo"
            :width="140"
            :height="38"
            contain
            class="mr-2"
          ></v-img>
        </router-link>
      </div>
    </v-list-item>

    <v-list density="compact" nav :color="'#10341A'">
      <template v-for="section in sections" :key="section.title">
        <v-list-subheader class="text-white">{{ section.title }}</v-list-subheader>
        <v-list-item 
          v-for="item in section.items" 
          :key="item.title" 
          :to="item.to" 
          link
          class="text-white"
        >
          <template #prepend>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-divider class="my-2" color="white"></v-divider>
      </template>
    </v-list>
    
    <BecomeSeller />
    
    <v-spacer></v-spacer>
    
    <div class="px-4 text-caption pb-4">
      <router-link to="/privacy-policy" class="text-white text-decoration-none d-block mb-2">Privacy Policy</router-link>
      <router-link to="/terms-of-service" class="text-white text-decoration-none d-block mb-4">Terms of Service</router-link>
      <div class="text-white">&copy; {{ new Date().getFullYear() }} Loquo - Local. Natural. Connected.</div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { computed } from 'vue';
import BecomeSeller from './BecomeSeller.vue';
import newLogo from '@/assets/logo_dark.png';

export default {
  name: 'AppMenu',
  
  components: {
    BecomeSeller
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const sections = [
      {
        title: 'Shopping',
        items: [
          { title: 'Products', icon: 'mdi-storefront', to: '/browse' },
          { title: 'Bulk meat', icon: 'mdi-package-variant-closed', to: '/bulk-meat' },
          { title: 'Farm subscriptions', icon: 'mdi-basket', to: '/farm-subscriptions' },
        ]
      },
      {
        title: 'Lists',
        items: [
          { title: 'Recipes', icon: 'mdi-book-open-variant', to: '/lists/recipes' },
          { title: 'Organic baby registry', icon: 'mdi-baby-carriage', to: '/lists/baby' },
          { title: 'Homeopathy', icon: 'mdi-bottle-tonic', to: '/lists/homeopathy' },
        ]
      },
      {
        title: 'Map',
        items: [
          { title: 'Pick your own food', icon: 'mdi-food-apple', to: '/explore' },
          { title: 'Farm tours', icon: 'mdi-tractor', to: '/explore' },
          { title: 'Farmers markets', icon: 'mdi-store', to: '/explore' },
          { title: 'Restaurants', icon: 'mdi-silverware-fork-knife', to: '/explore' },
          { title: 'Health Practitioners', icon: 'mdi-account-tie', to: '/explore' },
        ]
      }
    ];

    const drawerModel = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    });

    const closeDrawer = () => {
      emit('update:modelValue', false);
    };

    return {
      drawerModel,
      sections,
      closeDrawer,
      newLogo
    };
  }
}
</script>