<!-- ./frontend/src/components/SearchCategories.vue -->
<template>
  <div>
    <!-- Show Me Section -->
    <div v-if="filteredSections.length > 0" class="category-section mb-1">
      <span class="section-title font-weight-bold">SHOW ME:</span>
      <v-chip-group
        v-model="activeSection"
        mandatory
        selected-class="selected-chip"
        class="d-inline-flex"
      >
        <v-chip
          v-if="filteredSections.length > 1"
          value="all"
          :variant="activeSection === 'all' ? 'flat' : 'outlined'"
          :color="activeSection === 'all' ? 'primary' : undefined"
          @click="handleSectionChange('all')"
        >
          ALL
        </v-chip>
        <v-chip
          v-for="section in filteredSections"
          :key="section"
          :value="section.toLowerCase()"
          :variant="activeSection === section.toLowerCase() ? 'flat' : 'outlined'"
          :color="activeSection === section.toLowerCase() ? 'primary' : undefined"
          @click="handleSectionChange(section)"
          class="text-uppercase"
        >
          {{ section }}
        </v-chip>
      </v-chip-group>
    </div>

    <!-- Categories Section -->
    <div v-if="filteredCategories.length > 0" class="category-section mb-2">
      <v-chip-group
        v-model="activeCategory"
        mandatory
        selected-class="selected-chip"
        class="d-inline-flex"
      >
        <v-chip
          v-if="filteredCategories.length > 1"
          value="all"
          :variant="activeCategory === 'all' ? 'flat' : 'outlined'"
          :color="activeCategory === 'all' ? 'primary' : undefined"
          @click="handleCategoryChange('all')"
        >
          ALL
        </v-chip>
        <v-chip
          v-for="category in filteredCategories"
          :key="category"
          :value="category.toLowerCase()"
          :variant="activeCategory === category.toLowerCase() ? 'flat' : 'outlined'"
          :color="activeCategory === category.toLowerCase() ? 'primary' : undefined"
          @click="handleCategoryChange(category)"
          class="text-uppercase"
        >
          {{ category }}
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'SearchCategories',

  setup() {
    const store = useStore()

    // Computed properties
    const filteredSections = computed(() => {
      const sections = store.getters['category/filteredSectionsByResults']
      console.log('Computed filteredSections:', sections)
      return sections
    })

    const filteredCategories = computed(() => {
      const categories = store.getters['category/filteredCategoriesBySection']
      console.log('Computed filteredCategories:', categories)
      return categories
    })

    const activeSection = computed({
      get: () => store.state.search.categorySelection.section?.toLowerCase(),
      set: (value) => handleSectionChange(value)
    })

    const activeCategory = computed({
      get: () => store.state.search.categorySelection.category?.toLowerCase(),
      set: (value) => handleCategoryChange(value)
    })

    // Action handlers
    const handleSectionChange = async (section) => {
      console.log('Handling section change:', section)
      const normalizedSection = section.toLowerCase()
      
      try {
        await store.dispatch('search/updateCategorySelection', {
          section: normalizedSection,
          category: 'all'
        })
        console.log('Section updated successfully:', normalizedSection)
      } catch (error) {
        console.error('Error updating section:', error)
      }
    }

    const handleCategoryChange = async (category) => {
      console.log('Handling category change:', category)
      const normalizedCategory = category.toLowerCase()
      
      try {
        await store.dispatch('search/updateCategorySelection', {
          ...store.state.search.categorySelection,
          category: normalizedCategory
        })
        console.log('Category updated successfully:', normalizedCategory)
      } catch (error) {
        console.error('Error updating category:', error)
      }
    }

    // Watch for changes in filters that might affect sections/categories
    watch(
      () => store.state.search.filters,
      () => {
        console.log('Filters changed, reapplying filters')
        store.dispatch('search/applyFilters')
      },
      { deep: true }
    )

    return {
      // Computed
      filteredSections,
      filteredCategories,
      activeSection,
      activeCategory,
      // Methods
      handleSectionChange,
      handleCategoryChange
    }
  }
}
</script>

<style scoped>
.category-section {
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 8px;
}

.section-title {
  font-size: 0.875rem;
  white-space: nowrap;
}

.v-chip-group {
  margin-left: 8px;
  flex-wrap: wrap;
  gap: 8px;
}

.v-chip {
  font-weight: 700 !important;
  text-transform: uppercase;
  transition: all 0.2s ease;
  height: 36px;
  padding: 0 24px !important;
}

.selected-chip {
  color: white !important;
}

/* Responsive styles */
@media (max-width: 599px) {
  .category-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .v-chip-group {
    margin-left: 0;
    margin-top: 8px;
    width: 100%;
  }

  .v-chip {
    height: 32px;
    font-size: 0.875rem;
  }
}
</style>