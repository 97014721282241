<!-- ./frontend/src/views/SubcategoryPage.vue -->
<template>
  <div class="subcategory-page">
    <v-container v-if="loading" class="d-flex justify-center align-center" style="min-height: 400px;">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-container>

    <v-container v-else-if="error" class="d-flex justify-center align-center" style="min-height: 400px;">
      <v-alert
        type="error"
        variant="tonal"
        :text="error"
      ></v-alert>
    </v-container>

    <v-container v-else class="subcategory-container pa-2">
      <!-- Category Header Component -->
      <CategoryHeader
        v-if="category"
        :category-name="category.name"
        :subcategories="category.subcategories"
        :active-subcategory="subcategoryName"
      />

      <!-- Product Slider Section -->
      <v-row class="mt-4">
        <v-col cols="12" class="pa-1">
          <SliderProducts
            :subcategory-name="subcategoryName"
            :products="products"
            mode="multi-row"
            @add-to-cart="addToCart"
            @increase-quantity="increaseQuantity"
            @decrease-quantity="decreaseQuantity"
          />
        </v-col>
      </v-row>

      <!-- No Products Message -->
      <v-row v-if="products.length === 0" class="mt-2">
        <v-col cols="12" class="text-center pa-1">
          <v-alert
            type="info"
            variant="tonal"
            text="No products available in this subcategory"
            class="ma-0"
          ></v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SliderProducts from '@/components/SliderProducts.vue';
import CategoryHeader from '@/components/CategoryHeader.vue';

export default {
  name: 'SubcategoryPage',
  
  components: {
    SliderProducts,
    CategoryHeader
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const error = ref(null);
    
    // Computed properties
    const loading = computed(() => store.state.category.loading || store.state.product.loading);
    const subcategoryUrl = computed(() => route.params.subcategory);
    
    const categoryData = computed(() => 
      store.getters['category/getSubcategoryByUrl'](subcategoryUrl.value)
    );

    const category = computed(() => categoryData.value?.category || null);
    const subcategory = computed(() => categoryData.value?.subcategory || null);
    const subcategoryName = computed(() => subcategory.value?.name || '');

    const products = computed(() => 
      store.getters['product/getProductsBySubcategory'](subcategoryName.value) || []
    );

    // Methods
    const addToCart = (product) => {
      store.dispatch('cart/addItem', { product, quantity: 1 });
    };

    const increaseQuantity = (product) => {
      store.dispatch('cart/incrementItem', product._id);
    };

    const decreaseQuantity = (product) => {
      store.dispatch('cart/decrementItem', product._id);
    };

    const initializeSubcategory = async () => {
      error.value = null;
      
      try {
        // Fetch categories if not already loaded
        if (store.state.category.categories.length === 0) {
          await store.dispatch('category/fetchCategories');
        }

        const categoryData = store.getters['category/getSubcategoryByUrl'](subcategoryUrl.value);
        
        if (!categoryData?.category || !categoryData?.subcategory) {
          error.value = 'Subcategory not found';
          return;
        }

        // Fetch products for the category
        await store.dispatch('product/fetchProductsByCategory', categoryData.category._id);

        // Record category view
        await store.dispatch('trend/recordView', {
          type: 'category',
          itemId: categoryData.category._id
        });
      } catch (err) {
        console.error('Error initializing subcategory:', err);
        error.value = 'Error loading subcategory data. Please try again.';
      }
    };
    
    // Lifecycle hooks
    onMounted(() => {
      initializeSubcategory();
    });

    // Watch for route changes
    watch(() => route.params.subcategory, () => {
      initializeSubcategory();
    });

    return {
      // State
      error,
      loading,
      
      // Computed
      category,
      subcategoryName,
      products,
      
      // Methods
      addToCart,
      increaseQuantity,
      decreaseQuantity
    };
  }
};
</script>

<style scoped>
.subcategory-page {
  min-height: 100vh;
}

.subcategory-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>