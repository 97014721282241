<!-- ./frontend/src/components/ForgotPassword.vue -->
<template>
  <div class="forgot-password-container">
    <div class="mb-8">
      <v-label class="font-weight-bold">Email Address</v-label>
      <v-text-field
        v-model="email"
        color="primary"
        density="comfortable"
        placeholder="you@example.com"
        variant="outlined"
        type="email"
        required
      />
    </div>
    <v-btn
      block
      class="text-none mb-4 submit-btn"
      height="56"
      rounded="lg"
      type="submit"
      :loading="loading"
      @click="resetPassword"
    >
      RESET PASSWORD
    </v-btn>
    <v-btn
      block
      class="text-none"
      variant="text"
      color="primary"
      height="56"
      rounded="lg"
      @click="$emit('back')"
    >
      BACK TO LOGIN
    </v-btn>
  </div>
</template>

<script>
// Script remains the same
</script>

<style scoped>
.forgot-password-container {
  margin-top: 48px;
}

.submit-btn {
  background-color: rgb(25, 55, 25) !important;
  color: white !important;
}
</style>