// ./frontend/src/store/modules/product.js

import axiosInstance from '../../utils/axios';

/**
 * Default product structure that ensures data consistency.
 * This template is used to initialize new products and fill in
 * missing fields when receiving data from the API.
 */
const DEFAULT_PRODUCT = {
  name: '',
  category: '',
  subcategory: '',
  description: '',
  price: 0,
  weightAdjusted: false,
  weightMeasure: '',
  pricePerMeasure: 0,
  averageWeight: null,
  specifications: [],
  seller: null, // Changed to simple ObjectId reference
  deliveryOptions: [],
  availableQuantity: 0,
  productImage: '',
  otherImages: [],
  halal: false,
  certifiedOrganic: false,
  options: [],
  needsRefrigeration: false,
  needsFreezing: false,
  isEligibleForCanadaWideShipping: false,
  localShippingZone: '',
  countRecommendations: 0,
  countChangedMyLife: 0,
  isActive: true,
  totalPageViews: 0,
  uniquePageViews: 0,
  lastViewed: null
};

/**
 * Initial state with proper typing and default values
 */
const initialState = {
  products: [],
  currentProduct: null,
  loading: false,
  error: null,
  filters: {
    category: null,
    subcategory: null,
    seller: null,
    priceRange: null,
    organic: null,
    halal: null,
    deliveryOption: null
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
    limit: 20,
    total: 0
  }
};

export const state = { ...initialState };

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState);
  },

  SET_LOADING(state, status) {
    state.loading = status;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_PRODUCTS(state, products = []) {
    state.products = (Array.isArray(products) ? products : []).map(product => ({
      ...DEFAULT_PRODUCT,
      ...product
    }));
  },

  SET_PRODUCTS_BY_CATEGORY(state, { categoryId, products }) {
    const existingIds = new Set(state.products.map(p => p._id));
    const newProducts = products.filter(p => !existingIds.has(p._id))
      .map(product => ({
        ...DEFAULT_PRODUCT,
        ...product
      }));
    
    state.products = [...state.products, ...newProducts];
  },

  SET_CURRENT_PRODUCT(state, product) {
    state.currentProduct = product ? {
      ...DEFAULT_PRODUCT,
      ...product
    } : null;
  },

  ADD_PRODUCT(state, product) {
    state.products.push({
      ...DEFAULT_PRODUCT,
      ...product
    });
  },

  UPDATE_PRODUCT(state, updatedProduct) {
    const index = state.products.findIndex(p => p._id === updatedProduct._id);
    if (index !== -1) {
      state.products[index] = {
        ...DEFAULT_PRODUCT,
        ...updatedProduct
      };
    }

    if (state.currentProduct && state.currentProduct._id === updatedProduct._id) {
      state.currentProduct = {
        ...DEFAULT_PRODUCT,
        ...updatedProduct
      };
    }
  },

  REMOVE_PRODUCT(state, productId) {
    state.products = state.products.filter(p => p._id !== productId);
    if (state.currentProduct && state.currentProduct._id === productId) {
      state.currentProduct = null;
    }
  },

  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  INCREMENT_PAGE_VIEWS(state, { productId }) {
    const product = state.products.find(p => p._id === productId);
    if (product) {
      product.totalPageViews++;
      product.lastViewed = new Date();
    }

    if (state.currentProduct && state.currentProduct._id === productId) {
      state.currentProduct.totalPageViews++;
      state.currentProduct.lastViewed = new Date();
    }
  }
};

export const actions = {
  /**
   * Initialize the store module
   */
  init({ commit }) {
    commit('RESET_STATE');
  },

  /**
   * Fetch all products with pagination and filtering
   */
  async fetchProducts({ commit, state }, { page = 1, filters = {} } = {}) {
    try {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      const response = await axiosInstance.get('/api/products', {
        params: {
          page,
          limit: state.pagination.limit,
          ...filters,
          ...state.filters
        }
      });

      if (!response.data) {
        throw new Error('No data received from server');
      }

      let products, totalPages, total;

      if (Array.isArray(response.data)) {
        products = response.data;
        totalPages = 1;
        total = products.length;
      } else if (typeof response.data === 'object') {
        ({ products, totalPages, total } = response.data);
        
        if (!Array.isArray(products)) {
          products = [];
        }
      } else {
        throw new Error('Invalid response format from server');
      }

      commit('SET_PRODUCTS', products);
      commit('SET_PAGINATION', {
        currentPage: page,
        totalPages: totalPages || 1,
        total: total || products.length
      });

      return { products, totalPages, total };
    } catch (error) {
      console.error('Error in fetchProducts:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Error fetching products';
      commit('SET_ERROR', errorMessage);
      commit('SET_PRODUCTS', []);
      commit('SET_PAGINATION', {
        currentPage: 1,
        totalPages: 1,
        total: 0
      });
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Fetch products by category ID
   */
  async fetchProductsByCategory({ commit }, categoryId) {
    try {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      const response = await axiosInstance.get(`/api/products/by-category/${categoryId}`);

      if (!response.data) {
        throw new Error('No products received for category');
      }

      const products = Array.isArray(response.data) ? response.data : [];
      commit('SET_PRODUCTS_BY_CATEGORY', { categoryId, products });
      
      return products;
    } catch (error) {
      console.error('Error fetching products by category:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error fetching category products');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Fetch products by subcategory
   */
  async fetchProductsBySubcategory({ commit }, { categoryId, subcategoryName }) {
    try {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      const response = await axiosInstance.get(`/api/products/by-subcategory`, {
        params: {
          categoryId,
          subcategory: subcategoryName
        }
      });

      if (!response.data) {
        throw new Error('No products received for subcategory');
      }

      const products = Array.isArray(response.data) ? response.data : [];
      commit('SET_PRODUCTS_BY_CATEGORY', { categoryId, products });
      
      return products;
    } catch (error) {
      console.error('Error fetching products by subcategory:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error fetching subcategory products');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Fetch active products only
   */
  async fetchActiveProducts({ commit }) {
    try {
      commit('SET_LOADING', true);
      const response = await axiosInstance.get('/api/products/active');
      
      if (!response.data) {
        throw new Error('No data received from server');
      }

      commit('SET_PRODUCTS', Array.isArray(response.data) ? response.data : []);
      return response.data;
    } catch (error) {
      console.error('Error fetching active products:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error fetching active products');
      commit('SET_PRODUCTS', []);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Fetch product by ID
   */
  async fetchProductById({ commit }, productId) {
    try {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);
      
      const response = await axiosInstance.get(`/api/products/${productId}`);
      
      if (!response.data) {
        throw new Error('No product data received');
      }
      
      commit('SET_CURRENT_PRODUCT', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching product by ID:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error fetching product');
      commit('SET_CURRENT_PRODUCT', null);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Create a new product
   */
  async createProduct({ commit }, formData) {
    try {
      console.log('=== Starting Product Creation ===');
      console.log('Initial FormData keys:', [...formData.keys()]);
      
      // Log raw product data
      const productData = formData.get('product');
      console.log('Raw product data from FormData:', productData);
      
      // Log parsed product data
      const parsedProduct = JSON.parse(productData);
      console.log('Parsed product data:', {
        ...parsedProduct,
        seller: parsedProduct.seller ? {
          _id: parsedProduct.seller._id,
          fullObject: parsedProduct.seller
        } : 'No seller data'
      });
      
      // Log seller data specifically
      console.log('Seller data type:', typeof parsedProduct.seller);
      console.log('Seller data structure:', {
        isObject: typeof parsedProduct.seller === 'object',
        hasId: parsedProduct.seller?._id ? true : false,
        raw: parsedProduct.seller
      });
      
      // Clean product data
      const cleanProductData = {
        ...parsedProduct,
        seller: parsedProduct.seller._id || parsedProduct.seller
      };
      
      console.log('Clean product data:', {
        ...cleanProductData,
        seller: {
          value: cleanProductData.seller,
          type: typeof cleanProductData.seller
        }
      });
      
      // Update FormData
      formData.set('product', JSON.stringify(cleanProductData));
      console.log('Updated FormData keys:', [...formData.keys()]);
      console.log('Updated product data in FormData:', formData.get('product'));
      
      // Log request details
      console.log('Making API request to:', '/api/products');
      console.log('Request method:', 'POST');
      
      const response = await axiosInstance.post('/api/products', formData);
      
      // Log response details
      console.log('Server response status:', response.status);
      console.log('Server response headers:', response.headers);
      console.log('Server response data:', {
        ...response.data,
        seller: response.data.seller ? {
          id: response.data.seller._id || response.data.seller,
          type: typeof response.data.seller
        } : 'No seller in response'
      });
      
      // Log commit details
      console.log('Committing to store with data:', {
        mutation: 'ADD_PRODUCT',
        payload: response.data
      });
      
      commit('ADD_PRODUCT', response.data);
      return response.data;
    } catch (error) {
      console.error('=== Product Creation Error ===');
      console.error('Error type:', error.constructor.name);
      console.error('Error message:', error.message);
      
      if (error.response) {
        console.error('Server response details:', {
          status: error.response.status,
          statusText: error.response.statusText,
          headers: error.response.headers,
          data: error.response.data
        });
      }
      
      if (error.request) {
        console.error('Request details:', {
          method: error.request.method,
          url: error.request.url,
          headers: error.request.headers
        });
      }
      
      if (error.config) {
        console.error('Request config:', {
          url: error.config.url,
          method: error.config.method,
          headers: error.config.headers,
          data: error.config.data
        });
      }
      
      console.error('Full error stack:', error.stack);
      throw error;
    }
  },

  /**
   * Update an existing product
   */
  async updateProduct({ commit }, { productId, productData }) {
    try {
      commit('SET_LOADING', true);
      
      // If productData is FormData, handle seller conversion
      if (productData instanceof FormData) {
        const productJson = JSON.parse(productData.get('product'));
        const cleanProductData = {
          ...productJson,
          seller: productJson.seller._id || productJson.seller // Get ID if populated object, or use direct ID
        };
        productData.set('product', JSON.stringify(cleanProductData));
      }
      
      const response = await axiosInstance.put(`/api/products/${productId}`, productData);
      commit('UPDATE_PRODUCT', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating product:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error updating product');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Delete a product
   */
  async deleteProduct({ commit }, productId) {
    try {
      commit('SET_LOADING', true);
      await axiosInstance.delete(`/api/products/${productId}`);
      commit('REMOVE_PRODUCT', productId);
    } catch (error) {
      console.error('Error deleting product:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error deleting product');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Set filters and fetch filtered products
   */
  async setFilters({ commit, dispatch }, filters) {
    commit('SET_FILTERS', filters);
    return dispatch('fetchProducts', { page: 1 });
  },

  /**
   * Clear all filters
   */
  async clearFilters({ commit, dispatch }) {
    commit('SET_FILTERS', {
      category: null,
      subcategory: null,
      seller: null,
      priceRange: null,
      organic: null,
      halal: null,
      deliveryOption: null
    });
    return dispatch('fetchProducts', { page: 1 });
  },

  /**
   * Search products
   */
  async searchProducts({ commit }, query) {
    try {
      commit('SET_LOADING', true);
      const response = await axiosInstance.get('/api/products/search', {
        params: { q: query }
      });

      if (!response.data) {
        throw new Error('No search results received');
      }

      commit('SET_PRODUCTS', Array.isArray(response.data) ? response.data : []);
      return response.data;
    } catch (error) {
      console.error('Error searching products:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error searching products');
      commit('SET_PRODUCTS', []);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Fetch products by seller
   */
  async fetchProductsBySeller({ commit }, sellerId) {
    try {
      commit('SET_LOADING', true);
      const response = await axiosInstance.get(`/api/products/by-seller/${sellerId}`);

      if (!response.data) {
        throw new Error('No seller products received');
      }

      commit('SET_PRODUCTS', Array.isArray(response.data) ? response.data : []);
      return response.data;
    } catch (error) {
      console.error('Error fetching seller products:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error fetching seller products');
      commit('SET_PRODUCTS', []);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Fetch cart product information
   */
  async fetchCartProductInfo({ commit }, productIds) {
    try {
      commit('SET_LOADING', true);
      const response = await axiosInstance.get('/api/products/cart-info', {
        params: { ids: productIds.join(',') }
      });

      if (!response.data) {
        throw new Error('No cart product info received');
      }

      return response.data;
    } catch (error) {
      console.error('Error fetching cart product info:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error fetching cart product info');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  }
};

const getters = {
  isLoading: state => state.loading,
  
  error: state => state.error,
  
  allProducts: state => state.products,
  
  currentProduct: state => state.currentProduct,
  
  getProductById: state => id => {
    return state.products.find(product => product._id === id)
  },

  getProductsBySubcategory: state => subcategoryName => {
    return state.products.filter(product => 
      product.subcategory === subcategoryName && product.isActive
    )
  },

  getProductsByCategory: state => categoryId => {
    return state.products.filter(product => 
      product.category === categoryId && product.isActive
    )
  },

  getProductsGroupedBySubcategory: state => categoryId => {
    const products = state.products.filter(product => 
      product.category === categoryId && product.isActive
    )

    return products.reduce((groups, product) => {
      const subcategory = product.subcategory
      if (!groups[subcategory]) {
        groups[subcategory] = []
      }
      groups[subcategory].push(product)
      return groups
    }, {})
  },

  getProductDeliveryOptions: state => productId => {
    const product = state.products.find(p => p._id === productId) || state.currentProduct;
    if (!product?.seller) return null;
  
    const options = [];
    if (product.seller.deliverySchedule?.length > 0) {
      options.push('Delivery');
    }
    if (product.seller.pickupSchedule?.length > 0) {
      options.push('Pickup');  
    }
    
    // Return preferred option based on availability
    if (options.includes('Delivery')) return 'Delivery';
    if (options.includes('Pickup')) return 'Pickup';
    return null;
  },

  filteredProducts: state => {
    return state.products.filter(product => {
      let matches = true
      const filters = state.filters

      if (filters.category && product.category !== filters.category) matches = false
      if (filters.subcategory && product.subcategory !== filters.subcategory) matches = false
      if (filters.seller && product.seller !== filters.seller) matches = false
      if (filters.organic && product.certifiedOrganic !== filters.organic) matches = false
      if (filters.halal && product.halal !== filters.halal) matches = false
      if (filters.deliveryOption && !product.deliveryOptions.includes(filters.deliveryOption)) matches = false
      if (filters.priceRange) {
        const [min, max] = filters.priceRange
        if (product.price < min || product.price > max) matches = false
      }

      return matches
    })
  },

  activeProducts: state => {
    return state.products.filter(product => product.isActive)
  },

  paginationInfo: state => state.pagination,
  
  currentFilters: state => state.filters,

  productsBySeller: state => sellerId => {
    return state.products.filter(product => product.seller === sellerId)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
