<!-- ./frontend/src/views/SearchPage.vue -->
<template>
  <v-container class="pt-2 px-0 pb-2" fluid>
    <v-row>
      <v-col fluid class="pa-0">
        <!-- Section and Category Selection -->
        <div class="search-sections rounded pa-4 mb-4 bg-grey-lighten-5">
          <SearchCategories />
        </div>

        <!-- Filters -->
        <div class="filters-container pa-4 rounded bg-grey-lighten-5 mb-6">
          <SearchFilters />
        </div>

        <!-- Search Results -->
        <template v-if="filteredResults.length > 0">
          <div class="px-4">
            <SliderProducts
              :subcategory-name="`(${filteredResults.length}) Results for ${searchQuery}`"
              :products="filteredResults"
              mode="multi-row"
            />
          </div>
        </template>
        <template v-else>
          <v-alert
            type="info"
            class="mt-4 mx-4"
          >
            No results found for "{{ searchQuery }}"
          </v-alert>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import SearchFilters from '@/components/search/SearchFilters.vue'
import SearchCategories from '@/components/search/SearchCategories.vue'
import SliderProducts from '@/components/SliderProducts.vue'

export default {
  name: 'SearchPage',
  
  components: {
    SearchFilters,
    SearchCategories,
    SliderProducts
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    
    const searchQuery = ref('')

    // Computed properties
    const filteredResults = computed(() => store.getters['search/filteredResults'])

    // Methods
    const performSearch = async () => {
      if (searchQuery.value) {
        try {
          await store.dispatch('search/searchProducts', searchQuery.value)
          await store.dispatch('saveSearchQuery', searchQuery.value)
        } catch (error) {
          console.error('Search error:', error)
        }
      }
    }

    // Lifecycle hooks
    onMounted(async () => {
      searchQuery.value = route.query.q || ''
      await Promise.all([
        store.dispatch('category/fetchCategoryTree'),
        performSearch()
      ])
    })

    watch(() => route.query, (newQuery) => {
      searchQuery.value = newQuery.q || ''
      performSearch()
    })

    return {
      searchQuery,
      filteredResults
    }
  }
}
</script>

<style scoped>
.v-container {
  max-width: 1440px;
  margin: 0 auto;
}

.search-sections {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.filters-container {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/* Responsive styles */
@media (max-width: 599px) {
  .search-sections,
  .filters-container {
    border-radius: 0;
    margin-left: -16px;
    margin-right: -16px;
  }
}
</style>