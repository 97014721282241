<!-- ./frontend/src/views/CategoryPage.vue -->
<template>
  <div class="category-page">
    <v-container v-if="loading" class="d-flex justify-center align-center" style="min-height: 400px;">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-container>

    <v-container v-else-if="error" class="d-flex justify-center align-center" style="min-height: 400px;">
      <v-alert
        type="error"
        variant="tonal"
        :text="error"
      ></v-alert>
    </v-container>

    <v-container v-else class="category-container pa-2">
      <!-- Category Header Component -->
      <CategoryHeader
        :category-name="categoryName"
        :subcategories="subcategories"
      />

      <!-- No Subcategories Message -->
      <v-row v-if="subcategories.length === 0">
        <v-col cols="12" class="text-center pa-1">
          <v-alert
            type="info"
            variant="tonal"
            text="No subcategories available"
            class="ma-0"
          ></v-alert>
        </v-col>
      </v-row>

      <!-- Product Sliders Section -->
      <template v-if="subcategories.length > 0">
        <v-row v-for="subcategory in subcategories" :key="subcategory._id" class="mb-2">
          <v-col cols="12" class="pa-1">
            <SliderProducts
              :subcategory-name="subcategory.name"
              :products="getProductsBySubcategory(subcategory.name)"
              @add-to-cart="addToCart"
              @increase-quantity="increaseQuantity"
              @decrease-quantity="decreaseQuantity"
            />
          </v-col>
        </v-row>

        <!-- No Products Message -->
        <v-row v-if="!hasProducts" class="mt-2">
          <v-col cols="12" class="text-center pa-1">
            <v-alert
              type="info"
              variant="tonal"
              text="No products available in this category"
              class="ma-0"
            ></v-alert>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SliderProducts from '@/components/SliderProducts.vue';
import CategoryHeader from '@/components/CategoryHeader.vue';

export default {
  name: 'CategoryPage',
  
  components: {
    SliderProducts,
    CategoryHeader
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const error = ref(null);
    const selectedSubcategory = ref(null);
    
    // Computed properties
    const loading = computed(() => store.state.category.loading || store.state.product.loading);
    
    const category = computed(() => 
      store.getters['category/getCategoryByUrl'](route.params.category)
    );

    const categoryName = computed(() => 
      category.value ? category.value.name : ''
    );

    const categoryDescription = computed(() => 
      category.value ? category.value.description : ''
    );

    const subcategories = computed(() => 
      category.value ? category.value.subcategories.filter(sub => sub.isActive) : []
    );

    const hasProducts = computed(() => {
      return subcategories.value.some(subcategory => 
        getProductsBySubcategory(subcategory.name).length > 0
      );
    });

    // Methods
    const getProductsBySubcategory = (subcategoryName) => {
      return store.getters['product/getProductsBySubcategory'](subcategoryName) || [];
    };

    const addToCart = (product) => {
      store.dispatch('cart/addItem', { product, quantity: 1 });
    };

    const increaseQuantity = (product) => {
      store.dispatch('cart/incrementItem', product._id);
    };

    const decreaseQuantity = (product) => {
      store.dispatch('cart/decrementItem', product._id);
    };

    const initializeCategory = async () => {
      error.value = null;
      
      try {
        // Fetch categories if not already loaded
        if (!category.value) {
          await store.dispatch('category/fetchCategories');
        }

        const currentCategory = store.getters['category/getCategoryByUrl'](route.params.category);
        
        if (!currentCategory) {
          error.value = 'Category not found';
          return;
        }

        // Set initial selected subcategory
        if (subcategories.value.length > 0 && !selectedSubcategory.value) {
          selectedSubcategory.value = subcategories.value[0].name;
        }

        // Fetch products for the category
        await store.dispatch('product/fetchProductsByCategory', currentCategory._id);

        // Record category view
        await store.dispatch('trend/recordView', {
          type: 'category',
          itemId: currentCategory._id
        });
      } catch (err) {
        console.error('Error initializing category:', err);
        error.value = 'Error loading category data. Please try again.';
      }
    };
    
    // Lifecycle hooks
    onMounted(() => {
      initializeCategory();
    });

    // Watch for route changes
    watch(() => route.params.category, () => {
      initializeCategory();
    });

    return {
      // State
      error,
      loading,
      
      // Computed
      categoryName,
      categoryDescription,
      subcategories,
      hasProducts,
      
      // Methods
      getProductsBySubcategory,
      addToCart,
      increaseQuantity,
      decreaseQuantity
    };
  }
};
</script>

<style scoped>
.category-page {
  min-height: 100vh;
}

.category-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>