<!-- ./frontend/src/views/AdminLocalShippingZones.vue -->
<template>
  <v-container>
    <h1>Admin: Local Shipping Zones</h1>
    <v-btn color="primary" @click="openAddDialog" class="mb-4">Add New Local Shipping Zone</v-btn>
    <v-data-table
      :headers="headers"
      :items="localShippingZones"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn small color="primary" @click="editLocalShippingZone(item)">Edit</v-btn>
        <v-btn small color="error" @click="deleteLocalShippingZone(item._id)">Delete</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="saveLocalShippingZone" ref="form">
            <v-text-field
              v-model="editedLocalShippingZone.name"
              label="Name"
              :rules="[v => !!v || 'Name is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedLocalShippingZone.province"
              label="Province"
              :rules="[v => !!v || 'Province is required']"
              required
            ></v-text-field>
            <v-textarea
              v-model="editedLocalShippingZone.description"
              label="Description"
            ></v-textarea>
            <v-combobox
              v-model="editedLocalShippingZone.RSACodes"
              label="RSA Codes"
              multiple
              chips
              small-chips
            ></v-combobox>
            <v-switch
              v-model="editedLocalShippingZone.isActive"
              label="Active"
            ></v-switch>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveLocalShippingZone">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const dialog = ref(false)
const form = ref(null)
const editedIndex = ref(-1)

const headers = ref([
  { 
    title: 'Name',
    key: 'name',
    align: 'start',
    sortable: true
  },
  { 
    title: 'Province',
    key: 'province',
    align: 'start',
    sortable: true
  },
  { 
    title: 'Description',
    key: 'description',
    align: 'start',
    sortable: true
  },
  { 
    title: 'RSA Codes',
    key: 'RSACodes',
    align: 'start',
    sortable: false
  },
  { 
    title: 'Active',
    key: 'isActive',
    align: 'center',
    sortable: true
  },
  { 
    title: 'Actions',
    key: 'actions',
    align: 'center',
    sortable: false
  }
])

const defaultLocalShippingZone = {
  name: '',
  province: '',
  description: '',
  RSACodes: [],
  isActive: true
}

const editedLocalShippingZone = ref({ ...defaultLocalShippingZone })

const formTitle = computed(() => {
  return editedIndex.value === -1 ? 'New Local Shipping Zone' : 'Edit Local Shipping Zone'
})

const localShippingZones = computed(() => store.state.localShippingZones)

const editLocalShippingZone = (localShippingZone) => {
  editedIndex.value = localShippingZones.value.indexOf(localShippingZone)
  editedLocalShippingZone.value = Object.assign({}, localShippingZone)
  dialog.value = true
}

const openAddDialog = () => {
  editedIndex.value = -1
  editedLocalShippingZone.value = Object.assign({}, defaultLocalShippingZone)
  dialog.value = true
}

const closeDialog = () => {
  dialog.value = false
  nextTick(() => {
    editedLocalShippingZone.value = Object.assign({}, defaultLocalShippingZone)
    editedIndex.value = -1
  })
}

const saveLocalShippingZone = async () => {
  if (form.value.validate()) {
    try {
      if (editedIndex.value > -1) {
        await store.dispatch('updateLocalShippingZone', editedLocalShippingZone.value)
      } else {
        await store.dispatch('createLocalShippingZone', editedLocalShippingZone.value)
      }
      closeDialog()
      await store.dispatch('fetchLocalShippingZones')
    } catch (error) {
      console.error('Error saving local shipping zone:', error)
    }
  }
}

onMounted(() => {
  store.dispatch('fetchLocalShippingZones')
})
</script>