<!-- ./frontend/src/components/SearchAutocomplete.vue -->
<template>
  <v-card class="search-autocomplete" color="rgb(21, 46, 25)">
    <v-container class="pa-4" fluid>
      <v-list v-if="!loading && results.length > 0" lines="one" class="transparent px-2">
        <v-list-item
          v-for="result in results"
          :key="result.text"
          :to="{ 
            name: 'Search',
            query: { q: result.text }
          }"
          class="search-item py-1"
          color="transparent"
        >
          <template #prepend>
            <v-icon color="white" size="small" class="mr-2">
              {{ result.category === 'seller' ? 'mdi-store' : 'mdi-magnify' }}
            </v-icon>
          </template>
          <v-list-item-title class="text-white text-body-1">
            {{ result.text }}
          </v-list-item-title>
          <template #append v-if="result.category === 'seller'">
            <span class="text-caption text-white">Seller</span>
          </template>
        </v-list-item>
      </v-list>

      <div v-else-if="!loading && query && query.length >= 3" class="text-center py-4">
        <span class="text-white">No suggestions found for "{{ query }}"</span>
      </div>

      <div v-else-if="!loading && query && query.length < 3" class="text-center py-4">
        <span class="text-white">Type at least 3 characters to search</span>
      </div>

      <!-- Loading State -->
      <v-overlay
        :model-value="loading"
        class="align-center justify-center"
        contained
        persistent
      >
        <v-progress-circular
          color="white"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

      <!-- Error Alert -->
      <v-alert
        v-if="error"
        type="error"
        class="ma-4"
        closable
        @click:close="error = null"
      >
        {{ error }}
      </v-alert>
    </v-container>
  </v-card>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'SearchAutocomplete',
  
  props: {
    query: {
      type: String,
      required: true
    }
  },
  
  setup(props) {
    const store = useStore();
    const router = useRouter();
    
    // Computed properties for reactivity
    const results = computed(() => store.getters['search/autocompleteResults']);
    const loading = computed(() => store.getters['search/isLoading']);
    const error = computed(() => store.getters['search/error']);

    // Debounce function to limit API calls
    const debounce = (fn, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(...args), delay);
      };
    };

    // Debounced search function
    const debouncedSearch = debounce((query) => {
      store.dispatch('search/fetchAutocompleteResults', query);
    }, 300);

    // Watch for query changes
    watch(() => props.query, (newQuery) => {
      if (!newQuery || newQuery.trim() === '') {
        store.dispatch('search/clearAutocompleteResults');
        return;
      }
      debouncedSearch(newQuery);
    });

    // Handle search suggestion selection
    const handleSuggestionSelect = (suggestion) => {
      router.push({
        name: 'Search',
        query: { q: suggestion.text }
      });
    };

    return {
      results,
      loading,
      error,
      handleSuggestionSelect
    };
  }
};
</script>

<style scoped>
.search-autocomplete {
  position: relative;
  min-height: 100px;
  border-radius: 8px;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.transparent {
  background-color: transparent !important;
}

.search-item {
  min-height: 40px !important;
  transition: all 0.2s ease;
}

.search-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  cursor: pointer;
  transform: translateX(4px);
}

.v-list-item__prepend {
  opacity: 0.7;
}

.v-list-item__append {
  opacity: 0.7;
}

.text-caption {
  font-size: 0.75rem;
  opacity: 0.8;
}

.v-overlay {
  border-radius: 8px;
}

.v-progress-circular {
  width: 48px !important;
  height: 48px !important;
}

.v-alert {
  margin-top: 8px;
}

/* Media queries for responsive design */
@media (max-width: 600px) {
  .search-autocomplete {
    border-radius: 4px;
  }

  .search-item {
    min-height: 36px !important;
  }

  .v-progress-circular {
    width: 36px !important;
    height: 36px !important;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1785px !important;
  }
}

/* Transitions */
.v-list-item {
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.v-icon {
  transition: transform 0.2s ease;
}

.search-item:hover .v-icon {
  transform: scale(1.1);
}

/* Vuetify overrides */
:deep(.v-list-item__content) {
  padding: 8px 0;
}

:deep(.v-list-item__prepend) {
  padding-right: 12px;
}

:deep(.v-list-item__append) {
  padding-left: 12px;
}
</style>