// ./frontend/src/store/modules/trend.js

import axiosInstance from '../../utils/axios';

export const state = {
  loading: false,
  error: null,
  lastUpdateAttempt: null,
  weeklyTrends: null,
  trendingProducts: [],
  trendingCategories: [],
  trendingSearches: [],
  processing: false
};

export const mutations = {
  SET_LOADING(state, status) {
    state.loading = status;
  },

  SET_PROCESSING(state, status) {
    state.processing = status;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  CLEAR_ERROR(state) {
    state.error = null;
  },

  SET_LAST_UPDATE_ATTEMPT(state, timestamp) {
    state.lastUpdateAttempt = timestamp;
  },

  SET_WEEKLY_TRENDS(state, trends) {
    state.weeklyTrends = trends;
  },

  SET_TRENDING_ITEMS(state, { products, categories, searches }) {
    state.trendingProducts = products || [];
    state.trendingCategories = categories || [];
    state.trendingSearches = searches || [];
  },

  RESET_STATE(state) {
    state.loading = false;
    state.error = null;
    state.lastUpdateAttempt = null;
    state.weeklyTrends = null;
    state.trendingProducts = [];
    state.trendingCategories = [];
    state.trendingSearches = [];
    state.processing = false;
  }
};

export const actions = {
  // Record a view for trending items
  async recordView({ commit }, { type, itemId, query = null }) {
    try {
      await axiosInstance.post('/api/trends/view', {
        type,
        itemId,
        query
      });
    } catch (error) {
      console.error('Error recording view:', error);
      // Don't throw error for view recording to prevent UI disruption
    }
  },

  // Get visible trending items
  async getVisibleTrends({ commit }) {
    try {
      commit('SET_LOADING', true);
      commit('CLEAR_ERROR');
      
      const response = await axiosInstance.get('/api/trends/visible');
      
      commit('SET_TRENDING_ITEMS', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching visible trends:', error);
      const errorMessage = error.response?.data?.error || 'Failed to load trends';
      commit('SET_ERROR', errorMessage);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // Get current weekly trends (admin only)
  async getCurrentWeeklyTrends({ commit }) {
    try {
      commit('SET_LOADING', true);
      commit('CLEAR_ERROR');

      const response = await axiosInstance.get('/api/trends/weekly/current');
      
      if (response.data) {
        commit('SET_WEEKLY_TRENDS', response.data);
        commit('SET_TRENDING_ITEMS', {
          products: response.data.products || [],
          categories: response.data.categories || [],
          searches: response.data.searches || []
        });
      }
      
      return response.data;
    } catch (error) {
      console.error('Error fetching weekly trends:', error);
      const errorMessage = error.response?.data?.error || 'Failed to load weekly trends';
      commit('SET_ERROR', errorMessage);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // Generate new weekly trends (admin only)
  async generateWeeklyTrends({ commit }) {
    try {
      commit('SET_LOADING', true);
      commit('CLEAR_ERROR');
      commit('SET_LAST_UPDATE_ATTEMPT', new Date());

      const response = await axiosInstance.post('/api/trends/weekly/generate');
      
      if (!response.data?.success) {
        throw new Error('Failed to generate trends');
      }

      // Update weekly trends data
      if (response.data.weeklyTrend) {
        commit('SET_WEEKLY_TRENDS', response.data.weeklyTrend);
      }

      return response.data;
    } catch (error) {
      console.error('Error generating weekly trends:', error);
      const errorMessage = error.response?.data?.error || 'Failed to generate trends';
      commit('SET_ERROR', errorMessage);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // Update item visibility (admin only)
  async updateItemVisibility({ commit, state }, { type, rank, isVisible }) {
    if (!state.weeklyTrends?._id) {
      throw new Error('No weekly trend data available');
    }

    try {
      commit('SET_PROCESSING', true);
      commit('CLEAR_ERROR');

      const response = await axiosInstance.patch(
        `/api/trends/weekly/${state.weeklyTrends._id}/${type}/${rank}`,
        { isVisible }
      );

      if (response.data) {
        // Update weekly trends data with the response
        commit('SET_WEEKLY_TRENDS', response.data);
      }

      return response.data;
    } catch (error) {
      console.error('Error updating item visibility:', error);
      const errorMessage = error.response?.data?.error || 'Failed to update visibility';
      commit('SET_ERROR', errorMessage);
      throw error;
    } finally {
      commit('SET_PROCESSING', false);
    }
  },

  // Get analytics data (admin only)
  async getAnalytics({ commit }, { startDate, endDate }) {
    try {
      commit('SET_LOADING', true);
      commit('CLEAR_ERROR');

      const response = await axiosInstance.get('/api/trends/analytics', {
        params: { startDate, endDate }
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching analytics:', error);
      const errorMessage = error.response?.data?.error || 'Failed to fetch analytics';
      commit('SET_ERROR', errorMessage);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // Reset store state
  resetState({ commit }) {
    commit('RESET_STATE');
  }
};

export const getters = {
  isLoading: state => state.loading,
  isProcessing: state => state.processing,
  error: state => state.error,
  lastUpdateAttempt: state => state.lastUpdateAttempt,
  weeklyTrendId: state => state.weeklyTrends?._id,
  trendingProducts: state => state.trendingProducts.filter(item => item.isVisible),
  trendingCategories: state => state.trendingCategories.filter(item => item.isVisible),
  trendingSearches: state => state.trendingSearches.filter(item => item.isVisible),
  allTrendingProducts: state => state.trendingProducts,
  allTrendingCategories: state => state.trendingCategories,
  allTrendingSearches: state => state.trendingSearches,
  hasVisibleTrends: state => 
    state.trendingProducts.some(item => item.isVisible) ||
    state.trendingCategories.some(item => item.isVisible) ||
    state.trendingSearches.some(item => item.isVisible)
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};