<!-- ./frontend/src/components/ProductActionButtons.vue -->
<template>
  <div>
    <div class="mb-4">
      <v-btn
        color="primary"
        class="list-pill"
        variant="flat"
        density="comfortable"
        disabled
      >
        <v-icon icon="mdi-playlist-plus" color="white" class="mr-2" />
        Add to List
      </v-btn>
    </div>

    <div class="d-flex align-center gap-4">
      <div class="pill-container">
        <v-btn
          variant="outlined"
          :color="isRecommended ? 'primary' : undefined"
          density="comfortable"
          class="pill-left"
          @click="toggleRecommend"
        >
          <v-icon :icon="isRecommended ? 'mdi-thumb-up' : 'mdi-thumb-up-outline'" />
        </v-btn>
        <v-btn
          variant="outlined"
          density="comfortable"
          class="pill-right"
          @click="showCommentDialog = true"
        >
          <v-icon icon="mdi-comment-text" class="mr-2" />
          Comment
        </v-btn>
      </div>

      <v-btn
        variant="outlined"
        density="comfortable"
        class="pill-single"
        @click="shareProduct"
      >
        <v-icon icon="mdi-share" class="mr-2" />
        Share
      </v-btn>
    </div>

    <!-- Comment Dialog -->
    <v-dialog
      v-model="showCommentDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h6 pa-4">Add Comment</v-card-title>
        
        <v-card-text>
          <v-textarea
            v-model="commentText"
            label="Write your comment"
            rows="4"
            auto-grow
            variant="outlined"
            class="mb-3"
          />
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-container class="pa-0">
            <v-row justify="center">
              <v-col cols="12">
                <v-btn
                  block
                  color="error"
                  variant="outlined"
                  height="48"
                  rounded="lg"
                  class="mb-3 custom-button custom-button--error"
                  @click="closeCommentDialog"
                >
                  CANCEL
                </v-btn>
                <v-btn
                  block
                  color="primary"
                  height="48"
                  rounded="lg"
                  class="custom-button custom-button--primary"
                  @click="submitComment"
                  :disabled="!commentText.trim()"
                >
                  SUBMIT COMMENT
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ProductActionButtons',

  props: {
    productId: {
      type: String,
      required: true
    },
    productName: {
      type: String,
      required: true
    },
    isSaved: {
      type: Boolean,
      required: true
    },
    isRecommended: {
      type: Boolean,
      required: true
    }
  },

  emits: ['update:isSaved', 'update:isRecommended', 'show-message'],

  setup(props, { emit }) {
    const store = useStore()
    const showCommentDialog = ref(false)
    const commentText = ref('')

    const toggleRecommend = async () => {
      try {
        if (props.isRecommended) {
          await store.dispatch('deleteRecommendation', {
            productId: props.productId,
            type: 'recommendation'
          })
          emit('show-message', { text: 'Recommendation removed', type: 'success' })
        } else {
          await store.dispatch('createRecommendation', {
            productId: props.productId,
            type: 'recommendation'
          })
          emit('show-message', { text: 'Product recommended', type: 'success' })
        }
        emit('update:isRecommended', !props.isRecommended)
      } catch (error) {
        emit('show-message', { text: 'Error updating recommendation', type: 'error' })
      }
    }

    const shareProduct = async () => {
      const url = window.location.href
      
      if (navigator.share) {
        try {
          await navigator.share({
            title: props.productName,
            url: url
          })
          emit('show-message', { text: 'Shared successfully', type: 'success' })
        } catch (error) {
          if (error.name !== 'AbortError') {
            emit('show-message', { text: 'Error sharing product', type: 'error' })
          }
        }
      } else {
        try {
          await navigator.clipboard.writeText(url)
          emit('show-message', { text: 'Link copied to clipboard', type: 'success' })
        } catch (error) {
          emit('show-message', { text: 'Error copying link', type: 'error' })
        }
      }
    }

    const closeCommentDialog = () => {
      showCommentDialog.value = false
      commentText.value = ''
    }

    const submitComment = async () => {
      try {
        // TODO: Add store dispatch for submitting comment
        await store.dispatch('submitProductComment', {
          productId: props.productId,
          comment: commentText.value
        })
        emit('show-message', { text: 'Comment submitted successfully', type: 'success' })
        closeCommentDialog()
      } catch (error) {
        emit('show-message', { text: 'Error submitting comment', type: 'error' })
      }
    }

    return {
      toggleRecommend,
      shareProduct,
      showCommentDialog,
      commentText,
      closeCommentDialog,
      submitComment
    }
  }
})
</script>

<style scoped>
.gap-4 {
  gap: 1rem;
}

.list-pill {
  min-width: 140px;
  height: 36px !important;
  border-radius: 20px;
  padding: 0 16px;
  transition: background-color 0.2s ease;
}

.pill-single {
  border-radius: 20px !important;
}

.pill-container {
  position: relative;
  display: flex;
}

.pill-left {
  border-radius: 20px 0 0 20px !important;
  margin-right: -1px;
  z-index: 2;
}

.pill-right {
  border-radius: 0 20px 20px 0 !important;
  border-left: none !important;
}

.custom-button {
  text-transform: uppercase !important;
  letter-spacing: 0.0892857143em !important;
  font-weight: 500 !important;
  width: 100% !important;
}

.custom-button--primary {
  background-color: rgb(var(--v-theme-primary)) !important;
  color: rgb(var(--v-theme-on-primary)) !important;
}

.custom-button--error {
  border-color: rgb(var(--v-theme-error)) !important;
  color: rgb(var(--v-theme-error)) !important;
}

@media (max-width: 599px) {
  .list-pill {
    min-width: 120px;
    height: 32px !important;
  }

  .v-dialog {
    margin: 0 !important;
  }
}
</style>