<!-- ./frontend/src/views/CartPage.vue -->
<template>
  <v-container class="pa-4" fluid>
    <v-row>
      <!-- Left column for cart items -->
      <v-col cols="12" md="8">
        <!-- Pickup and Delivery Options -->
        <v-card class="mb-4">
          <v-card-title>Pickup and delivery options</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-for="option in deliveryOptions" :key="option._id" cols="6">
                <v-sheet
                  border
                  class="d-flex flex-column justify-center align-center mb-4"
                  color="surface-light"
                  min-height="100"
                  rounded="lg"
                  :class="{ 'success lighten-4': selectedDeliveryOption === option }"
                  @click="toggleDeliveryOption(option)"
                >
                  <v-icon 
                    :color="selectedDeliveryOption === option ? 'success' : 'medium-emphasis'" 
                    :icon="option.title === 'Pickup' ? 'mdi-store' : 'mdi-truck-delivery'" 
                  />
                  <p class="font-weight-bold mt-2">{{ option.title }}</p>
                  <p class="text-caption">{{ option.description }}</p>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Cart Items -->
        <v-card class="mt-4">
          <v-card-title>
            {{ cartItemCount }} items
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              :items="sortOptions"
              item-title="text"
              item-value="value"
              label="Sort by"
              density="compact"
              variant="outlined"
            ></v-select>
          </v-card-title>
          <v-card-text>
            <template v-for="(group, groupTitle) in groupedCartItems" :key="groupTitle">
              <template v-if="group && group.length > 0">
                <v-card class="mb-4" v-if="selectedDeliveryOption">
                  <v-card-title>
                    <template v-if="groupTitle === 'Delivery'">
                      {{ groupTitle }}, arrives by {{ formattedDeliveryDate }}
                    </template>
                    <template v-else-if="groupTitle === 'Pickup'">
                      {{ groupTitle }}
                    </template>
                    <template v-else>
                      {{ groupTitle }}
                    </template>
                  </v-card-title>
                </v-card>
                <template v-if="sortBy === 'category' && !selectedDeliveryOption">
                  <h3 class="text-h6 mb-4">{{ groupTitle }}</h3>
                </template>
                <div v-for="item in group" :key="item._id" class="mb-6">
                  <div class="d-flex ga-4">
                    <v-img
                      :src="item.productImage || 'https://via.placeholder.com/150'"
                      class="flex-shrink-0"
                      height="150"
                      width="150"
                      cover
                      rounded="lg"
                    ></v-img>
                    <div class="flex-grow-1 ml-4">
                      <div class="d-flex justify-space-between">
                        <div>
                          <h3 class="text-h6">{{ item.name }}</h3>
                          <p class="text-body-2">${{ item.price }} / each</p>
                          <v-chip 
                            size="small" 
                            color="charcoal" 
                            text-color="white" 
                            class="mt-1"
                          >
                            {{ item.category }}
                          </v-chip>
                        </div>
                        <div>
                          <p class="text-h6">${{ (item.price * item.quantity).toFixed(2) }}</p>
                          <p v-if="item.deliveryOption === 'Delivery'">
                            Ready by {{ item.readyByDate }}
                          </p>
                          <v-btn 
                            v-if="item.deliveryOptions && item.deliveryOptions.includes('Pickup') && item.deliveryOptions.includes('Delivery')"
                            class="text-none px-0 mt-2" 
                            color="charcoal" 
                            :ripple="false" 
                            size="default" 
                            variant="plain" 
                            @click="changeDeliveryOption(item)"
                          >
                            {{ item.deliveryOption === 'Pickup' ? 'Change to delivery' : 'Change to pickup' }}
                          </v-btn>
                        </div>
                      </div>
                      <div class="d-flex justify-space-between align-center mt-4">
                        <div>
                          <v-btn 
                            class="text-none px-0 mr-2" 
                            color="charcoal" 
                            :ripple="false" 
                            size="default" 
                            variant="plain" 
                            @click="removeFromCart(item._id)"
                          >
                            Remove
                          </v-btn>
                          <v-btn 
                            class="text-none px-0" 
                            color="charcoal" 
                            :ripple="false" 
                            size="default" 
                            variant="plain" 
                            @click="saveForLater(item)"
                          >
                            Save for later
                          </v-btn>
                        </div>
                        <v-btn-group>
                          <v-btn icon @click="decrementQuantity(item)">
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                          <v-btn text>{{ item.quantity }}</v-btn>
                          <v-btn icon @click="incrementQuantity(item)">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-btn-group>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      
      <!-- Right column for cart summary -->
      <v-col cols="12" md="4">
        <v-card>
          <v-card-text>
            <v-btn 
              color="#4CAF50" 
              block 
              @click="goToCheckout" 
              :disabled="isCartEmpty"
              class="mb-4"
            >
              Continue to checkout
            </v-btn>
            <div class="d-flex justify-space-between mb-2">
              <p class="text-body-1">Subtotal ({{ cartItemCount }} items)</p>
              <p>${{ subtotal.toFixed(2) }}</p>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <p class="text-body-1">Taxes (13% HST)</p>
              <p>${{ taxes.toFixed(2) }}</p>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between mb-4">
              <p class="text-body-1 font-weight-bold">Estimated total</p>
              <p class="font-weight-bold">${{ total.toFixed(2) }}</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbarText }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { productDeliveryDate, orderDeliveryDate } from '@/utils/dateUtils'

export default {
  name: 'CartPage',
  setup() {
    const store = useStore()
    const router = useRouter()

    const sortBy = ref('time')
    const sortOptions = ref([
      { text: 'Time added to cart', value: 'time' },
      { text: 'Category', value: 'category' },
    ])
    const snackbar = ref(false)
    const snackbarText = ref('')

    // Ensure cart is always an array
    const cart = computed(() => store.state.cart?.cart || [])
    const cartItemCount = computed(() => store.getters['cart/cartItemCount'] || 0)
    const subtotal = computed(() => store.getters['cart/cartTotal'] || 0)
    const taxes = computed(() => subtotal.value * 0.13)
    const deliveryFee = ref(5.99)
    const total = computed(() => subtotal.value + taxes.value + (quantityDelivery.value > 0 ? deliveryFee.value : 0))
    const isCartEmpty = computed(() => cartItemCount.value === 0)

    const selectedDeliveryOption = computed({
      get: () => store.state.cart?.selectedDeliveryOption || null,
      set: (value) => store.dispatch('cart/setSelectedDeliveryOption', value)
    })

    const deliveryOptions = ref([
      { _id: 'pickup', title: 'Pickup', description: 'Pick up your order' },
      { _id: 'delivery', title: 'Delivery', description: 'Get your order delivered' }
    ])

    const groupedCartItems = computed(() => {
      const cartItems = cart.value || []
      
      if (selectedDeliveryOption.value) {
        return {
          [selectedDeliveryOption.value.title]: cartItems.filter(item => 
            item.deliveryOption === selectedDeliveryOption.value.title
          ),
          [selectedDeliveryOption.value.title === 'Delivery' ? 'Pickup' : 'Delivery']: 
            cartItems.filter(item => item.deliveryOption !== selectedDeliveryOption.value.title)
        }
      } else if (sortBy.value === 'category') {
        return cartItems.reduce((acc, item) => {
          const category = item.category || 'Uncategorized'
          if (!acc[category]) {
            acc[category] = []
          }
          acc[category].push(item)
          return acc
        }, {})
      } else {
        return { 
          'All Items': [...cartItems].sort((a, b) => 
            new Date(b.addedAt || 0) - new Date(a.addedAt || 0)
          ) 
        }
      }
    })

    const deliveryDate = ref(null)
    const formattedDeliveryDate = computed(() => {
      return deliveryDate.value ? formatDate(deliveryDate.value) : 'Not available'
    })

    const quantityDelivery = computed(() => {
      return (cart.value || [])
        .filter(item => item.deliveryOption === 'Delivery')
        .reduce((sum, item) => sum + (item.quantity || 0), 0)
    })

    const quantityPickUp = computed(() => {
      return (cart.value || [])
        .filter(item => item.deliveryOption === 'Pickup')
        .reduce((sum, item) => sum + (item.quantity || 0), 0)
    })

    const updateDeliveryDates = async () => {
      const deliveryItems = (cart.value || []).filter(item => item.deliveryOption === 'Delivery')
      try {
        deliveryDate.value = await orderDeliveryDate(deliveryItems)
        
        const updatedCart = await Promise.all((cart.value || []).map(async (item) => {
          if (item.deliveryOption === 'Delivery') {
            const readyByDate = await productDeliveryDate(item)
            return { 
              ...item, 
              readyByDate: readyByDate ? formatDate(readyByDate) : 'Not available' 
            }
          }
          return item
        }))
        
        store.commit('cart/SET_CART', updatedCart)
      } catch (error) {
        console.error('Error updating delivery dates:', error)
        snackbar.value = true
        snackbarText.value = 'Error updating delivery dates'
      }
    }

    const formatDate = (date) => {
      if (!date) return 'Not available'
      return new Date(date).toLocaleDateString('en-US', { 
        weekday: 'long', 
        month: 'long', 
        day: 'numeric' 
      })
    }

    const toggleDeliveryOption = (option) => {
      selectedDeliveryOption.value = option
      updateAllCartItemsDeliveryOption(option.title)
      updateDeliveryDates()
    }

    const updateAllCartItemsDeliveryOption = (optionTitle) => {
      (cart.value || []).forEach(item => {
        if (item.deliveryOptions?.includes(optionTitle)) {
          changeDeliveryOption(item, optionTitle)
        }
      })
    }

    const changeDeliveryOption = (item, newOption = null) => {
      const newDeliveryOption = newOption || 
        (item.deliveryOption === 'Pickup' ? 'Delivery' : 'Pickup')
      store.dispatch('cart/updateCartItemDeliveryOption', { 
        productId: item._id, 
        deliveryOption: newDeliveryOption 
      })
      updateDeliveryDates()
    }

    const removeFromCart = (productId) => {
      store.dispatch('cart/removeFromCart', productId)
    }

    const decrementQuantity = (item) => {
      if (item.quantity > 1) {
        updateCartItemQuantity(item._id, item.quantity - 1)
      } else {
        removeFromCart(item._id)
      }
    }

    const incrementQuantity = (item) => {
      updateCartItemQuantity(item._id, item.quantity + 1)
    }

    const updateCartItemQuantity = (productId, quantity) => {
      store.dispatch('cart/updateCartItemQuantity', { productId, quantity })
    }

    const saveForLater = (item) => {
      store.dispatch('cart/saveForLater', item)
      snackbar.value = true
      snackbarText.value = 'Item saved for later'
    }

    const goToCheckout = () => {
      router.push('/checkout')
    }

    onMounted(async () => {
      if (cart.value && cart.value.length > 0) {
        const cartProductIds = cart.value.map(item => item._id)
        await store.dispatch('cart/fetchCartProductInfo', cartProductIds)
        // If a delivery option was previously selected, update the dates
        if (selectedDeliveryOption.value) {
          await updateDeliveryDates()
        }
      }
      // Set selectedDeliveryOption to null if cart is empty
      if (isCartEmpty.value) {
        selectedDeliveryOption.value = null
      }
    })

    watch(() => [quantityDelivery.value, quantityPickUp.value], ([newDeliveryQuantity, newPickupQuantity]) => {
      if (newDeliveryQuantity === 0 && selectedDeliveryOption.value?.title === 'Delivery') {
        selectedDeliveryOption.value = null
      } else if (newPickupQuantity === 0 && selectedDeliveryOption.value?.title === 'Pickup') {
        selectedDeliveryOption.value = null
      }
    })

    // Watch cart changes to update delivery dates
    watch(() => cart.value, async (newCart) => {
      if (newCart && newCart.length > 0 && selectedDeliveryOption.value) {
        await updateDeliveryDates()
      }
    }, { deep: true })

    return {
      sortBy,
      sortOptions,
      selectedDeliveryOption,
      cart,
      cartItemCount,
      isCartEmpty,
      subtotal,
      taxes,
      total,
      deliveryOptions,
      groupedCartItems,
      formattedDeliveryDate,
      snackbar,
      snackbarText,
      toggleDeliveryOption,
      changeDeliveryOption,
      decrementQuantity,
      incrementQuantity,
      removeFromCart,
      updateCartItemQuantity,
      saveForLater,
      goToCheckout,
      quantityDelivery,
      quantityPickUp,
    }
  }
}
</script>

<style scoped>
.v-btn {
  text-transform: none;
}

.quantity-control {
  background-color: #4CAF50;
  border-radius: 9999px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 40px;
}

.quantity-control .v-btn {
  color: white !important;
  min-width: 36px;
  width: 36px;
  height: 36px;
}

.quantity-control span {
  color: white;
  flex-grow: 1;
  text-align: center;
}

.active-icon {
  color: #2E7D32 !important;
}

.ga-4 {
  gap: 1rem;
}
</style>