# src/components/SliderCategory.vue

<template>
  <div :class="{ 'mb-8': $vuetify.display.mdAndUp, 'mb-4': $vuetify.display.smAndDown }">
    <div class="d-flex justify-space-between align-center mb-4" :class="{ 'px-4': $vuetify.display.smAndDown }">
      <h2 :class="{ 'text-h5 font-weight-bold': $vuetify.display.mdAndUp, 'text-h6 font-weight-bold': $vuetify.display.smAndDown }">
        Popular Categories
      </h2>
      <v-btn
        variant="text"
        class="text-none"
        @click="$router.push('/browse')"
        :size="$vuetify.display.smAndDown ? 'small' : 'default'"
      >
        View All <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <!-- Scrollable Categories -->
    <v-sheet class="position-relative">
      <v-btn
        v-if="$vuetify.display.mdAndUp"
        icon="mdi-chevron-left"
        variant="text"
        class="scroll-button left-button"
        @click="scroll('left')"
        :disabled="atStart"
      ></v-btn>
      
      <div class="categories-scroll" ref="containerRef">
        <v-row nowrap class="flex-nowrap custom-row">
          <v-col v-for="category in categories" 
                :key="category.title" 
                cols="auto"
                class="custom-col"
          >
            <div class="category-wrapper">
              <v-card
                :width="cardWidth"
                class="category-card"
                @click="$router.push(category.route)"
                elevation="0"
                rounded="16"
              >
                <v-img
                  :src="category.image"
                  :height="cardWidth"
                  cover
                  class="rounded-lg"
                >
                  <template #placeholder>
                    <v-skeleton-loader type="image"></v-skeleton-loader>
                  </template>
                </v-img>
              </v-card>
              <div class="category-title mt-2 text-center">{{ category.title }}</div>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-btn
        v-if="$vuetify.display.mdAndUp"
        icon="mdi-chevron-right"
        variant="text"
        class="scroll-button right-button"
        @click="scroll('right')"
        :disabled="atEnd"
      ></v-btn>
    </v-sheet>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

export default {
  name: 'CategorySlider',
  
  props: {
    categories: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  setup(props) {
    const router = useRouter();
    const display = useDisplay();
    const containerRef = ref(null);
    const atStart = ref(true);
    const atEnd = ref(false);

    // Compute responsive card sizes
    const cardWidth = computed(() => {
      if (display.xs.value) return '120';
      if (display.sm.value) return '130';
      return '140';
    });

    // Scroll function for navigation buttons
    const scroll = (direction) => {
      const scrollAmount = 200;
      if (containerRef.value) {
        if (direction === 'left') {
          containerRef.value.scrollLeft -= scrollAmount;
        } else {
          containerRef.value.scrollLeft += scrollAmount;
        }
        checkScrollPosition();
      }
    };

    // Check scroll position to enable/disable nav buttons
    const checkScrollPosition = () => {
      if (containerRef.value) {
        atStart.value = containerRef.value.scrollLeft <= 0;
        atEnd.value = 
          containerRef.value.scrollLeft + containerRef.value.clientWidth >= 
          containerRef.value.scrollWidth;
      }
    };

    // Initialize scroll position check and listeners
    onMounted(() => {
      checkScrollPosition();
      if (containerRef.value) {
        containerRef.value.addEventListener('scroll', checkScrollPosition);
        // Add resize listener for responsive updates
        window.addEventListener('resize', checkScrollPosition);
      }
    });

    return {
      containerRef,
      scroll,
      atStart,
      atEnd,
      cardWidth,
      // Expose for template
      router,
      display
    };
  }
};
</script>

<style scoped>
.categories-scroll {
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  position: relative;
  padding: 0 40px;
}

@media (max-width: 600px) {
  .categories-scroll {
    padding: 0 16px;
  }
}

.categories-scroll::-webkit-scrollbar {
  display: none;
}

.custom-row {
  margin-right: 0;
  margin-left: 0;
}

.custom-col {
  padding-right: 8px;
  padding-left: 8px;
}

@media (max-width: 600px) {
  .custom-col {
    padding-right: 6px;
    padding-left: 6px;
  }
}

.category-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.category-wrapper:hover .category-card {
  transform: scale(1.05);
}

.category-card {
  transition: transform 0.2s;
  border-radius: 16px;
  overflow: hidden;
}

.category-title {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 12px;
  width: 100%;
  white-space: normal;
  text-align: center;
  line-height: 1.3;
}

@media (max-width: 600px) {
  .category-title {
    font-size: 14px;
    margin-top: 8px;
  }
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: rgba(255,255,255,0.9) !important;
}

.left-button {
  left: 0;
}

.right-button {
  right: 0;
}

/* Disable hover effects on touch devices */
@media (hover: none) {
  .category-wrapper:hover .category-card {
    transform: none;
  }
}
</style>