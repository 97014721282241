<!-- ./frontend/src/views/OrderConfirmationPage.vue -->
<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="mt-5">
          <v-card-title class="text-h4 text-center">Order Confirmation</v-card-title>
          <v-card-text>
            <v-alert type="success" class="mb-4">
              Your order has been successfully placed!
            </v-alert>
            <p class="text-h6">Order Details:</p>
            <p><strong>Order ID:</strong> {{ order._id }}</p>
            <p><strong>Total Amount:</strong> ${{ order.totalAmount.toFixed(2) }}</p>
            <p><strong>Status:</strong> {{ order.status }}</p>
            <v-divider class="my-4"></v-divider>
            <p class="text-h6">Items:</p>
            <v-list>
              <v-list-item v-for="item in order.items" :key="item._id">
                <v-list-item-content>
                  <v-list-item-title>{{ item.productId.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    Quantity: {{ item.quantity }} | Price: ${{ item.price.toFixed(2) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-4"></v-divider>
            <p class="text-h6">Shipping Information:</p>
            <p>{{ order.deliveryAddress.street }}</p>
            <p>{{ order.deliveryAddress.city }}, {{ order.deliveryAddress.province }} {{ order.deliveryAddress.postalCode }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="goToOrders">View All Orders</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'OrderConfirmationPage',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const order = ref({});

    onMounted(async () => {
      const orderId = route.params.orderId;
      try {
        order.value = await store.dispatch('fetchOrder', orderId);
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    });

    const goToOrders = () => {
      router.push('/orders');
    };

    return {
      order,
      goToOrders,
    };
  },
};
</script>
