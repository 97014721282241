// ./frontend/src/components/SliderProducts.vue
<template>
  <div class="slider-products">
    <v-container class="pa-0">
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <!-- Header section -->
          <div class="d-flex align-center justify-space-between mb-1">
            <router-link 
              :to="`/subcategory/${subcategoryName.toLowerCase()}`"
              class="text-decoration-none text-black"
            >
              <h2 class="text-h5">{{ subcategoryName }}</h2>
            </router-link>
            <v-btn
              v-if="mode !== 'multi-row'"
              variant="text"
              size="small"
              :to="`/subcategory/${subcategoryName.toLowerCase()}`"
              class="text-body-2"
            >
              View All
              <v-icon end size="small">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          
          <!-- Content section with conditional classes and events -->
          <div 
            ref="scrollContainer" 
            :class="[
              mode === 'multi-row' ? 'multi-row-container' : 'scroll-container',
            ]"
            @mousedown="mode !== 'multi-row' && startDrag"
          >
            <!-- Multi-row mode using v-row -->
            <template v-if="mode === 'multi-row'">
              <v-row dense>
                <v-col
                  v-for="product in displayProducts" 
                  :key="product._id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  class="pa-1"
                >
                  <v-card
                    class="h-100"
                    elevation="0"
                  >
                    <ProductCard
                      :product="product"
                      @navigate-to-product="navigateToProduct"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <!-- Slider mode -->
            <template v-else>
              <div 
                ref="scrollContent"
                class="scroll-content"
                :style="{ transform: `translateX(${position}px)` }"
              >
                <div 
                  v-for="product in displayProducts" 
                  :key="product._id"
                  class="product-card-wrapper"
                >
                  <v-card
                    class="mx-2"
                    width="100%"
                    min-width="280"
                    elevation="0"
                  >
                    <ProductCard
                      :product="product"
                      @navigate-to-product="navigateToProduct"
                    />
                  </v-card>
                </div>
              </div>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import ProductCard from './ProductCard.vue'

export default {
  name: 'SliderProducts',
  
  components: {
    ProductCard
  },

  props: {
    subcategoryName: {
      type: String,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      default: 'slider',
      validator: (value) => ['slider', 'multi-row'].includes(value)
    }
  },

  setup(props) {
    const router = useRouter()
    
    // Refs for drag functionality
    const scrollContainer = ref(null)
    const scrollContent = ref(null)
    const position = ref(0)
    const isDragging = ref(false)
    const startPosition = ref(0)
    const dragStartX = ref(0)
    
    const displayProducts = computed(() => {
      return props.mode === 'multi-row' ? props.products : props.products.slice(0, 10)
    })

    const startDrag = (e) => {
      if (props.mode === 'multi-row') return
      
      isDragging.value = true
      dragStartX.value = e.clientX
      startPosition.value = position.value

      document.addEventListener('mousemove', onDrag)
      document.addEventListener('mouseup', stopDrag)
    }

    const onDrag = (e) => {
      if (!isDragging.value) return

      const deltaX = e.clientX - dragStartX.value
      const newPosition = startPosition.value + deltaX

      const containerWidth = scrollContainer.value.offsetWidth
      const contentWidth = scrollContent.value.offsetWidth
      const minPosition = -(contentWidth - containerWidth)

      position.value = Math.min(0, Math.max(minPosition, newPosition))
    }

    const stopDrag = () => {
      isDragging.value = false
      document.removeEventListener('mousemove', onDrag)
      document.removeEventListener('mouseup', stopDrag)
    }

    const navigateToProduct = (product) => {
      if (!isDragging.value) {
        router.push(`/product/${product._id}`)
      }
    }

    return {
      displayProducts,
      scrollContainer,
      scrollContent,
      position,
      startDrag,
      navigateToProduct
    }
  }
}
</script>

<style scoped>
.slider-products {
  margin: 0;
}

/* Slider mode styles */
.scroll-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: grab;
  touch-action: none;
  user-select: none;
}

.scroll-container:active {
  cursor: grabbing;
}

.scroll-content {
  display: flex;
  gap: 8px;
  will-change: transform;
  transition: transform 0.1s ease-out;
}

.scroll-content:active {
  transition: none;
}

.product-card-wrapper {
  flex: 0 0 auto;
  width: 280px;
}

/* Multi-row mode styles */
.multi-row-container {
  width: 100%;
  overflow: visible;
}

/* Card styles */
:deep(.v-card) {
  margin: 0 !important;
}

:deep(.v-card__text) {
  padding: 8px !important;
}

:deep(.v-img) {
  height: 200px !important;
  object-fit: contain !important;
}

@media (max-width: 600px) {
  :deep(.v-img) {
    height: 180px !important;
  }
}

.v-card {
  transition: none;
}

/* Product card image container height */
:deep(.product-image-container) {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive adjustments for product images */
@media (max-width: 960px) {
  :deep(.product-image-container) {
    height: 180px;
  }
}

@media (max-width: 600px) {
  :deep(.product-image-container) {
    height: 160px;
  }
}
</style>