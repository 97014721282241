<!-- ./frontend/src/components/AccountManagementDrawer.vue -->
<template>
  <v-navigation-drawer
    permanent
    class="pt-4"
  >
    <v-list>
      <v-list-subheader class="d-flex align-center justify-space-between">
        Account Management
      </v-list-subheader>
      <v-list-item to="/account" link>
        <template #prepend>
          <v-icon>mdi-account</v-icon>
        </template>
        <v-list-item-title>Account</v-list-item-title>
      </v-list-item>
      <v-list-item to="/orders" link>
        <template #prepend>
          <v-icon>mdi-package-variant</v-icon>
        </template>
        <v-list-item-title>Orders</v-list-item-title>
      </v-list-item>
      <v-list-item to="/routine" link>
        <template #prepend>
          <v-icon>mdi-refresh</v-icon>
        </template>
        <v-list-item-title>Routine</v-list-item-title>
      </v-list-item>
      <v-list-item to="/notifications" link>
        <template #prepend>
          <v-icon>mdi-bell-outline</v-icon>
        </template>
        <v-list-item-title>
          Notifications
          <v-badge
            v-if="unreadNotificationsCount > 0"
            :content="unreadNotificationsCount"
            color="error"
            inline
          ></v-badge>
        </v-list-item-title>
      </v-list-item>
      <v-list-item to="/coupons" link>
        <template #prepend>
          <v-icon>mdi-ticket-percent</v-icon>
        </template>
        <v-list-item-title>Referral coupons</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'AccountManagementDrawer',
  
  setup() {
    const store = useStore()
    const user = computed(() => store.state.user.user)
    const unreadNotificationsCount = computed(() => store.getters.unreadNotificationsCount)

    return {
      user,
      unreadNotificationsCount
    }
  }
}
</script>