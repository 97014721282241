// frontend/src/store/modules/delivery.js
import axios from '@/utils/axios'

const state = () => ({
  status: {
    canDeliverLocal: false,
    canDeliverCanadaWide: false,
    canPickup: false,
    nextDelivery: null,
    nextPickup: null,
    schedules: {
      delivery: [],
      pickup: []
    },
    nextOrderByDate: null,
    nextOrderByTime: null,
    nextLocalDeliveryDay: null,
    nextNationalDeliveryEarlyEstimateDay: null,
    nextNationalDeliveryLateEstimateDay: null
  },
  selectedOption: null,
  loading: false,
  error: null,
  cache: new Map()
})

const mutations = {
  SET_STATUS(state, status) {
    state.status = {
      ...state.status,
      ...status
    }
  },
  SET_SELECTED_OPTION(state, option) {
    state.selectedOption = option
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  UPDATE_CACHE(state, { key, data }) {
    state.cache.set(key, {
      status: data,
      timestamp: Date.now()
    })
  },
  CLEAR_CACHE(state) {
    state.cache.clear()
  }
}

const actions = {
  async getDeliveryStatus({ commit, state, rootGetters }, { productId }) {
    const userShippingZone = rootGetters['location/getLocalShippingZone']
    const cacheKey = `${productId}-${userShippingZone || 'none'}`
    
    const cached = state.cache.get(cacheKey)
    if (cached && Date.now() - cached.timestamp < 5 * 60 * 1000) {
      commit('SET_STATUS', cached.status)
      return cached.status
    }

    commit('SET_LOADING', true)
    commit('SET_ERROR', null)

    try {
      console.log('Fetching delivery status for:', productId)
      const response = await axios.get(`/api/delivery/products/${productId}/status`, {
        params: { userShippingZone }
      })
      
      console.log('Delivery status response:', response.data)

      // Extract the date from nextDelivery and subtract 2 days for order by date
      const nextDeliveryDate = new Date(response.data.nextDelivery)
      const orderByDate = new Date(nextDeliveryDate)
      orderByDate.setDate(orderByDate.getDate() - 2)

      // Calculate national delivery estimates
      const earlyEstimateDate = new Date(orderByDate)
      earlyEstimateDate.setDate(earlyEstimateDate.getDate() + 2)
      
      const lateEstimateDate = new Date(orderByDate)
      lateEstimateDate.setDate(lateEstimateDate.getDate() + 6)

      const deliveryStatus = {
        ...response.data,
        nextLocalDeliveryDay: response.data.nextDelivery,
        nextOrderByDate: orderByDate.toISOString(),
        nextOrderByTime: '08:00',
        nextNationalDeliveryEarlyEstimateDay: earlyEstimateDate.toISOString(),
        nextNationalDeliveryLateEstimateDay: lateEstimateDate.toISOString()
      }
      
      console.log('Processed delivery status:', deliveryStatus)

      commit('SET_STATUS', deliveryStatus)
      commit('UPDATE_CACHE', {
        key: cacheKey,
        data: deliveryStatus
      })

      return deliveryStatus
    } catch (error) {
      console.error('Failed to get delivery status:', error)
      commit('SET_ERROR', error.message)
      return null
    } finally {
      commit('SET_LOADING', false)
    }
  },

  selectDeliveryOption({ commit, state }, option) {
    if (option === 'LocalDelivery' && !state.status.canDeliverLocal) {
      commit('SET_ERROR', 'Local delivery is not available')
      return false
    }

    if (option === 'CanadaWideDelivery' && !state.status.canDeliverCanadaWide) {
      commit('SET_ERROR', 'Canada-wide delivery is not available')
      return false
    }

    if (option === 'Pickup' && !state.status.canPickup) {
      commit('SET_ERROR', 'Pickup is not available')
      return false
    }

    commit('SET_SELECTED_OPTION', option)
    return true
  },

  clearDeliveryStatus({ commit }) {
    commit('SET_STATUS', {
      canDeliverLocal: false,
      canDeliverCanadaWide: false,
      canPickup: false,
      nextDelivery: null,
      nextPickup: null,
      schedules: {
        delivery: [],
        pickup: []
      },
      nextOrderByDate: null,
      nextOrderByTime: null,
      nextLocalDeliveryDay: null,
      nextNationalDeliveryEarlyEstimateDay: null,
      nextNationalDeliveryLateEstimateDay: null
    })
    commit('SET_SELECTED_OPTION', null)
    commit('SET_ERROR', null)
  },

  clearCache({ commit }) {
    commit('CLEAR_CACHE')
  }
}

const getters = {
  canDeliverLocal: state => state.status.canDeliverLocal,
  canDeliverCanadaWide: state => state.status.canDeliverCanadaWide,
  canPickup: state => state.status.canPickup,
  nextDeliveryDate: state => state.status.nextDelivery,
  nextPickupDate: state => state.status.nextPickup,
  deliverySchedule: state => state.status.schedules.delivery,
  pickupSchedule: state => state.status.schedules.pickup,
  selectedOption: state => state.selectedOption,
  isLoading: state => state.loading,
  error: state => state.error,
  nextOrderByDate: state => state.status.nextOrderByDate,
  nextOrderByTime: state => state.status.nextOrderByTime,
  nextLocalDeliveryDay: state => state.status.nextLocalDeliveryDay,
  nextNationalDeliveryEarlyEstimateDay: state => state.status.nextNationalDeliveryEarlyEstimateDay,
  nextNationalDeliveryLateEstimateDay: state => state.status.nextNationalDeliveryLateEstimateDay
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}