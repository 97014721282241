// ./frontend/src/store/modules/cart.js
import axiosInstance from '../../utils/axios'

export default {
 namespaced: true,

 state: () => ({
   cart: [],
   userId: null,
   errors: null,
   isLoading: false
 }),

 getters: {
   cartItemCount: state => {
     return state.cart.reduce((total, item) => total + (item.quantity || 0), 0)
   },
   
   cartTotal: state => {
     return state.cart.reduce((total, item) => {
       const itemPrice = item.weightAdjusted 
         ? (item.pricePerMeasure * item.averageWeight) 
         : (item.price || 0)
       return total + (itemPrice * (item.quantity || 0))
     }, 0)
   },

   cartItems: state => state.cart,

   getCartItemById: state => id => {
     return state.cart.find(item => item._id === id)
   },

   getCartItemQuantity: state => id => {
     const item = state.cart.find(item => item._id === id)
     return item?.quantity || 0
   },

   isItemInCart: state => id => {
     return state.cart.some(item => item._id === id)
   }
 },

 mutations: {
   SET_USER_ID(state, id) {
     state.userId = id
   },

   SET_CART(state, items) {
     state.cart = items.map(item => ({
       _id: item._id,
       name: item.name,
       price: item.price,
       quantity: item.quantity || 1,
       weightAdjusted: item.weightAdjusted,
       averageWeight: item.averageWeight,
       pricePerMeasure: item.pricePerMeasure,
       deliveryOption: item.deliveryOption || 'Delivery',
       sellerId: item.seller?._id
     }))
     if(state.userId) {
       localStorage.setItem(`cart_${state.userId}`, JSON.stringify(state.cart))
     } else {
       localStorage.setItem('guest_cart', JSON.stringify(state.cart))
     }
   },

   ADD_TO_CART(state, { product, deliveryOption }) {
     const cartItem = state.cart.find(item => item._id === product._id)
     const simplifiedProduct = {
       _id: product._id,
       name: product.name,
       price: product.price,
       weightAdjusted: product.weightAdjusted,
       averageWeight: product.averageWeight,
       pricePerMeasure: product.pricePerMeasure,
       sellerId: product.seller?._id,
       deliveryOption: deliveryOption || 'Delivery',
       quantity: 1
     }
     
     if (cartItem) {
       cartItem.quantity++
     } else {
       state.cart.push(simplifiedProduct)
     }

     if(state.userId) {
       localStorage.setItem(`cart_${state.userId}`, JSON.stringify(state.cart))
     } else {
       localStorage.setItem('guest_cart', JSON.stringify(state.cart))
     }
   },

   REMOVE_FROM_CART(state, productId) {
     state.cart = state.cart.filter(item => item._id !== productId)
     if(state.userId) {
       localStorage.setItem(`cart_${state.userId}`, JSON.stringify(state.cart))
     } else {
       localStorage.setItem('guest_cart', JSON.stringify(state.cart))
     }
   },

   UPDATE_CART_ITEM_QUANTITY(state, { productId, quantity }) {
     const item = state.cart.find(item => item._id === productId)
     if (item) {
       item.quantity = Math.max(0, Math.min(quantity, 99))
       if(state.userId) {
         localStorage.setItem(`cart_${state.userId}`, JSON.stringify(state.cart))
       } else {
         localStorage.setItem('guest_cart', JSON.stringify(state.cart))
       }
     }
   },

   CLEAR_CART(state) {
     state.cart = []
     if(state.userId) {
       localStorage.removeItem(`cart_${state.userId}`)
     } else {
       localStorage.removeItem('guest_cart')
     }
   },

   UPDATE_CART_ITEM_DELIVERY_OPTION(state, { productId, deliveryOption }) {
     const item = state.cart.find(item => item._id === productId)
     if (item) {
       item.deliveryOption = deliveryOption
       if(state.userId) {
         localStorage.setItem(`cart_${state.userId}`, JSON.stringify(state.cart))
       } else {
         localStorage.setItem('guest_cart', JSON.stringify(state.cart))
       }
     }
   },

   SET_LOADING(state, isLoading) {
     state.isLoading = isLoading
   },

   SET_ERRORS(state, errors) {
     state.errors = errors
   }
 },

 actions: {
   initializeCart({ commit, rootState }) {
     const userId = rootState.auth?.user?._id
     commit('SET_USER_ID', userId)
     
     try {
       let savedCart = null
       if (userId) {
         savedCart = localStorage.getItem(`cart_${userId}`)
       } else {
         savedCart = localStorage.getItem('guest_cart')
       }
       
       if (savedCart) {
         commit('SET_CART', JSON.parse(savedCart))
       }
     } catch (error) {
       console.error('Error loading cart:', error)
       commit('CLEAR_CART')
     }
   },

   async addToCart({ commit, dispatch }, { product, quantity = 1 }) {
     commit('SET_LOADING', true)
     commit('SET_ERRORS', null)
     
     try {
       const response = await axiosInstance.get(`/api/products/${product._id}/delivery-options`)
       const { deliveryOptions, defaultOption } = response.data
       
       for (let i = 0; i < quantity; i++) {
         commit('ADD_TO_CART', { 
           product,
           deliveryOption: defaultOption || 'Delivery'
         })
       }
     } catch (error) {
       console.error('Error adding to cart:', error)
       commit('SET_ERRORS', error.response?.data?.message || 'Error adding item to cart')
     } finally {
       commit('SET_LOADING', false)
     }
   },

   removeFromCart({ commit }, productId) {
     commit('REMOVE_FROM_CART', productId)
   },

   updateCartItemQuantity({ commit }, { productId, quantity }) {
     commit('UPDATE_CART_ITEM_QUANTITY', { productId, quantity })
   },

   clearCart({ commit }) {
     commit('CLEAR_CART')
   },

   updateCartItemDeliveryOption({ commit }, { productId, deliveryOption }) {
     commit('UPDATE_CART_ITEM_DELIVERY_OPTION', { productId, deliveryOption })
   }
 }
}