# frontend/src/views/BrowsePage.vue
<template>
 <div class="browse-container">
   <v-container class="pa-0">
     <v-row>
       <template v-for="category in categories" :key="category.name">
         <v-col cols="6" sm="4" md="3" lg="2">
           <router-link :to="category.route" class="text-decoration-none">
             <div class="category-tile">
               <v-img
                 :src="category.image"
                 :alt="category.name"
                 height="150"
                 cover
                 class="rounded-lg"
               >
                 <div class="fill-height category-overlay d-flex align-center justify-center">
                   <p class="text-h5 text-white font-weight-bold text-break text-center px-2">
                     {{ category.name }}
                   </p>
                 </div>
               </v-img>
             </div>
           </router-link>
         </v-col>
       </template>
     </v-row>
   </v-container>
 </div>
</template>

<script>
import { defineComponent } from 'vue'
import pantryIcon from '@/assets/category-icons/pantry.png'
import spicesIcon from '@/assets/category-icons/herbs-spices.png'
import snacksIcon from '@/assets/category-icons/snacks.png'
import beveragesIcon from '@/assets/category-icons/beverages.png'
import produceIcon from '@/assets/category-icons/produce.png'
import healthIcon from '@/assets/category-icons/health.png'
import dairyIcon from '@/assets/category-icons/dairy.png'
import meatIcon from '@/assets/category-icons/meat.png'
import beautyIcon from '@/assets/category-icons/beauty.png'
import householdIcon from '@/assets/category-icons/household.png'
import alcoholIcon from '@/assets/category-icons/alcohol.png'
import internationalIcon from '@/assets/category-icons/international.png'
import frozenIcon from '@/assets/category-icons/frozen.png'
import breadsIcon from '@/assets/category-icons/breads.png'
import homecareIcon from '@/assets/category-icons/homecare.png'
import petIcon from '@/assets/category-icons/pet.png'
import babyIcon from '@/assets/category-icons/baby.png'
import otherIcon from '@/assets/category-icons/other.png'

export default defineComponent({
 name: 'BrowsePage',
 
 setup() {
   const categories = [
     { name: 'Fruit & Vegetables', image: produceIcon, route: '/category/fruit-vegetables' },
     { name: 'Dairy & Eggs', image: dairyIcon, route: '/category/dairy-eggs' },
     { name: 'Meat & Seafood', image: meatIcon, route: '/category/meat-fish' },
     { name: 'Herbs & Spices', image: spicesIcon, route: '/category/herbs-spices' },
     { name: 'Pantry', image: pantryIcon, route: '/category/pantry' },
     { name: 'Beverages', image: beveragesIcon, route: '/category/beverages' },
     { name: 'Snacks', image: snacksIcon, route: '/category/snacks' },
     { name: 'Bakery', image: breadsIcon, route: '/category/bakery' },
     { name: 'Alcohol', image: alcoholIcon, route: '/category/alcohol' },
     { name: 'International & Specialty Foods', image: internationalIcon, route: '/category/international-specialty-foods' },
     { name: 'Health & Wellness', image: healthIcon, route: '/category/health-wellness' },
     { name: 'Personal Care & Beauty', image: beautyIcon, route: '/category/personal-care-beauty' },
     { name: 'Baby & Kids', image: babyIcon, route: '/category/baby-kids' },
     { name: 'Pet Care', image: petIcon, route: '/category/pet-care' },
     { name: 'Home Care', image: homecareIcon, route: '/category/home-care' },
     { name: 'Household Essentials', image: householdIcon, route: '/category/household-essentials' }
   ]

   return {
     categories
   }
 }
})
</script>

<style scoped>
.browse-container {
 max-width: 1400px;
 margin: 0 auto;
 padding: 16px;
}

.category-tile {
 cursor: pointer;
 transition: transform 0.2s;
}

.category-tile:hover {
 transform: translateY(-2px);
}

.category-overlay {
 background: rgba(0, 0, 0, 0.4);
}

.text-break {
 word-break: break-word;
 hyphens: auto;
 max-width: 100%;
}
</style>