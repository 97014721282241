<!-- ./frontend/src/components/textField/LocationSearch.vue -->
<template>
  <div>
    <v-text-field
      class="location-search mb-4"
      v-model="localSearchValue"
      :label="label"
      variant="outlined"
      density="comfortable"
      :loading="isLoading"
      @update:model-value="handleInput"
      :rules="rules"
      :error-messages="errorMessage"
      :hint="hint"
      persistent-hint
    ></v-text-field>
    
    <div v-if="suggestions.length" class="suggestion-container">
      <v-list density="compact" class="suggestion-list">
        <v-list-item
          v-for="municipality in suggestions"
          :key="municipality._id"
          @click="selectSuggestion(municipality)"
          link
        >
          <v-list-item-title>
            {{ municipality.name }}, {{ municipality.province }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { ref, defineProps, defineEmits, watch } from 'vue'
import axios from 'axios'
import { debounce } from 'lodash'

export default {
  name: 'LocationSearch',

  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Enter a Canada city or postal code'
    },
    hint: {
      type: String,
      default: 'Enter city name or postal code'
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  emits: ['update:modelValue', 'select-municipality'],

  setup(props, { emit }) {
    const localSearchValue = ref(props.modelValue)
    const suggestions = ref([])
    const isLoading = ref(false)

    const rules = [
      v => !!v || 'Location is required',
      v => /^[A-Za-z0-9\s\-',]*$/.test(v) || 'Only letters, numbers, spaces, commas, hyphens and apostrophes allowed',
      v => v.length <= 50 || 'Maximum 50 characters allowed'
    ]

    watch(() => props.modelValue, (newValue) => {
      localSearchValue.value = newValue
    })

    const searchMunicipalities = debounce(async (query) => {
      if (query.length >= 3) {
        isLoading.value = true
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/municipalities/search`, {
            params: { query }
          })
          suggestions.value = response.data
        } catch (error) {
          console.error('Error fetching municipality suggestions:', error)
          suggestions.value = []
        }
        isLoading.value = false
      } else {
        suggestions.value = []
      }
    }, 300)

    const handleInput = (value) => {
      if (typeof value === 'string') {
        localSearchValue.value = value
        emit('update:modelValue', value)
        searchMunicipalities(value)
      }
    }

    const selectSuggestion = (municipality) => {
      const municipalityString = `${municipality.name}, ${municipality.province}`
      localSearchValue.value = municipalityString
      emit('update:modelValue', municipalityString)
      emit('select-municipality', municipality)
      suggestions.value = []
    }

    return {
      localSearchValue,
      suggestions,
      isLoading,
      rules,
      handleInput,
      selectSuggestion
    }
  }
}
</script>

<style scoped>
.location-search {
  position: relative;
}

.suggestion-container {
  position: relative;
  margin-top: -16px;
  z-index: 100;
}

.suggestion-list {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.suggestion-list .v-list-item {
  cursor: pointer;
}

.suggestion-list .v-list-item:hover {
  background-color: #f5f5f5;
}
</style>