# ./frontend/src/components/SearchPreview.vue
<template>
  <v-card class="search-preview" color="rgb(21, 46, 25)">
    <v-container class="pa-0" fluid>
      <v-row no-gutters>
        <!-- Left Column: Searches and Categories -->
        <v-col cols="12" lg="4" xl="3" class="pr-lg-4">
          <!-- Searches Section -->
          <div class="section">
            <v-card-title class="text-subtitle-1 font-weight-bold text-white px-4 pt-4 pb-2">
              TRENDING SEARCHES
            </v-card-title>
            <v-list v-if="!loading" lines="one" class="transparent px-2">
              <v-list-item
                v-for="search in visibleSearches"
                :key="search.rank"
                :to="`/search?q=${encodeURIComponent(search.query)}`"
                class="search-item py-1"
                color="transparent"
              >
                <template #prepend>
                  <v-icon color="white" size="small" class="mr-2">mdi-magnify</v-icon>
                </template>
                <v-list-item-title class="text-white text-body-1">
                  {{ search.query }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>

          <!-- Categories Section -->
          <div class="section mt-4">
            <v-card-title class="text-subtitle-1 font-weight-bold text-white px-4 pb-2">
              TRENDING CATEGORIES
            </v-card-title>
            <v-list v-if="!loading" lines="one" class="transparent px-2">
              <v-list-item
                v-for="category in visibleCategories"
                :key="category.rank"
                :to="`/category/${category.item.url}`"
                class="search-item py-1"
                color="transparent"
              >
                <template #prepend>
                  <v-icon color="white" size="small" class="mr-2">mdi-fire</v-icon>
                </template>
                <v-list-item-title class="text-white text-body-1">
                  {{ category.item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-col>

        <!-- Right Column: Products -->
        <v-col cols="12" lg="8" xl="9" class="pl-lg-4">
          <!-- Products Section -->
          <div class="section">
            <v-card-title class="text-subtitle-1 font-weight-bold text-white px-4 pt-4 pb-4">
              POPULAR ITEMS
            </v-card-title>
            <v-row v-if="!loading" class="px-2" dense>
              <v-col v-for="product in visibleProducts" 
                    :key="product.rank" 
                    cols="12" 
                    sm="6"
                    md="4"
                    lg="4"
                    xl="3"
                    class="pb-4">
                <router-link 
                  :to="`/${product.item.category}/${product.item._id}`"
                  class="text-decoration-none"
                >
                  <v-card class="product-card h-100" elevation="0">
                    <div class="product-image-container">
                      <v-img
                        :src="product.item.productImage || '/placeholder.jpg'"
                        :alt="product.item.name"
                        aspect-ratio="1"
                        cover
                        class="rounded-lg"
                      ></v-img>
                    </div>
                    <div class="pt-2 px-1">
                      <div class="text-caption text-white font-weight-medium text-uppercase">
                        {{ product.item.seller?.name || 'LOQUO' }}
                      </div>
                      <div class="text-body-2 text-white">
                        {{ product.item.name }}
                      </div>
                    </div>
                  </v-card>
                </router-link>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading State -->
    <v-overlay
      :model-value="loading"
      class="align-center justify-center"
      contained
      persistent
    >
      <v-progress-circular
        color="white"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <!-- Error Alert -->
    <v-alert
      v-if="error"
      type="error"
      class="ma-4"
      closable
      @click:close="error = null"
    >
      {{ error }}
    </v-alert>
  </v-card>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'SearchPreview',
  
  setup() {
    const store = useStore();
    const error = ref(null);

    const loading = computed(() => store.getters['trend/isLoading']);
    
    const visibleProducts = computed(() => {
      const products = store.getters['trend/trendingProducts'];
      console.log('=== Visible Products ===', {
        count: products.length,
        products: products.map(p => ({
          rank: p.rank,
          id: p.item._id,
          name: p.item.name,
          category: p.item.category,
          hasImage: !!p.item.productImage,
          seller: p.item.seller,
          isVisible: p.isVisible,
          views: {
            total: p.totalViews,
            unique: p.uniqueViews
          }
        }))
      });
      return products;
    });
    
    const visibleCategories = computed(() => {
      const categories = store.getters['trend/trendingCategories'];
      console.log('=== Visible Categories ===', {
        count: categories.length,
        categories: categories.map(c => ({
          rank: c.rank,
          id: c.item._id,
          name: c.item.name,
          url: c.item.url,
          description: c.item.description,
          isVisible: c.isVisible,
          views: {
            total: c.totalViews,
            unique: c.uniqueViews
          }
        }))
      });
      return categories;
    });
    
    const visibleSearches = computed(() => {
      const searches = store.getters['trend/trendingSearches'];
      console.log('=== Visible Searches ===', {
        count: searches.length,
        searches: searches.map(s => ({
          rank: s.rank,
          query: s.query,
          isVisible: s.isVisible,
          views: {
            total: s.totalViews,
            unique: s.uniqueViews
          }
        }))
      });
      return searches;
    });

    const fetchVisibleTrends = async () => {
      console.log('=== Fetching Visible Trends ===');
      const startTime = Date.now();
      
      try {
        error.value = null;
        const result = await store.dispatch('trend/getVisibleTrends');
        
        console.log('Fetch Result:', {
          success: true,
          timeTaken: `${Date.now() - startTime}ms`,
          data: result
        });
        
        return result;
      } catch (err) {
        console.error('Error fetching visible trends:', {
          error: err.message,
          stack: err.stack,
          timeTaken: `${Date.now() - startTime}ms`
        });
        error.value = 'Unable to load trending items. Please try again later.';
        return null;
      }
    };

    onMounted(() => {
      console.log('SearchPreview component mounted');
      fetchVisibleTrends();
    });

    // Add watcher for debugging updates
    watch([visibleProducts, visibleCategories, visibleSearches], ([newProducts, newCategories, newSearches]) => {
      console.log('=== Trends Updated ===', {
        timestamp: new Date().toISOString(),
        productCount: newProducts.length,
        categoryCount: newCategories.length,
        searchCount: newSearches.length
      });
    });

    return {
      visibleProducts,
      visibleCategories,
      visibleSearches,
      loading,
      error
    };
  }
};
</script>

<style scoped>
.search-preview {
  position: relative;
  min-height: 200px;
  border-radius: 8px;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
}

.transparent {
  background-color: transparent !important;
}

.section {
  padding-bottom: 8px;
}

.search-item {
  min-height: 40px !important;
  transition: background-color 0.2s ease;
}

.search-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  cursor: pointer;
}

.v-list-item__prepend {
  opacity: 0.7;
}

.v-list-item__append {
  opacity: 0.7;
}

.product-card {
  background-color: transparent !important;
  transition: transform 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.product-image-container {
  flex: 1;
  padding: 2px;
}

.product-card:hover {
  transform: translateY(-4px);
}

.v-overlay {
  border-radius: 8px;
}

.error-message {
  color: #ff5252;
  margin: 16px;
  text-align: center;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1785px !important;
  }
}
</style>