// frontend/src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import vue3GoogleLogin from 'vue3-google-login'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { initFacebookSdk } from './utils/facebook-sdk'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#10341A',
          secondary: '#C2BAAC',
          charcoal: '#2E2929',
        },
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})

async function startApp() {
  await initFacebookSdk();

  const app = createApp(App)

  // Provide fetchUnreadNotificationsCount to all components
  app.provide('fetchUnreadNotificationsCount', async () => {
    try {
      return await store.dispatch('fetchUnreadNotificationsCount')
    } catch (error) {
      console.error('Error fetching unread notifications count:', error)
      return 0
    }
  })

  app.use(router)
  app.use(store)
  app.use(vuetify)
  app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
  })
  app.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: ['places']
    },
  })

  // Initialize authentication
  store.dispatch('user/initializeAuth');

  // Initialize cart
  store.dispatch('cart/initializeCart')

  app.mount('#app')
}

startApp();