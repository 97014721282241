<!-- frontend/src/views/AdminTrendPage.vue -->
<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <span>Trend Management</span>
        <div class="d-flex align-center gap-4">
          <span class="text-caption text-medium-emphasis">
            Last updated: {{ formattedLastUpdate }}
          </span>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            prepend-icon="mdi-refresh"
            @click="generateWeeklyTrends"
          >
            Generate Trends
          </v-btn>
        </div>
      </v-card-title>
      
      <v-card-text>
        <v-tabs v-model="activeTab" color="primary" class="mb-4">
          <v-tab value="products">Products</v-tab>
          <v-tab value="categories">Categories</v-tab>
          <v-tab value="searches">Searches</v-tab>
        </v-tabs>

        <v-window v-model="activeTab">
          <v-window-item 
            v-for="type in ['products', 'categories', 'searches']" 
            :key="type" 
            :value="type"
          >
            <v-data-table
              :headers="getHeadersForType(type)"
              :items="getTrendItems(type)"
              :loading="loading"
              class="trend-table"
            >
              <template #[`item.rank`]="{ item }">
                <span class="font-weight-medium">#{{ item.rank }}</span>
              </template>

              <template #[`item.isVisible`]="{ item }">
                <v-switch
                  v-model="item.isVisible"
                  :loading="updatingVisibility"
                  :disabled="updatingVisibility"
                  color="primary"
                  hide-details
                  density="compact"
                  @update:model-value="updateVisibility(type, item)"
                />
              </template>

              <template #[`item.name`]="{ item }">
                <div class="d-flex align-center">
                  <template v-if="type === 'products'">
                    <v-avatar size="32" rounded class="me-2">
                      <v-img
                        :src="item.item.productImage || '/placeholder.jpg'"
                        :alt="item.item.name"
                        cover
                      />
                    </v-avatar>
                    <div>
                      <div class="font-weight-medium">{{ item.item.name }}</div>
                      <div class="text-caption text-medium-emphasis">
                        {{ item.item.category }}
                      </div>
                      <div class="text-caption text-medium-emphasis">
                        By {{ item.item.seller?.name || 'Unknown Seller' }}
                      </div>
                    </div>
                  </template>
                  <template v-else-if="type === 'categories'">
                    <div>
                      <div class="font-weight-medium">{{ item.item.name }}</div>
                      <div class="text-caption text-medium-emphasis">
                        {{ item.item.description }}
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="font-weight-medium">{{ item.query }}</div>
                  </template>
                </div>
              </template>

              <template #[`item.views`]="{ item }">
                <div>
                  <div class="font-weight-medium">
                    {{ formatNumber(item.totalViews) }} total
                  </div>
                  <div class="text-caption text-medium-emphasis">
                    {{ formatNumber(item.uniqueViews) }} unique
                  </div>
                </div>
              </template>

              <!-- No data placeholder -->
              <template #no-data>
                <div class="d-flex flex-column align-center py-8">
                  <v-icon 
                    icon="mdi-alert-circle-outline" 
                    size="large" 
                    class="mb-2"
                  />
                  <div class="text-h6">No Trend Data Available</div>
                  <div class="text-body-2 text-medium-emphasis">
                    Click "Generate Trends" to create new trend data
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

    <!-- Success Snackbar -->
    <v-snackbar
      v-model="showSuccess"
      color="success"
      :timeout="3000"
      location="top"
    >
      {{ successMessage }}
      <template #actions>
        <v-btn
          color="white"
          variant="text"
          @click="showSuccess = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Error Snackbar -->
    <v-snackbar
      v-model="showError"
      color="error"
      :timeout="5000"
      location="top"
    >
      {{ error }}
      <template #actions>
        <v-btn
          color="white"
          variant="text"
          @click="showError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';

// Store and router
const store = useStore();
const router = useRouter();

// Component state
const activeTab = ref('products');
const showSuccess = ref(false);
const showError = ref(false);
const successMessage = ref('');
const updatingVisibility = ref(false);

// Computed properties
const loading = computed(() => store.getters['trend/isLoading']);
const error = computed(() => store.getters['trend/error']);

const formattedLastUpdate = computed(() => {
  const lastUpdate = store.state.trend.lastUpdateAttempt;
  return lastUpdate ? format(new Date(lastUpdate), 'PPpp') : 'Never';
});

// Methods
const getHeadersForType = (type) => ([
  {
    title: 'Rank',
    key: 'rank',
    align: 'center',
    sortable: true,
    width: '80'
  },
  {
    title: 'Visible',
    key: 'isVisible',
    align: 'center',
    sortable: true,
    width: '100'
  },
  { 
    title: type === 'searches' ? 'Search Query' : 'Name',
    key: 'name',
    align: 'start',
    sortable: true,
    width: type === 'products' ? '400' : '300'
  },
  {
    title: 'Views',
    key: 'views',
    align: 'start',
    sortable: true,
    width: '150'
  }
]);

const formatNumber = (num) => {
  if (!num) return '0';
  if (num >= 1000000) return `${(num / 1000000).toFixed(1)}M`;
  if (num >= 1000) return `${(num / 1000).toFixed(1)}K`;
  return num.toString();
};

const getTrendItems = (type) => {
  const items = store.getters[`trend/trending${type.charAt(0).toUpperCase() + type.slice(1)}`];
  return items.map(item => ({
    ...item,
    name: type === 'searches' ? item.query : item.item.name
  }));
};

const loadTrendData = async () => {
  try {
    await store.dispatch('trend/getVisibleTrends');
  } catch (err) {
    console.error('Error loading trend data:', err);
    showError.value = true;
  }
};

const generateWeeklyTrends = async () => {
  try {
    await store.dispatch('trend/generateWeeklyTrends');
    await loadTrendData();
    successMessage.value = 'Weekly trends generated successfully';
    showSuccess.value = true;
  } catch (err) {
    console.error('Error generating trends:', err);
    showError.value = true;
  }
};

const updateVisibility = async (type, item) => {
  if (updatingVisibility.value) return;

  updatingVisibility.value = true;
  try {
    await store.dispatch('trend/updateItemVisibility', {
      type,
      rank: item.rank,
      isVisible: item.isVisible
    });
    await loadTrendData();
    successMessage.value = 'Visibility updated successfully';
    showSuccess.value = true;
  } catch (err) {
    console.error('Error updating visibility:', err);
    item.isVisible = !item.isVisible; // Revert change on error
    showError.value = true;
  } finally {
    updatingVisibility.value = false;
  }
};

// Watch for store errors
watch(() => store.state.trend.error, (newError) => {
  if (newError) {
    showError.value = true;
  }
});

// Load initial data
onMounted(async () => {
  await loadTrendData();
});
</script>

<style scoped>
.trend-table {
  max-height: calc(100vh - 300px);
}

.gap-4 {
  gap: 1rem;
}

:deep(.v-data-table) {
  background: transparent !important;
}

:deep(.v-data-table-header) {
  background: #f5f5f5;
}

:deep(.v-data-table-row:hover) {
  background-color: #f5f5f5 !important;
}

:deep(.v-switch__track) {
  opacity: 0.4;
}

:deep(.v-switch--inset .v-switch__track) {
  opacity: 0.4;
}

.v-card-text {
  padding-top: 0;
}
</style>