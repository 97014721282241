<template>
  <div>
    <!-- Main location button -->
    <v-btn
      class="mr-2 location-btn d-none d-sm-flex"
      style="background-color: black !important"
      @click="openLocationDialog"
    >
      <v-tooltip
        location="bottom"
        text="Set your location"
      >
        <template #activator="{ props }">
          <div v-bind="props" class="d-flex align-center">
            <v-icon left color="white">mdi-map-marker</v-icon>
            <span class="text-white">{{ currentLocation }}</span>
          </div>
        </template>
      </v-tooltip>
    </v-btn>
    
    <!-- Mobile location button -->
    <v-btn
      class="ml-2 location-btn d-sm-none"
      style="background-color: black !important"
      density="comfortable"
      @click="openLocationDialog"
    >
      <v-icon color="white">mdi-map-marker</v-icon>
      <span class="text-truncate text-white" style="max-width: 80px">{{ currentLocation }}</span>
    </v-btn>

    <!-- Location Dialog -->
    <v-dialog v-model="locationDialog" max-width="400">
      <v-card>
        <div class="dialog-header">
          <v-card-title class="text-h4 font-weight-regular">Choose your location</v-card-title>
          <v-btn icon class="close-button" @click="closeLocationDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-text class="mt-4">
          <p class="text-body-1 mb-8">Delivery options and delivery speeds may vary for different locations</p>
          
          <template v-if="isLoggedIn">
            <v-list v-if="userAddresses.length">
              <v-list-item
                v-for="address in userAddresses"
                :key="address._id"
                @click="selectAddress(address)"
                class="mb-2"
              >
                <v-list-item-title>
                  {{ address.street ? `${address.street}, ${address.city}, ${address.province} ${address.postalCode}` : address.postalCode }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="address.isPreferred">Default address</v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-btn
              block
              color="success-darken-3"
              height="56"
              rounded="lg"
              class="mt-4 mb-2 custom-button"
              @click="manageAddresses"
            >
              MANAGE ADDRESSES
            </v-btn>
            <div class="d-flex align-center my-4">
              <v-divider class="me-3"></v-divider>
              <span class="text-medium-emphasis text-body-1">OR</span>
              <v-divider class="ms-3"></v-divider>
            </div>
          </template>
          <template v-else>
            <div class="d-flex flex-column">
              <AccountMenu 
                mode="text"
                button-text="SIGN IN TO SEE YOUR ADDRESSES"
                text-size="small"
                text-color="white"
                :background-color="'rgb(25, 55, 25)'"
                class="sign-in-button mb-2"
              />
              <div class="d-flex align-center my-4">
                <v-divider class="me-3"></v-divider>
                <span class="text-medium-emphasis text-body-1">OR</span>
                <v-divider class="ms-3"></v-divider>
              </div>
            </div>
          </template>

          <LocationSearch
            v-model="searchLocation"
            :error-message="errorMessage"
            @select-municipality="selectMunicipality"
          />

          <v-btn
            block
            color="success-darken-3"
            height="56"
            rounded="lg"
            class="mt-8 custom-button"
            @click="applyLocation"
            :disabled="!searchLocation"
          >
            APPLY
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AccountMenu from '@/components/AccountMenu.vue'
import LocationSearch from '@/components/textField/LocationSearch.vue'
import axios from 'axios'

export default {
  name: 'SetLocation',

  components: {
    AccountMenu,
    LocationSearch
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const DEFAULT_POSTAL_CODE = 'K1N8S7'
    const searchLocation = ref('')
    const errorMessage = ref('')
    const localCurrentLocation = ref(DEFAULT_POSTAL_CODE)

    const locationDialog = computed({
      get: () => store.getters['location/showLocationDialog'],
      set: (value) => store.commit('location/SET_LOCATION_DIALOG', value)
    })

    const isLoggedIn = computed(() => store.getters['user/isLoggedIn'])
    const userAddresses = computed(() => store.getters['address/getSortedAddresses'])

    // Watch for authentication state changes
    watch(isLoggedIn, (newValue) => {
      if (newValue && locationDialog.value) {
        store.dispatch('address/fetchAddresses')
      }
    })

    // Watch for location changes in the store
    watch(() => store.getters['location/getCurrentLocation'], (newLocation) => {
      if (newLocation) {
        localCurrentLocation.value = newLocation
      } else {
        localCurrentLocation.value = DEFAULT_POSTAL_CODE
      }
    }, { immediate: true })

    const isPostalCode = (value) => {
      const postalCodeRegex = /^[A-Z]\d[A-Z]\d[A-Z]\d$/
      return postalCodeRegex.test(value)
    }

    const geocodeLocation = async (address) => {
      try {
        const encodedAddress = encodeURIComponent(`${address}, Canada`)
        const baseUrl = process.env.NODE_ENV === 'development' 
          ? `${process.env.VUE_APP_API_URL}/api/proxy/geocode`
          : 'https://maps.googleapis.com/maps/api/geocode/json'

        const response = await axios.get(baseUrl, {
          params: {
            address: encodedAddress,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
          }
        })

        if (response.data.results && response.data.results[0]) {
          return response.data.results[0].geometry.location
        }
        return null
      } catch (error) {
        try {
          const fallbackResponse = await axios.post(`${process.env.VUE_APP_API_URL}/api/geocode`, {
            address: `${address}, Canada`
          })
          if (fallbackResponse.data.location) {
            return fallbackResponse.data.location
          }
        } catch (fallbackError) {
          console.error('Server-side geocoding failed:', fallbackError)
        }
        return null
      }
    }

    const selectMunicipality = (municipality) => {
      const municipalityString = `${municipality.name}, ${municipality.province}`
      searchLocation.value = municipalityString
    }

    const openLocationDialog = () => {
      store.dispatch('location/openLocationDialog')
      if (isLoggedIn.value) {
        store.dispatch('address/fetchAddresses')
      }
    }

    const closeLocationDialog = () => {
      store.dispatch('location/closeLocationDialog')
    }

    const applyLocation = async () => {
      if (!searchLocation.value) {
        return
      }

      errorMessage.value = ''
      
      const parts = searchLocation.value.split(',')
      if (parts.length === 2) {
        const name = parts[0].trim()
        const province = parts[1].trim()

        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/municipalities/find`, {
            params: { 
              name: name,
              province: province
            }
          })
          
          if (response.data && response.data.geoLocation) {
            await store.dispatch('location/setLocation', {
              location: searchLocation.value,
              lat: response.data.geoLocation.lat,
              lng: response.data.geoLocation.lng
            })
            closeLocationDialog()
            searchLocation.value = ''
            return
          }
        } catch (error) {
          console.error('Error fetching municipality from database:', error)
          errorMessage.value = 'Unable to verify this municipality. Please try a different location or enter a postal code.'
          return
        }
      }

      const formattedValue = searchLocation.value.toUpperCase().replace(/\s+/g, '')

      if (isPostalCode(formattedValue)) {
        try {
          const coordinates = await geocodeLocation(formattedValue)
          
          if (coordinates) {
            await store.dispatch('location/setLocation', {
              location: formattedValue,
              lat: coordinates.lat,
              lng: coordinates.lng
            })
            closeLocationDialog()
            searchLocation.value = ''
          } else {
            errorMessage.value = 'Unable to verify this postal code. Please try again or enter a different location.'
          }
        } catch (error) {
          console.error('Error geocoding postal code:', error)
          errorMessage.value = 'Unable to verify this postal code. Please try again or enter a different location.'
        }
      } else {
        errorMessage.value = 'Please enter a valid Canadian postal code (e.g., A1A1A1) or select a municipality from the suggestions.'
      }
    }

    const manageAddresses = () => {
      router.push('/account#addresses')
      closeLocationDialog()
    }

    const selectAddress = async (address) => {
      try {
        await store.dispatch('address/setPreferredAddress', address._id)
        
        if (address.geoLocation) {
          await store.dispatch('location/setLocation', {
            location: address.postalCode,
            lat: address.geoLocation.lat,
            lng: address.geoLocation.lng
          })
        }

        closeLocationDialog()
      } catch (error) {
        console.error('Error selecting address:', error)
      }
    }

    return {
      locationDialog,
      currentLocation: localCurrentLocation,
      searchLocation,
      userAddresses,
      errorMessage,
      isLoggedIn,
      selectMunicipality,
      applyLocation,
      openLocationDialog,
      closeLocationDialog,
      selectAddress,
      manageAddresses,
    }
  }
}
</script>

<style scoped>
.location-btn {
  text-transform: none;
  border: none !important;
  box-shadow: none !important;
  background-color: black !important;
  color: white !important;
}

.dialog-header {
  position: relative;
  padding: 24px 24px 0 24px;
}

.close-button {
  position: absolute !important;
  right: 16px;
  top: 16px;
}

.w-100 {
  width: 100%;
}

.sign-in-button :deep(.v-btn) {
  width: 100%;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
  height: 56px !important;
  border-radius: 8px;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 56px !important;
}

.custom-button {
  background-color: rgb(25, 55, 25) !important;
  color: white !important;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}

:deep(.v-card-title) {
  font-size: 2rem !important;
  line-height: 2.5rem !important;
  padding: 0;
}

:deep(.v-card-text) {
  padding-top: 0 !important;
}

:deep(.v-divider) {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.text-medium-emphasis {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
}
</style>