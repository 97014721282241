<!-- ./frontend/src/components/CategoryHeader.vue -->
<template>
  <v-row class="mb-2">
    <v-col cols="12" class="pa-1">
      <div class="d-flex flex-column flex-sm-row">
        <!-- Category Name -->
        <div class="category-title pa-1">
          <v-chip
            size="x-large"
            variant="text"
            rounded="0"
            class="text-uppercase font-weight-medium text-left pl-0 mr-4"
            :class="{
              'text-h4': $vuetify.display.smAndUp,
              'text-h5': $vuetify.display.xs
            }"
          >
            {{ categoryName }}
          </v-chip>
        </div>
        
        <!-- Subcategories -->
        <div v-if="subcategories.length > 0" 
          class="subcategories-container d-flex flex-wrap align-center"
        >
          <v-chip
            v-for="subcategory in subcategories"
            :key="subcategory._id"
            variant="text"
            :size="$vuetify.display.smAndUp ? 'large' : 'default'"
            :class="[
              'ma-1 pa-1 text-uppercase no-transition',
              {
                'text-primary font-weight-bold': subcategory.name === activeSubcategory,
                'text-grey': subcategory.name !== activeSubcategory,
                'text-body-2': $vuetify.display.xs,
                'text-body-1': $vuetify.display.smAndUp
              }
            ]"
            style="transition: none !important;"
            @click="navigateToSubcategory(subcategory.url)"
          >
            {{ subcategory.name }}
          </v-chip>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useRouter, useDisplay } from 'vue-router';

export default defineComponent({
  name: 'CategoryHeader',

  props: {
    categoryName: {
      type: String,
      required: true
    },
    subcategories: {
      type: Array,
      required: true,
      default: () => []
    },
    activeSubcategory: {
      type: String,
      default: null
    }
  },

  setup() {
    const router = useRouter();

    const navigateToSubcategory = (subcategoryUrl) => {
      router.push(`/subcategory/${subcategoryUrl}`);
    };

    return {
      navigateToSubcategory
    };
  }
});
</script>

<style scoped>
.category-title {
  min-width: fit-content;
}

.subcategories-container {
  gap: 4px;
}

:deep(.v-chip) {
  padding: 0;
  height: auto !important;
  min-height: 32px;
}

:deep(.v-chip.v-chip--selected) {
  font-weight: 600;
}

.no-transition {
  transition: none !important;
}

.no-transition::before {
  transition: none !important;
}

.no-transition::after {
  transition: none !important;
}

/* Mobile optimizations */
@media (max-width: 600px) {
  .category-title {
    margin-bottom: 8px;
  }
  
  :deep(.v-chip) {
    min-height: 28px;
  }
}

/* Tablet and up optimizations */
@media (min-width: 601px) {
  .subcategories-container {
    margin-left: 8px;
  }
}

/* Remove default chip margins on mobile */
@media (max-width: 600px) {
  :deep(.v-chip) {
    margin: 2px !important;
  }
}
</style>