// ./frontend/src/store/modules/search.js

import axiosInstance from '../../utils/axios'

const state = {
  showFilters: false,
  autocompleteResults: [],
  searchResults: [],
  filteredResults: [],
  visibleSellers: [],
  eligibleSellersByType: {
    local: [],
    'canada-wide': []
  },
  filters: {
    shipsToMe: false,
    selectedOptions: [],
    selectedSellers: [],
    selectedCategories: [],
    selectedSubcategories: [],
    distanceFilter: false,
    distanceFilterRadius: 100
  },
  categorySelection: {
    section: 'all',
    category: 'all'
  },
  loading: false,
  error: null
}

const mutations = {
  SET_LOADING(state, status) {
    state.loading = status
  },

  SET_ERROR(state, error) {
    state.error = error
  },

  SET_SHOW_FILTERS(state, value) {
    state.showFilters = value
  },

  SET_AUTOCOMPLETE_RESULTS(state, results) {
    state.autocompleteResults = results
  },

  CLEAR_AUTOCOMPLETE_RESULTS(state) {
    state.autocompleteResults = []
  },

  SET_SEARCH_RESULTS(state, results) {
    state.searchResults = results
    state.filteredResults = results
  },

  SET_FILTERED_RESULTS(state, results) {
    state.filteredResults = results
  },

  UPDATE_VISIBLE_SELLERS(state) {
    const uniqueSellers = new Map()
    state.filteredResults.forEach(product => {
      const seller = product.seller
      if (seller && 
          seller.location?.visible && 
          seller.geoLocation?.lat && 
          seller.geoLocation?.lng && 
          !uniqueSellers.has(seller._id)) {
        uniqueSellers.set(seller._id, seller)
      }
    })
    state.visibleSellers = Array.from(uniqueSellers.values())
  },

  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters }
  },

  SET_ELIGIBLE_SELLERS(state, { shippingType, sellers }) {
    state.eligibleSellersByType[shippingType] = sellers
  },

  CLEAR_FILTERS(state) {
    // Preserve shipping and distance filter states
    const preservedFilters = {
      shipsToMe: state.filters.shipsToMe,
      shippingType: state.filters.shippingType,
      distanceFilter: state.filters.distanceFilter,
      distanceFilterRadius: state.filters.distanceFilterRadius
    }
    
    state.filters = {
      ...preservedFilters,
      selectedOptions: [],
      selectedSellers: [],
      selectedCategories: [],
      selectedSubcategories: []
    }
    state.categorySelection = {
      section: 'all',
      category: 'all'
    }
  },

  SET_CATEGORY_SELECTION(state, selection) {
    state.categorySelection = selection
  }
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  return R * c // Distance in km
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

const actions = {
  async fetchAutocompleteResults({ commit }, query) {
    if (!query) {
      commit('CLEAR_AUTOCOMPLETE_RESULTS')
      return
    }

    if (query.length >= 3) {
      try {
        commit('SET_LOADING', true)
        commit('SET_ERROR', null)
        
        const response = await axiosInstance.get('/api/searches/autocomplete', {
          params: { q: query }
        })

        commit('SET_AUTOCOMPLETE_RESULTS', response.data)
      } catch (error) {
        console.error('Error fetching autocomplete results:', error)
        const errorMessage = error.response?.data?.error || error.message || 'Error fetching suggestions'
        commit('SET_ERROR', errorMessage)
        commit('SET_AUTOCOMPLETE_RESULTS', [])
      } finally {
        commit('SET_LOADING', false)
      }
    } else {
      commit('CLEAR_AUTOCOMPLETE_RESULTS')
    }
  },

  async searchProducts({ commit, dispatch }, query) {
    try {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)

      const response = await axiosInstance.get('/api/products/search', {
        params: { q: query }
      })

      commit('SET_SEARCH_RESULTS', response.data)
      commit('UPDATE_VISIBLE_SELLERS')
      return response.data
    } catch (error) {
      console.error('Error searching products:', error)
      commit('SET_ERROR', error.response?.data?.message || 'Error searching products')
      commit('SET_SEARCH_RESULTS', [])
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  clearAutocompleteResults({ commit }) {
    commit('CLEAR_AUTOCOMPLETE_RESULTS')
  },

  updateEligibleSellers({ commit, state, rootGetters }, shippingType) {
    // Filter out sellers that don't offer delivery first
    const deliveryEnabledSellers = state.searchResults
      .map(product => product.seller)
      .filter((seller, index, self) => 
        seller && 
        seller.deliveryAvailable && 
        self.findIndex(s => s._id === seller._id) === index
      )

    if (shippingType === 'canada-wide') {
      commit('SET_ELIGIBLE_SELLERS', { 
        shippingType,
        sellers: deliveryEnabledSellers 
      })
      return
    }

    // For local delivery, check shipping zones
    const userZone = rootGetters['location/getLocalShippingZone']
    if (!userZone) {
      commit('SET_ELIGIBLE_SELLERS', { shippingType, sellers: [] })
      return
    }

    const eligibleSellers = deliveryEnabledSellers.filter(seller => {
      const sellerZoneId = seller.location?.localDeliveryZone?._id || seller.location?.localDeliveryZone
      const userZoneId = userZone._id
      return sellerZoneId === userZoneId
    })

    commit('SET_ELIGIBLE_SELLERS', { shippingType, sellers: eligibleSellers })
  },

  updateFilters({ commit, dispatch }, filters) {
    commit('SET_FILTERS', filters)
    dispatch('applyFilters')
  },

  clearAllFilters({ commit, dispatch }) {
    commit('CLEAR_FILTERS')
    dispatch('applyFilters')
  },

  updateCategorySelection({ commit, dispatch }, selection) {
    commit('SET_CATEGORY_SELECTION', selection)
    dispatch('applyFilters')
  },

  applyFilters({ commit, state, getters, rootGetters }) {
    let filtered = state.searchResults
  
    // Apply shipping filters if enabled
    if (state.filters.shipsToMe) {
      filtered = getters.eligibleProducts(state.filters.shippingType || 'local')
    }
  
    // Apply distance filter if enabled
    if (state.filters.distanceFilter) {
      const userCoords = rootGetters['location/getCoordinates']
      if (userCoords.lat && userCoords.lng) {
        filtered = filtered.filter(product => {
          const sellerGeoLocation = product.seller?.geoLocation
          if (!sellerGeoLocation?.lat || !sellerGeoLocation?.lng) return false
          
          const distance = calculateDistance(
            userCoords.lat,
            userCoords.lng,
            sellerGeoLocation.lat,
            sellerGeoLocation.lng
          )
          return distance <= state.filters.distanceFilterRadius
        })
      }
    }
  
    // Apply category and section filters
    filtered = filtered.filter(product => {
      // Skip if product has no category
      if (!product?.category) return false
  
      const categoryToSectionMap = rootGetters['category/getCategoryToSectionMap']
      
      // Section filter
      if (state.categorySelection.section !== 'all') {
        const productCategory = product.category.toLowerCase()
        const productSection = categoryToSectionMap[productCategory]
        
        if (!productSection || 
            productSection.toLowerCase() !== state.categorySelection.section.toLowerCase()) {
          return false
        }
      }
  
      // Category filter
      if (state.categorySelection.category !== 'all' && 
          product.category.toLowerCase() !== state.categorySelection.category.toLowerCase()) {
        return false
      }
  
      // Category selection filter
      if (state.filters.selectedCategories.length > 0 && 
          !state.filters.selectedCategories.some(cat => 
            cat.toLowerCase() === product.category.toLowerCase()
          )) {
        return false
      }
  
      // Subcategories filter
      if (state.filters.selectedSubcategories.length > 0 && 
          !state.filters.selectedSubcategories.some(sub => 
            sub.toLowerCase() === product.subcategory?.toLowerCase()
          )) {
        return false
      }
  
      // Options filter
      if (state.filters.selectedOptions.length > 0) {
        return state.filters.selectedOptions.every(option => {
          // Handle special boolean flags
          if (option === 'Halal') return product.halal
          if (option === 'Certified Organic') return product.certifiedOrganic
          
          // Handle other product options
          return product.options?.some(productOption => 
            productOption.option.toLowerCase() === option.toLowerCase()
          )
        })
      }
  
      return true
    })
  
    // Apply seller filters
    if (state.filters.selectedSellers.length > 0) {
      filtered = filtered.filter(product => 
        state.filters.selectedSellers.includes(product.seller._id)
      )
    }
  
    commit('SET_FILTERED_RESULTS', filtered)
    commit('UPDATE_VISIBLE_SELLERS')
  }
}

const getters = {
  autocompleteResults: state => state.autocompleteResults,
  searchResults: state => state.searchResults,
  filteredResults: state => state.filteredResults,
  filters: state => state.filters,
  categorySelection: state => state.categorySelection,
  isLoading: state => state.loading,
  error: state => state.error,
  visibleSellers: state => state.visibleSellers,
  
  hasActiveFilters: state => {
    return state.filters.shipsToMe || 
      state.filters.selectedOptions.length > 0 ||
      state.filters.selectedSellers.length > 0 || 
      state.filters.selectedCategories.length > 0 ||
      state.filters.selectedSubcategories.length > 0 ||
      state.filters.distanceFilter
  },

  eligibleSellers: state => shippingType => {
    return state.eligibleSellersByType[shippingType] || []
  },

  eligibleProducts: (state, getters) => shippingType => {
    const eligibleSellerIds = getters.eligibleSellers(shippingType)
      .map(s => s._id)

    if (eligibleSellerIds.length === 0) {
      return []
    }

    return state.searchResults.filter(product => {
      if (!eligibleSellerIds.includes(product.seller._id)) {
        return false
      }

      return shippingType === 'canada-wide'
        ? product.isEligibleForCanadaWideShipping
        : product.deliveryOptions.includes('Delivery')
    })
  },

  searchOptions: state => {
    if (!Array.isArray(state.searchResults)) return []
    
    const optionCounts = new Map()
    const excludedProperties = [
      'weightAdjusted',
      'isActive',
      'isEligibleForCanadaWideShipping',
      'needsRefrigeration',
      'needsFreezing'
    ]
    
    state.searchResults.forEach(product => {
      if (Array.isArray(product?.options)) {
        product.options.forEach(opt => {
          optionCounts.set(opt.option, (optionCounts.get(opt.option) || 0) + 1)
        })
      }
      
      Object.entries(product || {}).forEach(([key, value]) => {
        if (typeof value === 'boolean' && 
            value === true && 
            !excludedProperties.includes(key)) {
          const name = key
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, str => str.toUpperCase())
            .trim()
          optionCounts.set(name, (optionCounts.get(name) || 0) + 1)
        }
      })
    })
    
    return Array.from(optionCounts.entries())
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
  },

  searchSellers: state => {
    if (!Array.isArray(state.searchResults)) return []
    
    const sellerMap = new Map()
    state.searchResults.forEach(product => {
      const seller = product?.seller
      if (seller?._id) {
        if (!sellerMap.has(seller._id)) {
          sellerMap.set(seller._id, {
            id: seller._id,
            name: seller.name || 'Unknown Seller',
            count: 1
          })
        } else {
          sellerMap.get(seller._id).count++
        }
      }
    })
    
    return Array.from(sellerMap.values())
      .sort((a, b) => a.name.localeCompare(b.name))
  },

  searchCategories: state => {
    if (!Array.isArray(state.searchResults)) return []
    
    const categoryMap = new Map()
    state.searchResults.forEach(product => {
      if (product?.category) {
        categoryMap.set(product.category, (categoryMap.get(product.category) || 0) + 1)
      }
    })
    
    return Array.from(categoryMap.entries())
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
  },

  searchSubcategories: state => {
    if (!Array.isArray(state.searchResults)) return []
    
    const subcategoryMap = new Map()
    state.searchResults.forEach(product => {
      if (product?.subcategory) {
        subcategoryMap.set(product.subcategory, 
          (subcategoryMap.get(product.subcategory) || 0) + 1)
      }
    })
    
    return Array.from(subcategoryMap.entries())
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}