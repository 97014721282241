<template>
  <v-app id="inspire">
    <AppMenu v-model="drawer" />

    <v-app-bar :color="'#10341A'" app flat>
      <v-app-bar-nav-icon @click="drawer = !drawer" color="white"></v-app-bar-nav-icon>
      
      <router-link to="/" class="text-decoration-none d-flex align-center">
        <v-img
          :src="newLogo"
          :width="140"
          :height="38"
          contain
          class="mr-2"
          @load="onLogoLoad"
          @error="onLogoError"
        ></v-img>
      </router-link>

      <SearchInput />

      <div class="mx-2"></div>

      <SetLocation class="d-none d-sm-flex" />

      <div class="mobile-buttons d-flex align-center">
        <v-btn 
          icon 
          @click="$router.push('/explore')"
          class="d-none d-sm-flex"
        >
          <v-tooltip
            location="bottom"
            text="Map"
          >
            <template #activator="{ props }">
              <v-icon v-bind="props" color="white">mdi-map-outline</v-icon>
            </template>
          </v-tooltip>
        </v-btn>

        <v-btn 
          icon 
          @click="$router.push('/lists')"
          class="d-none d-sm-flex"
        >
          <v-tooltip
            location="bottom"
            text="Lists"
          >
            <template #activator="{ props }">
              <v-icon v-bind="props" color="white">mdi-clipboard-list</v-icon>
            </template>
          </v-tooltip>
        </v-btn>

        <v-btn 
          icon 
          @click="$router.push('/browse')"
          class="d-none d-sm-flex"
        >
          <v-tooltip
            location="bottom"
            text="Browse products"
          >
            <template #activator="{ props }">
              <v-icon v-bind="props" color="white">mdi-storefront</v-icon>
            </template>
          </v-tooltip>
        </v-btn>

        <v-btn 
          icon 
          @click="goToCart"
        >
          <v-icon color="white">mdi-cart-outline</v-icon>
          <v-badge
            v-if="cartItemCount > 0"
            :content="cartItemCount"
            color="error"
          ></v-badge>
        </v-btn>

        <AccountMenu mode="dark" />
      </div>
    </v-app-bar>

    <div class="fabs-container">
      <v-menu v-if="isAdmin" open-on-hover offset-y>
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="#10341A"
            class="fab admin-fab"
            icon="mdi-shield-account"
            size="large"
          ></v-btn>
        </template>
        <v-card max-width="300" class="nav-menu" elevation="5" rounded="xl">
          <v-card-text class="pa-6">
            <h2 class="text-h5 font-weight-bold mb-6">Admin</h2>
            <v-list density="compact" nav>
              <v-list-item
                v-for="item in adminItems"
                :key="item.title"
                :to="item.to"
                rounded="lg"
                class="mb-2"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu v-if="isSeller" open-on-hover offset-y>
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="#10341A"
            class="fab seller-fab"
            icon="mdi-account-cowboy-hat"
            size="large"
          ></v-btn>
        </template>
        <v-card max-width="300" class="nav-menu" elevation="5" rounded="xl">
          <v-card-text class="pa-6">
            <h2 class="text-h5 font-weight-bold mb-6">Seller</h2>
            <v-list density="compact" nav>
              <v-list-item
                v-for="item in sellerItems"
                :key="item.title"
                :to="item.to"
                rounded="lg"
                class="mb-2"
              >
                <template #prepend>
                  <v-icon>{{ item.icon }}</v-icon>
                </template>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>

    <v-main>
      <v-container class="py-8 px-6" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AccountMenu from '@/components/AccountMenu.vue'
import AppMenu from '@/components/AppMenu.vue'
import SearchInput from '@/components/SearchInput.vue'
import SetLocation from '@/components/SetLocation.vue'
import newLogo from '@/assets/logo_dark.png'

export default {
  name: 'App',
  
  components: {
    AccountMenu,
    AppMenu,
    SearchInput,
    SetLocation,
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const drawer = ref(false)
    const showFooter = ref(false)

    const primaryColor = "#623E2A"
    const secondaryColor = "#658D8B"

    const adminItems = [
      { title: 'Categories', to: '/admin/categories' },
      { title: 'Products', to: '/admin/products' },
      { title: 'Orders', to: '/admin/orders' },
      { title: 'Bulk Meat Products', to: '/admin/bulk-meat-products' },
      { title: 'Bulk Orders', to: '/admin/bulk-orders' },
      { title: 'Sellers', to: '/admin/sellers' },
      { title: 'Local Shipping Zones', to: '/admin/local-shipping-zones' },
      { title: 'Municipalities', to: '/admin/municipalities' },
      { title: 'Users', to: '/admin/users' },
      { title: 'Recommendations', to: '/admin/recommendations' },
      { title: 'Trending Data', to: '/admin/trending' },
      { title: 'Search History', to: '/admin/search-history' },
      { title: 'Premium Subscriptions', to: '/admin/premium-subscriptions' }
    ]

    const sellerItems = computed(() => [
      { title: 'Seller Dashboard', icon: 'mdi-monitor-dashboard', to: '/seller-dashboard' },
      { title: 'Your Page', icon: 'mdi-storefront', to: '/seller' },
      { title: 'Your Info', icon: 'mdi-information', to: '/seller-info' },
      { title: 'Your Inventory', icon: 'mdi-package-variant', to: '/seller-inventory' },
      { title: 'Your Recommendations', icon: 'mdi-star', to: '/seller-recommendations' },
      { title: 'Your Orders', icon: 'mdi-clipboard-text', to: '/seller-orders' },
      { title: 'Your Shipments', icon: 'mdi-truck-delivery', to: '/seller-shipments' },
    ])

    const isAdmin = computed(() => store.getters['user/isAdmin'])
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn'])
    const user = computed(() => store.state.user.user)
    const isSeller = computed(() => user.value && user.value.isSeller)
    const cartItemCount = computed(() => store.getters['cart/cartItemCount'])

    const goToCart = () => {
      router.push('/cart')
    }

    const onLogoLoad = () => {
      console.log('Logo loaded successfully')
    }

    const onLogoError = (error) => {
      console.error('Error loading logo:', error)
    }

    const checkScrollPosition = () => {
      const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
      showFooter.value = bottomOfWindow
    }

    onMounted(() => {
      window.addEventListener('scroll', checkScrollPosition)
      checkScrollPosition()
      store.dispatch('location/initializeLocation')
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', checkScrollPosition)
    })

    return {
      drawer,
      primaryColor,
      secondaryColor,
      isAdmin,
      isLoggedIn,
      user,
      isSeller,
      cartItemCount,
      adminItems,
      sellerItems,
      showFooter,
      goToCart,
      onLogoLoad,
      onLogoError,
      newLogo,
    }
  }
}
</script>

<style scoped>
.nav-menu {
  width: 300px;
}

.fabs-container {
  position: fixed !important;
  top: 66px; /* Height of the app bar + 2px */
  right: 16px;
  z-index: 100;
  display: flex;
  gap: 8px;
}

.fab {
  color: white !important;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2) !important;
}

.v-menu {
  margin-top: 66px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
}

/* Add styles for mobile buttons */
.mobile-buttons {
  gap: 4px;
}

@media (max-width: 600px) {
  .v-main {
    padding-top: calc(56px + 56px) !important;
  }
  
  .v-app-bar {
    height: 56px !important;
  }

  .fabs-container {
    top: 114px; /* Account for app bar + search bar on mobile + 2px */
  }

  .v-menu {
    margin-top: 114px;
  }

  /* Ensure mobile buttons stay compact and aligned right only on mobile */
  .mobile-buttons {
    margin-left: auto;
    gap: 2px;
  }
}
</style>