<!-- SliderSeller.vue -->
<template>
  <div :class="{ 'mb-8': $vuetify.display.mdAndUp, 'mb-4': $vuetify.display.smAndDown, 'mt-8': $vuetify.display.mdAndUp, 'mt-6': $vuetify.display.smAndDown }">
    <div class="d-flex justify-space-between align-center mb-4" :class="{ 'px-4': $vuetify.display.smAndDown }">
      <h2 :class="{ 'text-h5 font-weight-bold': $vuetify.display.mdAndUp, 'text-h6 font-weight-bold': $vuetify.display.smAndDown }">
        Your Local Farms
      </h2>
      <v-btn
        variant="text"
        class="text-none"
        @click="$router.push('/browse')"
        :size="$vuetify.display.smAndDown ? 'small' : 'default'"
      >
        View All <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <v-sheet class="position-relative">
      <v-btn
        v-if="$vuetify.display.mdAndUp"
        icon="mdi-chevron-left"
        variant="text"
        class="scroll-button left-button"
        @click="scroll('left')"
        :disabled="atStart"
      ></v-btn>

      <div class="featured-scroll" ref="containerRef">
        <v-row nowrap class="flex-nowrap custom-row">
          <v-col v-for="(item, i) in items" 
                :key="i" 
                cols="auto"
                class="custom-col"
          >
            <v-card
              :width="featuredCardWidth"
              :height="featuredCardHeight"
              class="featured-card"
              @click="$router.push(item.route)"
              elevation="0"
            >
              <v-img
                :src="item.image"
                :height="featuredCardHeight"
                cover
                class="rounded"
              >
                <div class="featured-overlay">
                  <div class="featured-tag" v-if="item.tag">{{ item.tag }}</div>
                  <div class="featured-content">
                    <h3 class="featured-title" :class="{ 'mobile-title': $vuetify.display.smAndDown }">
                      {{ item.title }}
                    </h3>
                    <p class="featured-subtitle" :class="{ 'mobile-subtitle': $vuetify.display.smAndDown }">
                      {{ item.subtitle }}
                    </p>
                  </div>
                  <v-btn
                    icon="mdi-arrow-right"
                    variant="plain"
                    color="white"
                    :size="$vuetify.display.smAndDown ? 'x-small' : 'small'"
                    class="featured-arrow"
                  ></v-btn>
                </div>
              </v-img>
            </v-card>
          </v-col>

          <!-- View More Card -->
          <v-col cols="auto" class="custom-col">
            <v-card
              :width="halfCardWidth"
              :height="featuredCardHeight"
              class="featured-card view-more-card"
              @click="$router.push('/browse')"
              elevation="0"
            >
              <v-img
                src="@/assets/category-icons/other.png"
                :height="featuredCardHeight"
                cover
                class="rounded"
              >
                <div class="featured-overlay d-flex align-center justify-center">
                  <div class="text-center">
                    <h3 class="view-more-text">VIEW MORE</h3>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-btn
        v-if="$vuetify.display.mdAndUp"
        icon="mdi-chevron-right"
        variant="text"
        class="scroll-button right-button"
        @click="scroll('right')"
        :disabled="atEnd"
      ></v-btn>
    </v-sheet>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

export default {
  name: 'SliderSeller',
  
  props: {
    items: {
      type: Array,
      required: true
    }
  },

  setup() {
    const router = useRouter();
    const display = useDisplay();
    const containerRef = ref(null);
    const atStart = ref(true);
    const atEnd = ref(false);

    // Compute responsive card sizes
    const featuredCardWidth = computed(() => {
      if (display.xs.value) return '200';
      if (display.sm.value) return '220';
      return '230';
    });

    const halfCardWidth = computed(() => {
      if (display.xs.value) return '100';
      if (display.sm.value) return '110';
      return '115';
    });

    const featuredCardHeight = computed(() => {
      if (display.xs.value) return '200';
      if (display.sm.value) return '220';
      return '230';
    });

    const scroll = (direction) => {
      const scrollAmount = 400;
      if (direction === 'left') {
        containerRef.value.scrollLeft -= scrollAmount;
      } else {
        containerRef.value.scrollLeft += scrollAmount;
      }
      checkScrollPosition();
    };

    const checkScrollPosition = () => {
      atStart.value = containerRef.value.scrollLeft <= 0;
      atEnd.value = containerRef.value.scrollLeft + containerRef.value.clientWidth >= containerRef.value.scrollWidth;
    };

    onMounted(() => {
      checkScrollPosition();
      containerRef.value.addEventListener('scroll', checkScrollPosition);
    });

    return {
      containerRef,
      scroll,
      atStart,
      atEnd,
      featuredCardWidth,
      halfCardWidth,
      featuredCardHeight
    };
  }
};
</script>

<style scoped>
.featured-scroll {
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  position: relative;
  padding: 0 40px;
}

@media (max-width: 600px) {
  .featured-scroll {
    padding: 0 16px;
  }
}

.featured-scroll::-webkit-scrollbar {
  display: none;
}

.custom-row {
  margin-right: 0;
  margin-left: 0;
}

.custom-col {
  padding-right: 8px;
  padding-left: 8px;
}

@media (max-width: 600px) {
  .custom-col {
    padding-right: 6px;
    padding-left: 6px;
  }
}

.featured-card {
  position: relative;
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 16px;
  overflow: hidden;
}

.featured-card:hover {
  transform: scale(1.02);
}

.featured-card :deep(.v-img__img) {
  border-radius: 16px;
}

.featured-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%);
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: rgba(255,255,255,0.9) !important;
}

.left-button {
  left: 0;
}

.right-button {
  right: 0;
}

.featured-content {
  position: absolute;
  bottom: 12px;
  left: 12px;
  width: 66%;
  color: white;
  text-shadow: 0 2px 4px rgba(0,0,0,0.4);
}

.featured-tag {
  position: absolute;
  top: 12px;
  left: 12px;
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  padding: 2px 12px;
  border-radius: 12px;
  color: white;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.featured-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.mobile-title {
  font-size: 14px;
}

.featured-subtitle {
  font-size: 11px;
  font-weight: 500;
  opacity: 0.9;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.mobile-subtitle {
  font-size: 10px;
}

.featured-arrow {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: transparent !important;
  opacity: 0.9;
  transform: scale(0.8);
}

.view-more-text {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 8px;
}

@media (max-width: 600px) {
  .featured-content {
    bottom: 8px;
    left: 8px;
  }
  
  .featured-tag {
    top: 8px;
    left: 8px;
    font-size: 9px;
    padding: 1px 8px;
  }

  .featured-arrow {
    bottom: 6px;
    right: 6px;
    transform: scale(0.7);
  }
  
  .view-more-text {
    font-size: 14px;
  }
}

@media (hover: none) {
  .featured-card:hover {
    transform: none;
  }
}
</style>