# ./frontend/src/components/filters/SearchMoreFilters.vue
<template>
  <div>
    <v-btn
      icon="mdi-tune-vertical-variant"
      :variant="hasOtherActiveFilters ? 'flat' : 'outlined'"
      :color="hasOtherActiveFilters ? 'primary' : undefined"
      @click="$emit('update:showDialog', true)"
      class="me-2 rounded-pill filters-button"
    />

    <!-- More Filters Dialog -->
    <v-dialog
      :model-value="showDialog"
      @update:model-value="$emit('update:showDialog', $event)"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h6 pa-4">Filters</v-card-title>
        
        <v-card-text>
          <!-- Options Section -->
          <div v-if="searchOptions.length" class="filter-section mb-6">
            <h3 class="text-subtitle-1 font-weight-medium mb-3">Options</h3>
            <v-list density="compact" class="pa-0 bg-transparent rounded-lg">
              <v-list-item
                v-for="option in searchOptions"
                :key="option.name"
                class="px-2 rounded-lg mb-1 hover-bg-surface"
              >
                <v-checkbox
                  :model-value="selectedOptions.includes(option.name)"
                  @update:model-value="checked => updateSelection('selectedOptions', option.name, checked)"
                  color="primary"
                  hide-details
                  density="compact"
                >
                  <template #label>
                    <div class="d-flex justify-space-between align-center w-100">
                      <span class="text-body-2">{{ option.name }}</span>
                      <span class="text-caption text-medium-emphasis ms-2">({{ option.count }})</span>
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item>
            </v-list>
          </div>

          <!-- Sellers Section -->
          <div v-if="filteredSellers.length" class="filter-section mb-6">
            <h3 class="text-subtitle-1 font-weight-medium mb-3">Seller</h3>
            <v-list density="compact" class="pa-0 bg-transparent rounded-lg">
              <v-list-item
                v-for="seller in filteredSellers"
                :key="seller.id"
                class="px-2 rounded-lg mb-1 hover-bg-surface"
              >
                <v-checkbox
                  :model-value="selectedSellers.includes(seller.id)"
                  @update:model-value="checked => updateSelection('selectedSellers', seller.id, checked)"
                  color="primary"
                  hide-details
                  density="compact"
                >
                  <template #label>
                    <div class="d-flex justify-space-between align-center w-100">
                      <span class="text-body-2">{{ seller.name }}</span>
                      <span class="text-caption text-medium-emphasis ms-2">({{ seller.count }})</span>
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item>
            </v-list>
          </div>

          <!-- Categories Section -->
          <div v-if="searchCategories.length" class="filter-section mb-6">
            <h3 class="text-subtitle-1 font-weight-medium mb-3">Category</h3>
            <v-list density="compact" class="pa-0 bg-transparent rounded-lg">
              <v-list-item
                v-for="category in searchCategories"
                :key="category.name"
                class="px-2 rounded-lg mb-1 hover-bg-surface"
              >
                <v-checkbox
                  :model-value="selectedCategories.includes(category.name)"
                  @update:model-value="checked => updateSelection('selectedCategories', category.name, checked)"
                  color="primary"
                  hide-details
                  density="compact"
                >
                  <template #label>
                    <div class="d-flex justify-space-between align-center w-100">
                      <span class="text-body-2">{{ category.name }}</span>
                      <span class="text-caption text-medium-emphasis ms-2">({{ category.count }})</span>
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item>
            </v-list>
          </div>

          <!-- Subcategories Section -->
          <div v-if="searchSubcategories.length" class="filter-section mb-6">
            <h3 class="text-subtitle-1 font-weight-medium mb-3">Subcategory</h3>
            <v-list density="compact" class="pa-0 bg-transparent rounded-lg">
              <v-list-item
                v-for="subcategory in searchSubcategories"
                :key="subcategory.name"
                class="px-2 rounded-lg mb-1 hover-bg-surface"
              >
                <v-checkbox
                  :model-value="selectedSubcategories.includes(subcategory.name)"
                  @update:model-value="checked => updateSelection('selectedSubcategories', subcategory.name, checked)"
                  color="primary"
                  hide-details
                  density="compact"
                >
                  <template #label>
                    <div class="d-flex justify-space-between align-center w-100">
                      <span class="text-body-2">{{ subcategory.name }}</span>
                      <span class="text-caption text-medium-emphasis ms-2">({{ subcategory.count }})</span>
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item>
            </v-list>
          </div>

        </v-card-text>

        <v-card-actions class="pa-4">
          <v-container class="pa-0">
            <v-row justify="center">
              <v-col cols="12">
                <v-btn
                  block
                  color="error"
                  variant="outlined"
                  height="48"
                  rounded="lg"
                  class="mb-3 custom-button custom-button--error"
                  @click="$emit('clear')"
                >
                  CLEAR ALL FILTERS
                </v-btn>
                <v-btn
                  block
                  color="primary"
                  height="48"
                  rounded="lg"
                  class="custom-button custom-button--primary"
                  @click="$emit('apply')"
                >
                  APPLY FILTERS
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchMoreFilters',

  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    hasOtherActiveFilters: {
      type: Boolean,
      required: true
    },
    searchOptions: {
      type: Array,
      default: () => []
    },
    filteredSellers: {
      type: Array,
      default: () => []
    },
    searchCategories: {
      type: Array,
      default: () => []
    },
    searchSubcategories: {
      type: Array,
      default: () => []
    },
    selectedOptions: {
      type: Array,
      required: true
    },
    selectedSellers: {
      type: Array,
      required: true
    },
    selectedCategories: {
      type: Array,
      required: true
    },
    selectedSubcategories: {
      type: Array,
      required: true
    }
  },

  emits: [
    'update:showDialog',
    'update:selectedOptions',
    'update:selectedSellers',
    'update:selectedCategories',
    'update:selectedSubcategories',
    'clear',
    'apply'
  ],

  setup(props, { emit }) {
    const updateSelection = (filterType, value, checked) => {
      const currentValues = props[filterType]
      const newValues = checked 
        ? [...currentValues, value]
        : currentValues.filter(item => item !== value)
      
      emit(`update:${filterType}`, newValues)
    }

    return {
      updateSelection
    }
  }
})
</script>

<style scoped>
.filters-button {
  height: 36px !important;
  width: 42px !important;
  min-width: 24px !important;
  padding: 0 12px !important;
}

.filter-section :deep(.v-list-item) {
  min-height: 40px !important;
  transition: background-color 0.2s ease;
}

.filter-section :deep(.v-checkbox) {
  margin: 0 !important;
  padding: 0 !important;
}

.filter-section :deep(.v-selection-control) {
  min-height: unset !important;
}

.filter-section :deep(.v-selection-control__wrapper) {
  margin-right: 8px !important;
}

.custom-button {
  text-transform: uppercase !important;
  letter-spacing: 0.0892857143em !important;
  font-weight: 500 !important;
  width: 100% !important;
}

.custom-button--primary {
  background-color: rgb(var(--v-theme-primary)) !important;
  color: rgb(var(--v-theme-on-primary)) !important;
}

.custom-button--error {
  border-color: rgb(var(--v-theme-error)) !important;
  color: rgb(var(--v-theme-error)) !important;
}

@media (max-width: 599px) {
  .v-dialog {
    margin: 0 !important;
  }

  .filter-section :deep(.v-list-item) {
    padding: 4px 8px !important;
  }
}
</style>