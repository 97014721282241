<!-- ./frontend/src/components/search/SearchFilters.vue -->
<template>
  <div>
    <div class="filters-section pa-2">
      <!-- Primary Filters -->
      <div class="mb-3">
        <div class="d-flex flex-column">
          <span class="text-subtitle-2 font-weight-bold mb-2">MORE FILTERS:</span>
          <div class="d-flex flex-wrap gap-2">
            <SearchShippingFilter
              v-model:shipsToMe="localFilters.shipsToMe"
              v-model:shippingType="localFilters.shippingType"
              :user-local-shipping-zone="userLocalShippingZone"
              @show-location-dialog="showSetLocation = true"
            />

            <SearchDistanceFilter
              v-model="localFilters.distanceFilter"
              v-model:distanceFilterRadius="localFilters.distanceFilterRadius"
            />
          </div>
        </div>
      </div>

      <!-- Secondary Controls and Filters -->
      <div class="d-flex flex-column gap-2">
        <div class="d-flex align-center">
          <SearchMoreFilters
            v-model:show-dialog="dialogOpen"
            v-model:selected-options="localFilters.selectedOptions"
            v-model:selected-sellers="localFilters.selectedSellers"
            v-model:selected-categories="localFilters.selectedCategories"
            v-model:selected-subcategories="localFilters.selectedSubcategories"
            :has-other-active-filters="hasOtherActiveFilters"
            :search-options="searchOptions"
            :filtered-sellers="filteredSellers"
            :search-categories="searchCategories"
            :search-subcategories="searchSubcategories"
            @clear="handleClear"
            @apply="handleApply"
          />

          <v-btn
            v-if="hasNonLocationFilters"
            variant="text"
            color="black"
            @click="handleClear"
            class="clear-button ms-2"
          >
            CLEAR
          </v-btn>
        </div>

        <!-- Active Filter Chips -->
        <div v-if="hasActiveFilters" class="active-filters">
          <div class="filter-chips-container">
            <v-chip
              v-for="option in filters.selectedOptions"
              :key="option"
              closable
              size="small"
              variant="outlined"
              class="filter-chip"
              @click:close="removeFilter('selectedOptions', option)"
            >
              {{ option }}
            </v-chip>
            
            <v-chip
              v-for="seller in filters.selectedSellers"
              :key="seller"
              closable
              size="small"
              variant="outlined"
              class="filter-chip"
              @click:close="removeFilter('selectedSellers', seller)"
            >
              {{ getSellerName(seller) }}
            </v-chip>
            
            <v-chip
              v-for="category in filters.selectedCategories"
              :key="category"
              closable
              size="small"
              variant="outlined"
              class="filter-chip"
              @click:close="removeFilter('selectedCategories', category)"
            >
              {{ category }}
            </v-chip>
            
            <v-chip
              v-for="subcategory in filters.selectedSubcategories"
              :key="subcategory"
              closable
              size="small"
              variant="outlined"
              class="filter-chip"
              @click:close="removeFilter('selectedSubcategories', subcategory)"
            >
              {{ subcategory }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>

    <!-- Set Location Dialog -->
    <v-dialog v-model="showSetLocation" max-width="400">
      <v-card>
        <v-card-title>Set Your Location</v-card-title>
        <v-card-text>
          <p>Please set your location to use the "Ships to me" filter.</p>
          <SetLocation @location-set="handleLocationSet" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import SetLocation from '@/components/SetLocation.vue'
import SearchShippingFilter from '@/components/search/SearchShippingFilter.vue'
import SearchDistanceFilter from '@/components/search/SearchDistanceFilter.vue'
import SearchMoreFilters from '@/components/search/SearchMoreFilters.vue'

export default {
  name: 'SearchFilters',
  components: {
    SetLocation,
    SearchShippingFilter,
    SearchDistanceFilter,
    SearchMoreFilters
  },
  setup() {
    const store = useStore()
    const showSetLocation = ref(false)
    
    const dialogOpen = computed({
      get: () => store.state.search.showFilters,
      set: value => store.commit('search/SET_SHOW_FILTERS', value)
    })

    const localFilters = ref({ 
      ...store.getters['search/filters'],
      shippingType: 'local',
      distanceFilter: false,
      distanceFilterRadius: 100
    })

    // Store computed properties
    const filters = computed(() => store.getters['search/filters'])
    // For showing all active filters, including location filters
    const hasActiveFilters = computed(() => 
      store.getters['search/hasActiveFilters']
    )

    // Only for showing the CLEAR button
    const hasNonLocationFilters = computed(() => {
      const { selectedOptions, selectedSellers, selectedCategories, selectedSubcategories } = filters.value
      return selectedOptions.length > 0 || 
             selectedSellers.length > 0 || 
             selectedCategories.length > 0 || 
             selectedSubcategories.length > 0
    })
    
    // For other UI purposes
    const hasOtherActiveFilters = computed(() => hasNonLocationFilters.value)
    const searchOptions = computed(() => store.getters['search/searchOptions'])
    const searchSellers = computed(() => store.getters['search/searchSellers'])
    const searchCategories = computed(() => store.getters['search/searchCategories'])
    const searchSubcategories = computed(() => store.getters['search/searchSubcategories'])
    const userLocalShippingZone = computed(() => store.getters['location/getLocalShippingZone'])
    const filteredSellers = computed(() => {
      if (!localFilters.value.shipsToMe) {
        return searchSellers.value
      }
      return store.getters['search/eligibleSellers'](localFilters.value.shippingType)
    })

    const getSellerName = (sellerId) => {
      const seller = searchSellers.value.find(s => s.id === sellerId)
      return seller?.name || ''
    }

    const removeFilter = (filterType, value) => {
      if (filterType === 'shipsToMe') {
        store.dispatch('search/updateFilters', { 
          ...filters.value,
          shipsToMe: false 
        })
        return
      }

      if (filterType === 'distanceFilter') {
        localFilters.value.distanceFilter = false
        handleApply()
        return
      }

      store.dispatch('search/updateFilters', {
        ...filters.value,
        [filterType]: filters.value[filterType].filter(item => item !== value)
      })
    }

    const handleClear = () => {
      // Preserve shipping and distance filter states
      const preservedFilters = {
        shipsToMe: localFilters.value.shipsToMe,
        shippingType: localFilters.value.shippingType,
        distanceFilter: localFilters.value.distanceFilter,
        distanceFilterRadius: localFilters.value.distanceFilterRadius
      }

      // Reset local filters while preserving shipping and distance states
      localFilters.value = {
        ...preservedFilters,
        selectedOptions: [],
        selectedSellers: [],
        selectedCategories: [],
        selectedSubcategories: []
      }

      // Update store with preserved filters
      store.dispatch('search/clearAllFilters')
      dialogOpen.value = false

      // Reapply the preserved filters
      handleApply()
    }

    const handleApply = async () => {
      if (localFilters.value.shipsToMe && 
          localFilters.value.shippingType === 'local' && 
          !userLocalShippingZone.value) {
        showSetLocation.value = true
        return
      }

      const filtersToApply = {
        ...localFilters.value,
        shipsToMe: localFilters.value.shipsToMe,
        shippingType: localFilters.value.shippingType,
        distanceFilter: localFilters.value.distanceFilter,
        distanceFilterRadius: localFilters.value.distanceFilterRadius
      }

      await store.dispatch('search/updateFilters', filtersToApply)
      dialogOpen.value = false
    }

    const handleLocationSet = async () => {
      showSetLocation.value = false
      await store.dispatch('location/updateLocalShippingZone')
      
      if (userLocalShippingZone.value) {
        localFilters.value.shipsToMe = true
        await store.dispatch('search/updateEligibleSellers', localFilters.value.shippingType)
        handleApply()
      }
    }

    watch(() => ({
      shipsToMe: localFilters.value.shipsToMe,
      shippingType: localFilters.value.shippingType,
      distanceFilter: localFilters.value.distanceFilter,
      distanceFilterRadius: localFilters.value.distanceFilterRadius
    }), async (newVal) => {
      if (newVal.shipsToMe) {
        await store.dispatch('search/updateEligibleSellers', newVal.shippingType)
      }
      await handleApply()
    }, { deep: true })

    watch(userLocalShippingZone, (newZone, oldZone) => {
      if (!newZone && localFilters.value.shipsToMe && localFilters.value.shippingType === 'local') {
        localFilters.value.shipsToMe = false
        handleApply()
      }
    })

    onMounted(async () => {
      localFilters.value = {
        ...store.getters['search/filters'],
        shippingType: 'local',
        distanceFilter: false,
        distanceFilterRadius: 100
      }

      await Promise.all([
        store.dispatch('search/updateEligibleSellers', 'local'),
        store.dispatch('search/updateEligibleSellers', 'canada-wide')
      ])
    })

    return {
      dialogOpen,
      showSetLocation,
      localFilters,
      filters,
      hasNonLocationFilters,
      hasActiveFilters,
      hasOtherActiveFilters,
      searchOptions,
      searchCategories,
      searchSubcategories,
      filteredSellers,
      userLocalShippingZone,
      getSellerName,
      removeFilter,
      handleClear,
      handleApply,
      handleLocationSet
    }
  }
}
</script>

<style scoped>
.filters-section {
  border: none !important;
  background-color: transparent;
}

.gap-2 {
  gap: 8px;
}

.text-subtitle-2 {
  font-size: 0.875rem !important;
  line-height: 1.375rem;
  white-space: nowrap;
}

.clear-button {
  font-weight: 500 !important;
  border: none !important;
  background: none !important;
  height: 36px !important;
  min-width: auto !important;
}

.active-filters {
  width: 100%;
  overflow: hidden;
}

.filter-chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: -4px;
  width: 100%;
}

.filter-chip {
  margin: 4px !important;
  font-size: 0.875rem !important;
}

@media (max-width: 599px) {
  .filters-section {
    padding: 12px !important;
  }

  .filter-chips-container {
    margin: -2px;
  }

  .filter-chip {
    margin: 2px !important;
  }
}
</style>