<template>
  <div class="search-container d-none d-sm-flex">
    <v-form @submit.prevent="performSearch" class="d-none d-sm-flex align-center search-form" @click.stop>
      <v-text-field
        v-model="searchQuery"
        bg-color="rgba(0, 0, 0, 0.2)"
        density="compact"
        flat
        hide-details
        label="Search"
        placeholder="Search"
        single-line
        variant="filled"
        class="custom-search-input"
        color="white"
        persistent-placeholder
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
      >
        <template #append-inner>
          <v-btn
            icon
            variant="text"
            class="search-icon-btn"
            @click="performSearch"
          >
            <v-tooltip
              location="bottom"
              text="Search"
            >
              <template #activator="{ props }">
                <v-icon v-bind="props" color="white">mdi-magnify</v-icon>
              </template>
            </v-tooltip>
          </v-btn>
        </template>
      </v-text-field>

      <v-card
        v-if="showSearchPreview"
        class="search-preview-container"
        elevation="3"
        rounded="lg"
      >
        <SearchPreview v-if="!isTyping || searchQuery.length < 3" />
        <SearchAutocomplete v-else :query="searchQuery" />
      </v-card>
    </v-form>
  </div>

  <v-toolbar :color="'#10341A'" flat dense class="d-sm-none px-2 mobile-search-bar">
    <SetLocation class="mr-2" />
    <v-text-field
      v-model="searchQuery"
      bg-color="rgba(0, 0, 0, 0.2)"
      color="white"
      density="compact"
      hide-details
      label="Search"
      single-line
      variant="filled"
      class="custom-search-input"
      @keyup.enter="performSearch"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
    >
      <template #append-inner>
        <v-btn
          icon
          variant="text"
          color="white"
          class="search-icon-btn"
          @click="performSearch"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <v-card
      v-if="showSearchPreview"
      class="search-preview-container mobile"
      elevation="3"
      rounded="lg"
    >
      <SearchPreview v-if="!isTyping || searchQuery.length < 3" />
      <SearchAutocomplete v-else :query="searchQuery" />
    </v-card>
  </v-toolbar>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SearchPreview from './SearchPreview.vue'
import SearchAutocomplete from './SearchAutocomplete.vue'
import SetLocation from './SetLocation.vue'

export default {
  name: 'SearchInput',
  
  components: {
    SearchPreview,
    SearchAutocomplete,
    SetLocation
  },

  setup() {
    const router = useRouter()
    const store = useStore()
    const searchQuery = ref('')
    const isFocused = ref(false)
    const mouseOverPreview = ref(false)
    const isTyping = ref(false)
    let typingTimeout

    const showSearchPreview = computed(() => {
      return isFocused.value || mouseOverPreview.value
    })

    const handleFocus = () => {
      isFocused.value = true
    }

    const handleBlur = () => {
      setTimeout(() => {
        if (!mouseOverPreview.value) {
          isFocused.value = false
          isTyping.value = false
          store.dispatch('search/clearAutocompleteResults')
        }
      }, 100)
    }

    const handleInput = () => {
      isTyping.value = true;
      clearTimeout(typingTimeout);
      
      if (searchQuery.value && searchQuery.value.length >= 3) {
        store.dispatch('search/fetchAutocompleteResults', searchQuery.value);
      }
      
      typingTimeout = setTimeout(() => {
        if (!searchQuery.value) {
          isTyping.value = false;
          store.dispatch('search/clearAutocompleteResults');
        }
      }, 300);
    } 

    const handlePreviewMouseEnter = () => {
      mouseOverPreview.value = true
    }

    const handlePreviewMouseLeave = () => {
      mouseOverPreview.value = false
      if (!isFocused.value) {
        isTyping.value = false
        store.dispatch('search/clearAutocompleteResults')
      }
    }

    const performSearch = () => {
      if (searchQuery.value.trim()) {
        isFocused.value = false
        mouseOverPreview.value = false
        isTyping.value = false
        store.dispatch('saveSearchQuery', searchQuery.value.trim())
        store.dispatch('search/clearAutocompleteResults')
        router.push({ 
          path: '/search', 
          query: { q: searchQuery.value.trim() } 
        })
      }
    }

    onMounted(() => {
      const previewContainer = document.querySelector('.search-preview-container')
      if (previewContainer) {
        previewContainer.addEventListener('mouseenter', handlePreviewMouseEnter)
        previewContainer.addEventListener('mouseleave', handlePreviewMouseLeave)
      }
    })

    onUnmounted(() => {
      const previewContainer = document.querySelector('.search-preview-container')
      if (previewContainer) {
        previewContainer.removeEventListener('mouseenter', handlePreviewMouseEnter)
        previewContainer.removeEventListener('mouseleave', handlePreviewMouseLeave)
      }
      clearTimeout(typingTimeout)
      store.dispatch('search/clearAutocompleteResults')
    })

    return {
      searchQuery,
      showSearchPreview,
      isTyping,
      handleFocus,
      handleBlur,
      handleInput,
      performSearch
    }
  }
}
</script>

<style scoped>
.search-container {
  position: relative;
  flex: 1;
  min-width: 200px;
}

.search-form {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.search-preview-container {
  position: fixed;
  top: 64px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
  z-index: 1000;
  background-color: rgb(21, 46, 25);
  width: 800px;
  max-height: calc(80vh - 64px);
  overflow-y: auto;
}

.search-preview-container.mobile {
  position: fixed;
  top: 112px;
  left: 0;
  right: 0;
  transform: none;
  width: 100%;
  margin: 0;
  max-height: calc(100vh - 120px);
  border-radius: 0;
}

.custom-search-input {
  border-radius: 9999px !important;
}

.custom-search-input :deep(.v-field) {
  border-radius: 9999px !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

.custom-search-input :deep(.v-field:hover) {
  background: rgba(0, 0, 0, 0.2) !important;
}

.custom-search-input :deep(.v-field__outline) {
  opacity: 0 !important;
}

.custom-search-input :deep(.v-field__input) {
  color: white !important;
  min-height: 40px !important;
  padding-top: 0 !important;
  padding-left: 20px !important;
  margin-top: 6px !important;
}

.custom-search-input :deep(.v-label) {
  color: rgba(255, 255, 255, 0.7) !important;
  padding-left: 20px !important;
}

.custom-search-input :deep(.v-field--focused) {
  outline: 2px solid #1976d2 !important;
}

.search-icon-btn {
  background-color: transparent !important;
  margin-right: -12px !important;
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

@media (max-width: 900px) {
  .search-preview-container {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .mobile-search-bar {
    top: 56px !important;
    position: fixed !important;
    z-index: 4;
    width: 100%;
    display: flex;
    align-items: center;
    border-top: none !important;
    margin-top: -1px !important;
    padding-top: 1px !important;
    background-color: #10341A !important;
  }

  .custom-search-input {
    flex: 1;
  }
}
</style>