<!-- ./frontend/src/views/AdminProductsView.vue -->
<template>
  <v-container class="pa-md-12">
    <section>
      <h3 class="d-flex justify-space-between align-center text-subtitle-1 font-weight-bold">
        Products
        <v-btn
          class="text-none"
          color="primary"
          prepend-icon="mdi-plus"
          rounded="lg"
          slim
          text="Add New Product"
          variant="flat"
          @click="openDialog"
        />
      </h3>
      <div class="text-body-2 text-medium-emphasis mb-4 w-100 w-md-75">
        A list of all the products available in the system.
      </div>
      <v-data-table
        class="bg-transparent"
        :headers="headers"
        :items="allProducts"
        :items-per-page="10"
      >
        <template #[`item.productImage`]="{ item }">
          <v-img :src="item.productImage" max-width="100" max-height="100"></v-img>
        </template>
        <template #[`item.seller`]="{ item }">
          {{ getSellerName(item.seller) }}
        </template>
        <template #[`item.price`]="{ item }">
          <div>
            <div>${{ formatPrice(item.price) }}</div>
            <div v-if="item.weightAdjusted" class="text-caption text-medium-emphasis">
              ${{ formatPrice(item.pricePerMeasure) }}/{{ item.weightMeasure }}
            </div>
          </div>
        </template>
        <template #[`item.isActive`]="{ item }">
          <v-switch
            v-model="item.isActive"
            @change="updateProductStatus(item)"
          ></v-switch>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            class="text-none mr-2"
            color="primary"
            min-width="0"
            slim
            text="Edit"
            variant="text"
            @click="editItem(item)"
          />
          <v-btn
            class="text-none"
            color="error"
            min-width="0"
            slim
            text="Delete"
            variant="text"
            @click="deleteItem(item)"
          />
        </template>
      </v-data-table>
    </section>

    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="save" ref="form">
            <v-text-field
              v-model="editedItem.name"
              label="Name"
              :rules="[v => !!v || 'Name is required']"
              required
            ></v-text-field>

            <v-select
              v-model="editedItem.seller"
              :items="sellers"
              item-title="name"
              :item-value="item => item"
              label="Seller"
              :rules="[v => !!v || 'Seller is required']"
              required
            ></v-select>

            <v-select
              v-model="editedItem.category"
              :items="categoryOptions"
              label="Category"
              :rules="[v => !!v || 'Category is required']"
              required
              @update:model-value="updateSubcategoryOptions"
            ></v-select>

            <v-select
              v-model="editedItem.subcategory"
              :items="subcategoryOptions"
              label="Subcategory"
            ></v-select>

            <v-textarea
              v-model="editedItem.description"
              label="Description"
            ></v-textarea>

            <v-switch
              v-model="editedItem.weightAdjusted"
              label="Weight Adjusted"
            ></v-switch>

            <template v-if="editedItem.weightAdjusted">
              <v-text-field
                v-model="editedItem.weightMeasure"
                label="Weight Measure (e.g., kg, lb)"
                :rules="[v => !!v || 'Weight measure is required']"
                required
              ></v-text-field>

              <v-text-field
                v-model.number="editedItem.pricePerMeasure"
                label="Price Per Measure"
                type="number"
                :rules="[v => !!v || 'Price Per Measure is required']"
                required
              ></v-text-field>

              <v-text-field
                v-model.number="editedItem.averageWeight"
                label="Average Weight"
                type="number"
                :rules="[v => !!v || 'Average weight is required']"
                required
              ></v-text-field>

              <v-text-field
                v-model.number="editedItem.price"
                label="Calculated Price"
                type="number"
                disabled
              ></v-text-field>
            </template>
            <template v-else>
              <v-text-field
                v-model.number="editedItem.price"
                label="Price"
                type="number"
                :rules="[v => !!v || 'Price is required']"
                required
              ></v-text-field>
            </template>

            <v-subheader class="mt-4">Bulk Pricing Grid</v-subheader>
            <v-btn 
              small 
              color="secondary" 
              @click="addBulkPricing" 
              class="mb-2"
            >
              Add Bulk Price Tier
            </v-btn>
            
            <v-row 
              v-for="(pricing, index) in editedItem.bulkPriceGrid" 
              :key="index" 
              class="mb-2"
              align="center"
            >
              <v-col cols="3">
                <v-text-field
                  v-model.number="pricing.minQuantity"
                  label="Minimum Quantity"
                  type="number"
                  :rules="[
                    v => v > 0 || 'Quantity must be greater than 0',
                    v => !hasDuplicateMinQuantity(v, index) || 'Duplicate minimum quantity'
                  ]"
                  min="1"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="pricing.discountPerUnit"
                  :label="editedItem.weightAdjusted ? 'Discount Per Unit (per measure)' : 'Discount Per Unit'"
                  type="number"
                  :rules="[
                    v => v >= 0 || 'Discount must be non-negative',
                    v => validateDiscount(v) || 'Discount too high'
                  ]"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">
                  Final Price: ${{ formatPrice(calculateBulkFinalPrice(pricing)) }}
                  <div class="text-caption text-medium-emphasis">
                    When buying {{ pricing.minQuantity }}+ units
                  </div>
                </div>
              </v-col>
              <v-col cols="2">
                <v-btn 
                  small 
                  color="error" 
                  @click="removeBulkPricing(index)"
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-combobox
              v-model="editedItem.specifications"
              label="Specifications"
              multiple
              chips
              small-chips
            ></v-combobox>

            <v-select
              v-model="editedItem.deliveryOptions"
              :items="availableDeliveryOptions"
              label="Delivery Options"
              multiple
              chips
              small-chips
            ></v-select>

            <v-text-field
              v-model.number="editedItem.availableQuantity"
              label="Available Quantity"
              type="number"
            ></v-text-field>

            <v-file-input
              v-model="productImage"
              label="Product Image"
              accept="image/*"
              @change="onFileChange"
            ></v-file-input>

            <v-img v-if="imagePreview" :src="imagePreview" max-width="200" max-height="200" class="mt-2"></v-img>
            <v-btn v-if="imagePreview" color="error" @click="removeProductImage">Remove Image</v-btn>
            
            <v-subheader>Other Images</v-subheader>
            <v-btn small color="primary" @click="addOtherImageInput" class="mb-2">+ Add Image</v-btn>
            <div v-for="(image, index) in otherImages" :key="index" class="mb-2">
              <v-file-input
                v-model="otherImages[index]"
                label="Other Image"
                accept="image/*"
                @change="(event) => onOtherImageChange(event, index)"
              ></v-file-input>
              <v-img v-if="otherImagesPreview[index]" :src="otherImagesPreview[index]" max-width="100" max-height="100"></v-img>
              <v-btn small color="error" @click="removeOtherImage(index)">Remove</v-btn>
            </div>

            <v-subheader>Existing Other Images</v-subheader>
            <div v-for="(imageUrl, index) in editedItem.otherImages" :key="'existing-'+index" class="mb-2">
              <v-img :src="imageUrl" max-width="100" max-height="100"></v-img>
              <v-btn small color="error" @click="removeExistingOtherImage(index)">Remove</v-btn>
            </div>

            <v-switch v-model="editedItem.needsRefrigeration" label="Needs Refrigeration"></v-switch>
            <v-switch v-model="editedItem.needsFreezing" label="Needs Freezing"></v-switch>
            <v-switch v-model="editedItem.halal" label="Halal"></v-switch>
            <v-switch v-model="editedItem.certifiedOrganic" label="Certified Organic"></v-switch>
            <v-switch v-model="editedItem.isActive" label="Is Active"></v-switch>
            
            <v-subheader>Options</v-subheader>
            <v-btn small color="secondary" @click="addOption" class="mb-2">Add Option</v-btn>
            <v-row v-for="(option, index) in editedItem.options" :key="index" class="mb-2">
              <v-col cols="3">
                <v-text-field v-model="option.option" label="Option"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="option.optionDescription" label="Description"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field 
                  v-model.number="option.priceAdjustment" 
                  :label="editedItem.weightAdjusted ? 'Weighted Price Adjustment' : 'Price Adjustment'" 
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn small color="error" @click="removeOption(index)">Remove</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AdminProductsView',
  setup() {
    const store = useStore();
    const dialog = ref(false);
    const headers = [
      { title: 'Image', key: 'productImage', sortable: false },
      { title: 'Name', key: 'name' },
      { title: 'Seller', key: 'seller' },
      { title: 'Category', key: 'category' },
      { title: 'Subcategory', key: 'subcategory' },
      { title: 'Price', key: 'price' },
      { title: 'Available Quantity', key: 'availableQuantity' },
      { title: 'Is Active', key: 'isActive' },
      { title: 'Actions', key: 'actions', sortable: false }
    ];

    const editedIndex = ref(-1);
    const editedItem = ref({
      name: '',
      category: '',
      subcategory: '',
      description: '',
      price: 0,
      weightAdjusted: false,
      weightMeasure: '',
      pricePerMeasure: 0,
      averageWeight: null,
      specifications: [],
      seller: null,
      deliveryOptions: [],
      availableQuantity: 0,
      productImage: '',
      otherImages: [],
      halal: false,
      certifiedOrganic: false,
      bulkPriceGrid: [],
      needsRefrigeration: false,
      needsFreezing: false,
      options: [],
      isActive: true
    });

    const defaultItem = { ...editedItem.value };
    const productImage = ref(null);
    const imagePreview = ref('');
    const otherImages = ref([]);
    const otherImagesPreview = ref([]);
    const imageToDelete = ref(null);
    const otherImagesToDelete = ref([]);

    const formTitle = computed(() => editedIndex.value === -1 ? 'New Product' : 'Edit Product');
    const allProducts = computed(() => store.getters['product/allProducts'] || []);
    const sellers = computed(() => store.state.sellers);
    const categories = computed(() => store.state.category.categories);

    const getSellerName = (seller) => {
      return seller ? seller.name : 'Unknown Seller';
    };

    const availableDeliveryOptions = computed(() => {
      const selectedSeller = editedItem.value.seller;
      if (selectedSeller) {
        const options = [];
        if (selectedSeller.pickupAvailable) options.push('Pickup');
        if (selectedSeller.deliveryAvailable) options.push('Delivery');
        return options;
      }
      return [];
    });

    const categoryOptions = computed(() => {
      return categories.value.map(category => category.name);
    });

    const subcategoryOptions = ref([]);

    const formatPrice = (price) => {
      return Number(price).toFixed(2);
    };

    const validateDiscount = (discount) => {
      const basePrice = editedItem.value.weightAdjusted 
        ? editedItem.value.pricePerMeasure 
        : editedItem.value.price;
      return discount < basePrice;
    };

    const updateSubcategoryOptions = () => {
      const selectedCategory = categories.value.find(c => c.name === editedItem.value.category);
      subcategoryOptions.value = selectedCategory ? selectedCategory.subcategories : [];
    };

    const openDialog = () => {
      editedIndex.value = -1;
      editedItem.value = { ...defaultItem };
      productImage.value = null;
      imagePreview.value = '';
      otherImages.value = [];
      otherImagesPreview.value = [];
      imageToDelete.value = null;
      otherImagesToDelete.value = [];
      dialog.value = true;
    };

    const editItem = (item) => {
      editedIndex.value = allProducts.value.indexOf(item);
      editedItem.value = {
        ...defaultItem,
        ...item,
        seller: item.seller,
        bulkPriceGrid: item.bulkPriceGrid || []
      };
      imagePreview.value = editedItem.value.productImage;
      otherImages.value = [];
      otherImagesPreview.value = [];
      imageToDelete.value = null;
      otherImagesToDelete.value = [];
      updateSubcategoryOptions();
      dialog.value = true;
    };

    const deleteItem = async (item) => {
      if (confirm('Are you sure you want to delete this item?')) {
        try {
          await store.dispatch('product/deleteProduct', item._id);
          await store.dispatch('product/fetchProducts');
        } catch (error) {
          console.error('Error deleting product:', error);
        }
      }
    };

    const close = () => {
      dialog.value = false;
      editedItem.value = { ...defaultItem };
      editedIndex.value = -1;
      productImage.value = null;
      imagePreview.value = '';
      otherImages.value = [];
      otherImagesPreview.value = [];
      imageToDelete.value = null;
      otherImagesToDelete.value = [];
    };

    const addBulkPricing = () => {
      if (!editedItem.value.bulkPriceGrid) {
        editedItem.value.bulkPriceGrid = [];
      }
      editedItem.value.bulkPriceGrid.push({
        minQuantity: 1,
        discountPerUnit: 0
      });
    };

    const removeBulkPricing = (index) => {
      editedItem.value.bulkPriceGrid.splice(index, 1);
    };

    const calculateBulkFinalPrice = (pricing) => {
      if (!pricing.minQuantity) return '0.00';
      
      const basePrice = editedItem.value.weightAdjusted
        ? editedItem.value.pricePerMeasure
        : editedItem.value.price;
        
      const discountedPrice = basePrice - pricing.discountPerUnit;
      const finalPrice = editedItem.value.weightAdjusted
        ? discountedPrice * editedItem.value.averageWeight * pricing.minQuantity
        : discountedPrice * pricing.minQuantity;
        
      return formatPrice(finalPrice);
    };

    const hasDuplicateMinQuantity = (quantity, currentIndex) => {
      return editedItem.value.bulkPriceGrid.some(
        (pricing, index) => 
          index !== currentIndex && 
          pricing.minQuantity === quantity
      );
    };

    const save = async () => {
      try {
        const formData = new FormData();
        
        // Sort bulk price grid by minimum quantity
        if (editedItem.value.bulkPriceGrid) {
          editedItem.value.bulkPriceGrid.sort((a, b) => a.minQuantity - b.minQuantity);
        }
        
        formData.append('product', JSON.stringify(editedItem.value));
        
        if (productImage.value) {
          formData.append('image', productImage.value);
        }
        
        otherImages.value.forEach((image) => {
          if (image) {
            formData.append('otherImages', image);
          }
        });
        
        if (imageToDelete.value) {
          formData.append('imageToDelete', imageToDelete.value);
        }
        
        if (otherImagesToDelete.value.length > 0) {
          formData.append('otherImagesToDelete', JSON.stringify(otherImagesToDelete.value));
        }

        if (editedIndex.value > -1) {
          await store.dispatch('product/updateProduct', { 
            productId: editedItem.value._id, 
            productData: formData 
          });
        } else {
          await store.dispatch('product/createProduct', formData);
        }
        
        close();
        await store.dispatch('product/fetchProducts');
      } catch (error) {
        console.error('Error saving product:', error);
      }
    };

    const onFileChange = (event) => {
      const file = event?.target?.files?.[0];
      if (file) {
        productImage.value = file;
        imagePreview.value = URL.createObjectURL(file);
      } else {
        productImage.value = null;
        imagePreview.value = '';
      }
    };

    const removeProductImage = () => {
      if (editedItem.value.productImage) {
        imageToDelete.value = editedItem.value.productImage;
      }
      productImage.value = null;
      imagePreview.value = '';
      editedItem.value.productImage = '';
    };

    const addOtherImageInput = () => {
      otherImages.value.push(null);
      otherImagesPreview.value.push(null);
    };

    const onOtherImageChange = (event, index) => {
      const file = event?.target?.files?.[0];
      if (file) {
        otherImages.value[index] = file;
        otherImagesPreview.value[index] = URL.createObjectURL(file);
      } else {
        otherImages.value[index] = null;
        otherImagesPreview.value[index] = null;
      }
    };

    const removeOtherImage = (index) => {
      otherImages.value.splice(index, 1);
      otherImagesPreview.value.splice(index, 1);
    };

    const removeExistingOtherImage = (index) => {
      otherImagesToDelete.value.push(editedItem.value.otherImages[index]);
      editedItem.value.otherImages.splice(index, 1);
    };

    const addOption = () => {
      editedItem.value.options.push({
        option: '',
        optionDescription: '',
        priceAdjustment: 0,
        weightedPriceAdjustment: 1
      });
    };

    const removeOption = (index) => {
      editedItem.value.options.splice(index, 1);
    };

    const updateProductStatus = async (item) => {
      try {
        const formData = new FormData();
        formData.append('product', JSON.stringify({ isActive: item.isActive }));

        await store.dispatch('product/updateProduct', {
          productId: item._id,
          productData: formData
        });
      } catch (error) {
        console.error('Error updating product status:', error);
        item.isActive = !item.isActive;
      }
    };

    watch(() => editedItem.value.weightAdjusted, (newValue) => {
      if (newValue) {
        editedItem.value.price = editedItem.value.pricePerMeasure * editedItem.value.averageWeight;
      }
    });

    watch(() => [editedItem.value.pricePerMeasure, editedItem.value.averageWeight], ([newPrice, newWeight]) => {
      if (editedItem.value.weightAdjusted) {
        editedItem.value.price = newPrice * newWeight;
      }
    });

    watch(() => editedItem.value.category, () => {
      updateSubcategoryOptions();
    });

    onMounted(async () => {
      try {
        await store.dispatch('product/init');
        await store.dispatch('product/fetchProducts', { 
          page: 1,
          filters: {} 
        });
        await store.dispatch('fetchSellers');
        await store.dispatch('category/fetchCategories');
      } catch (error) {
        console.error('Error in component initialization:', error);
      }
    });

    return {
      dialog,
      headers,
      editedIndex,
      editedItem,
      formTitle,
      allProducts,
      sellers,
      availableDeliveryOptions,
      productImage,
      imagePreview,
      otherImages,
      otherImagesPreview,
      imageToDelete,
      otherImagesToDelete,
      openDialog,
      editItem,
      deleteItem,
      close,
      save,
      onFileChange,
      removeProductImage,
      addOtherImageInput,
      onOtherImageChange,
      removeOtherImage,
      removeExistingOtherImage,
      addOption,
      removeOption,
      updateProductStatus,
      categoryOptions,
      subcategoryOptions,
      updateSubcategoryOptions,
      getSellerName,
      formatPrice,
      addBulkPricing,
      removeBulkPricing,
      calculateBulkFinalPrice,
      hasDuplicateMinQuantity,
      validateDiscount
    };
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}

:deep(.v-data-table) {
  background-color: transparent;
}
</style>