// ./frontend/src/store/modules/category.js

import axiosInstance from '../../utils/axios';

/**
 * Default stats object structure to ensure consistency
 */
const DEFAULT_STATS = {
  productCount: 0,
  totalViews: 0,
  lastViewed: null
};

/**
 * Default category structure with all required fields
 */
const DEFAULT_CATEGORY = {
  name: '',
  section: '',
  url: '',
  description: '',
  isActive: true,
  displayOrder: 0,
  subcategories: [],
  meta: {
    title: '',
    description: '',
    keywords: []
  },
  stats: { ...DEFAULT_STATS }
};

export const state = {
  categories: [],
  categoryTree: [],
  loading: false,
  error: null
};

export const mutations = {
  SET_LOADING(state, status) {
    state.loading = status;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_CATEGORIES(state, categories) {
    // Normalize each category to ensure required fields exist
    state.categories = categories.map(category => ({
      ...DEFAULT_CATEGORY,
      ...category,
      meta: {
        ...DEFAULT_CATEGORY.meta,
        ...(category.meta || {})
      },
      stats: {
        ...DEFAULT_STATS,
        ...(category.stats || {})
      }
    }));
  },

  SET_CATEGORY_TREE(state, tree) {
    state.categoryTree = tree;
  },

  ADD_CATEGORY(state, category) {
    // Normalize new category before adding to state
    const normalizedCategory = {
      ...DEFAULT_CATEGORY,
      ...category,
      meta: {
        ...DEFAULT_CATEGORY.meta,
        ...(category.meta || {})
      },
      stats: {
        ...DEFAULT_STATS,
        ...(category.stats || {})
      }
    };
    state.categories.push(normalizedCategory);
  },

  UPDATE_CATEGORY(state, updatedCategory) {
    const index = state.categories.findIndex(c => c._id === updatedCategory._id);
    if (index !== -1) {
      // Normalize updated category
      const normalizedCategory = {
        ...DEFAULT_CATEGORY,
        ...updatedCategory,
        meta: {
          ...DEFAULT_CATEGORY.meta,
          ...(updatedCategory.meta || {})
        },
        stats: {
          ...DEFAULT_STATS,
          ...(updatedCategory.stats || {})
        }
      };
      state.categories.splice(index, 1, normalizedCategory);
    }
  },

  REMOVE_CATEGORY(state, categoryId) {
    state.categories = state.categories.filter(c => c._id !== categoryId);
  }
};

export const actions = {
  async fetchCategories({ commit }) {
    try {
      commit('SET_LOADING', true);
      const response = await axiosInstance.get('/api/categories');
      commit('SET_CATEGORIES', response.data);
      return response.data;
    } catch (error) {
      console.error('[Category Store] Error fetching categories:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error fetching categories');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchCategoryTree({ commit }) {
    try {
      commit('SET_LOADING', true);
      const response = await axiosInstance.get('/api/categories/tree');
      commit('SET_CATEGORY_TREE', response.data);
      return response.data;
    } catch (error) {
      console.error('[Category Store] Error fetching category tree:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error fetching category tree');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createCategory({ commit }, categoryData) {
    try {
      commit('SET_LOADING', true);
      const normalizedData = {
        ...DEFAULT_CATEGORY,
        ...categoryData,
        meta: {
          ...DEFAULT_CATEGORY.meta,
          ...(categoryData.meta || {})
        },
        stats: {
          ...DEFAULT_STATS,
          ...(categoryData.stats || {})
        }
      };
      const response = await axiosInstance.post('/api/categories', normalizedData);
      commit('ADD_CATEGORY', response.data);
      return response.data;
    } catch (error) {
      console.error('[Category Store] Error creating category:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error creating category');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateCategory({ commit }, categoryData) {
    try {
      commit('SET_LOADING', true);
      
      if (!categoryData.name || !categoryData.section || !categoryData.url) {
        throw new Error('Name, section, and URL are required fields');
      }

      const updateData = {
        name: categoryData.name.trim(),
        url: categoryData.url.toLowerCase().trim(),
        section: categoryData.section.trim(),
        description: categoryData.description?.trim() || '',
        isActive: Boolean(categoryData.isActive),
        displayOrder: parseInt(categoryData.displayOrder) || 0,
        subcategories: categoryData.subcategories
          .filter(sub => sub.name && sub.url)
          .map(sub => ({
            name: sub.name.trim(),
            url: sub.url.toLowerCase().trim(),
            description: sub.description?.trim() || '',
            isActive: Boolean(sub.isActive)
          })),
        meta: {
          title: (categoryData.meta?.title || categoryData.name).trim(),
          description: categoryData.meta?.description?.trim() || '',
          keywords: Array.isArray(categoryData.meta?.keywords) 
            ? categoryData.meta.keywords.map(k => k.trim()).filter(Boolean)
            : (categoryData.meta?.keywords || '').split(',').map(k => k.trim()).filter(Boolean)
        }
      };

      const response = await axiosInstance.put(`/api/categories/${categoryData._id}`, updateData);
      commit('UPDATE_CATEGORY', response.data);
      return response.data;
    } catch (error) {
      console.error('[Category Store] Error updating category:', error);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deleteCategory({ commit }, categoryId) {
    try {
      commit('SET_LOADING', true);
      await axiosInstance.delete(`/api/categories/${categoryId}`);
      commit('REMOVE_CATEGORY', categoryId);
    } catch (error) {
      console.error('[Category Store] Error deleting category:', error);
      commit('SET_ERROR', error.response?.data?.message || 'Error deleting category');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  }
};

export const getters = {
  getCategoryById: state => id => {
    const category = state.categories.find(c => c._id === id);
    if (!category) return null;
    
    return {
      ...DEFAULT_CATEGORY,
      ...category,
      meta: {
        ...DEFAULT_CATEGORY.meta,
        ...(category.meta || {})
      },
      stats: {
        ...DEFAULT_STATS,
        ...(category.stats || {})
      }
    };
  },

  getCategoryByUrl: state => url => {
    return state.categories.find(cat => cat.url === url);
  },

  getCategoryUrl: state => categoryName => {
    const category = state.categories.find(c => 
      c.name.toLowerCase() === categoryName.toLowerCase()
    );
    return category?.url || categoryName.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  },
  
  getCategoriesWithStats: state => {
    return state.categories.map(category => ({
      ...DEFAULT_CATEGORY,
      ...category,
      meta: {
        ...DEFAULT_CATEGORY.meta,
        ...(category.meta || {})
      },
      stats: {
        ...DEFAULT_STATS,
        ...(category.stats || {})
      }
    }));
  },

  getCategoryTree: state => {
    return state.categoryTree;
  },

  getCategoryToSectionMap: state => {
    const map = {};
    if (state.categoryTree) {
      state.categoryTree.forEach(section => {
        section.categories?.forEach(category => {
          map[category.name.toLowerCase()] = section.name;
        });
      });
    }
    return map;
  },

  getSubcategoryByUrl: state => subcategoryUrl => {
    const categoryWithSubcategory = state.categories.find(category => 
      category.subcategories.some(sub => sub.url === subcategoryUrl)
    );

    if (!categoryWithSubcategory) return null;

    const subcategory = categoryWithSubcategory.subcategories.find(
      sub => sub.url === subcategoryUrl
    );

    return {
      category: categoryWithSubcategory,
      subcategory
    };
  },

  // Optimized getters for search/filter functionality
  filteredSectionsByResults: (state, getters, rootState) => {
    const searchResults = rootState.search?.searchResults || [];
    if (!Array.isArray(searchResults) || !state.categoryTree) return [];
        
    const categoryToSectionMap = getters.getCategoryToSectionMap;
    const sections = new Set();
    
    searchResults.forEach(product => {
      if (product?.category) {
        const sectionName = categoryToSectionMap[product.category.toLowerCase()];
        if (sectionName) {
          sections.add(sectionName);
        }
      }
    });
    
    const resultArray = Array.from(sections);

    return resultArray;
  },

  filteredCategoriesBySection: (state, getters, rootState) => {
    const searchResults = rootState.search?.searchResults || [];
    const selectedSection = rootState.search?.categorySelection?.section;
    
    if (!Array.isArray(searchResults) || !state.categoryTree) return [];
        
    // Get categories for the selected section
    const sectionCategories = selectedSection === 'all'
      ? state.categoryTree.flatMap(section => section.categories || [])
      : state.categoryTree.find(s => 
          s.name.toLowerCase() === selectedSection.toLowerCase()
        )?.categories || [];
    
    // Create a Set of valid category names for the section
    const validCategories = new Set(
      sectionCategories.map(cat => cat.name.toLowerCase())
    );
    
    // Filter and collect matching categories from search results
    const categories = new Set();
    searchResults.forEach(product => {
      if (product?.category && 
          (selectedSection === 'all' || 
           validCategories.has(product.category.toLowerCase()))) {
        categories.add(product.category);
      }
    });
    
    const resultArray = Array.from(categories);
    return resultArray;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};