<!-- ./frontend/src/views/AdminMunicipalityPage.vue -->
<template>
  <v-container>
    <h1 class="text-h4 mb-4">Admin: Municipalities</h1>
    
    <v-row class="mb-4">
      <v-col>
        <v-btn color="primary" @click="openAddDialog">
          Add New Municipality
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search municipalities..."
              single-line
              hide-details
              density="comfortable"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="municipalities"
        :items-per-page="25"
        :page="page"
        :server-items-length="totalItems"
        :loading="loading"
        @update:page="handlePageChange"
        class="elevation-1"
      >
        <template #[`item.rsaCodes`]="{ item }">
          <div class="rsa-codes-wrapper">
            <div class="rsa-codes-grid">
              <v-chip
                v-for="(code, index) in item.rsaCodes"
                :key="index"
                size="small"
                color="primary"
                variant="outlined"
                class="rsa-code-chip"
              >
                {{ code }}
              </v-chip>
            </div>
          </div>
        </template>

        <template #[`item.geoLocation`]="{ item }">
          <span v-if="item.geoLocation && item.geoLocation.lat && item.geoLocation.lng">
            {{ item.geoLocation.lat.toFixed(6) }}, {{ item.geoLocation.lng.toFixed(6) }}
          </span>
          <span v-else class="text-grey">Not set</span>
        </template>

        <template #[`item.localShippingZone`]="{ item }">
          <v-chip
            v-if="item.localShippingZone"
            color="success"
            size="small"
          >
            {{ item.localShippingZone.name }}
          </v-chip>
          <span v-else class="text-grey">None</span>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-tooltip text="Edit">
            <template v-slot:activator="{ props }">
              <v-btn 
                icon 
                color="primary" 
                size="small"
                class="mr-2"
                v-bind="props"
                @click="editMunicipality(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip text="Delete">
            <template v-slot:activator="{ props }">
              <v-btn 
                icon 
                color="error" 
                size="small"
                v-bind="props"
                @click="confirmDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <!-- Add/Edit Dialog -->
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" @submit.prevent="save">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Municipality Name"
                    :rules="[v => !!v || 'Name is required']"
                    required
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="editedItem.province"
                    label="Province"
                    :rules="[v => !!v || 'Province is required']"
                    required
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-combobox
                    v-model="editedItem.rsaCodes"
                    label="RSA Codes"
                    multiple
                    chips
                    closable-chips
                    :rules="[
                      v => v && v.length > 0 || 'At least one RSA code is required',
                      v => !v || v.every(code => /^[A-Z0-9]*$/.test(code.toUpperCase())) || 'RSA codes can only contain letters and numbers'
                    ]"
                    hint="Enter each RSA code and press Enter. Codes must be unique."
                    persistent-hint
                  ></v-combobox>
                </v-col>

                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model.number="editedItem.geoLocation.lat"
                        label="Latitude"
                        type="number"
                        step="0.000001"
                        :rules="[
                          v => !v || (v >= -90 && v <= 90) || 'Latitude must be between -90 and 90',
                          v => !v || !isNaN(v) || 'Must be a number'
                        ]"
                        hint="Enter latitude coordinate (-90 to 90)"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model.number="editedItem.geoLocation.lng"
                        label="Longitude"
                        type="number"
                        step="0.000001"
                        :rules="[
                          v => !v || (v >= -180 && v <= 180) || 'Longitude must be between -180 and 180',
                          v => !v || !isNaN(v) || 'Must be a number'
                        ]"
                        hint="Enter longitude coordinate (-180 to 180)"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editedItem.localShippingZone"
                    :items="localShippingZones"
                    item-title="name"
                    item-value="_id"
                    label="Local Shipping Zone"
                    clearable
                    :hint="localShippingZones.length ? '' : 'No shipping zones available'"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="grey-darken-1" 
            variant="text" 
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn 
            color="primary" 
            variant="text" 
            @click="save"
            :loading="saving"
            :disabled="saving"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Delete Municipality</v-card-title>
        <v-card-text>
          Are you sure you want to delete <strong>{{ itemToDelete?.name }}</strong>?
          <br>
          This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="grey-darken-1" 
            variant="text" 
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn 
            color="error" 
            variant="text" 
            @click="deleteMunicipality"
            :loading="deleting"
            :disabled="deleting"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { debounce } from 'lodash'

const store = useStore()
const dialog = ref(false)
const deleteDialog = ref(false)
const form = ref(null)
const search = ref('')
const page = ref(1)
const loading = ref(false)
const saving = ref(false)
const deleting = ref(false)
const totalItems = ref(0)
const municipalities = ref([])
const localShippingZones = ref([])

const snackbar = ref({
  show: false,
  text: '',
  color: 'success'
})

const headers = ref([
  { 
    title: 'Name',
    key: 'name',
    align: 'start',
    sortable: true,
    width: '20%'
  },
  { 
    title: 'Province',
    key: 'province',
    align: 'start',
    sortable: true,
    width: '15%'
  },
  {
    title: 'Location',
    key: 'geoLocation',
    align: 'start',
    sortable: false,
    width: '15%'
  },
  { 
    title: 'RSA Codes',
    key: 'rsaCodes',
    align: 'start',
    sortable: false,
    width: '20%'
  },
  { 
    title: 'Local Shipping Zone',
    key: 'localShippingZone',
    align: 'start',
    sortable: false,
    width: '20%'
  },
  { 
    title: 'Actions',
    key: 'actions',
    align: 'center',
    sortable: false,
    width: '10%'
  }
])

const defaultItem = {
  name: '',
  province: '',
  rsaCodes: [],
  geoLocation: {
    lat: null,
    lng: null
  },
  localShippingZone: null
}

const editedItem = ref({ ...defaultItem })
const editedIndex = ref(-1)
const itemToDelete = ref(null)

const formTitle = computed(() => {
  return editedIndex.value === -1 ? 'New Municipality' : 'Edit Municipality'
})

const validateGeoLocation = (location) => {
  if (!location) return true;
  
  const { lat, lng } = location;
  
  if (lat !== null && (isNaN(lat) || lat < -90 || lat > 90)) {
    return 'Invalid latitude';
  }
  
  if (lng !== null && (isNaN(lng) || lng < -180 || lng > 180)) {
    return 'Invalid longitude';
  }
  
  return true;
}

const showNotification = (text, color = 'success') => {
  snackbar.value = {
    show: true,
    text,
    color
  }
}

const fetchMunicipalities = async () => {
  console.log('Fetching municipalities:', {
    page: page.value,
    search: search.value
  })
  
  loading.value = true
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/municipalities`, {
      params: {
        page: page.value,
        limit: 25,
        search: search.value
      }
    })
    
    municipalities.value = response.data.municipalities
    totalItems.value = response.data.total
  } catch (error) {
    console.error('Error fetching municipalities:', error)
    showNotification('Error loading municipalities', 'error')
  } finally {
    loading.value = false
  }
}

const fetchLocalShippingZones = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/local-shipping-zones`)
    localShippingZones.value = response.data
  } catch (error) {
    console.error('Error fetching local shipping zones:', error)
    showNotification('Error loading shipping zones', 'error')
  }
}

const handlePageChange = (newPage) => {
  page.value = newPage
  fetchMunicipalities()
}

const editMunicipality = (item) => {
  editedIndex.value = municipalities.value.indexOf(item)
  editedItem.value = JSON.parse(JSON.stringify({
    ...item,
    geoLocation: item.geoLocation || { lat: null, lng: null }
  }))
  dialog.value = true
}

const openAddDialog = () => {
  editedItem.value = { ...defaultItem }
  editedIndex.value = -1
  dialog.value = true
}

const close = () => {
  dialog.value = false
  editedItem.value = { ...defaultItem }
  editedIndex.value = -1
  if (form.value) {
    form.value.resetValidation()
  }
}

const save = async () => {
  if (!form.value) return
  
  const { valid } = await form.value.validate()
  if (!valid) return

  const geoLocationValidation = validateGeoLocation(editedItem.value.geoLocation)
  if (geoLocationValidation !== true) {
    showNotification(geoLocationValidation, 'error')
    return
  }

  saving.value = true
  try {
    const municipalityData = {
      ...editedItem.value,
      rsaCodes: editedItem.value.rsaCodes.map(code => code.toUpperCase().trim()),
      geoLocation: editedItem.value.geoLocation.lat && editedItem.value.geoLocation.lng
        ? {
            lat: parseFloat(editedItem.value.geoLocation.lat),
            lng: parseFloat(editedItem.value.geoLocation.lng)
          }
        : null
    }

    if (editedIndex.value > -1) {
      await axios.put(
        `${process.env.VUE_APP_API_URL}/api/municipalities/${editedItem.value._id}`,
        municipalityData
      )
      showNotification('Municipality updated successfully')
    } else {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/api/municipalities`,
        municipalityData
      )
      showNotification('Municipality created successfully')
    }
    
    close()
    fetchMunicipalities()
  } catch (error) {
    console.error('Error saving municipality:', error)
    showNotification(error.response?.data?.message || 'Error saving municipality', 'error')
  } finally {
    saving.value = false
  }
}

const confirmDelete = (item) => {
  itemToDelete.value = item
  deleteDialog.value = true
}

const deleteMunicipality = async () => {
  deleting.value = true
  try {
    await axios.delete(`${process.env.VUE_APP_API_URL}/api/municipalities/${itemToDelete.value._id}`)
    deleteDialog.value = false
    showNotification('Municipality deleted successfully')
    fetchMunicipalities()
  } catch (error) {
    console.error('Error deleting municipality:', error)
    showNotification('Error deleting municipality', 'error')
  } finally {
    deleting.value = false
    itemToDelete.value = null
  }
}

// Create debounced search function
const debouncedSearch = debounce(() => {
  page.value = 1 // Reset to first page on new search
  fetchMunicipalities()
}, 300)

// Watch search input
watch(search, () => {
  debouncedSearch()
})

// Initialization
onMounted(() => {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token')
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  fetchMunicipalities()
  fetchLocalShippingZones()
})

// Cleanup
onUnmounted(() => {
  if (debouncedSearch.cancel) {
    debouncedSearch.cancel()
  }
})
</script>

<style scoped>
.v-data-table {
  margin-top: 1rem;
}

/* RSA Codes Grid Styling */
.rsa-codes-wrapper {
  max-width: 100%;
  padding: 4px 0;
}

.rsa-codes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 8px;
  align-items: center;
  max-width: 300px;
}

.rsa-code-chip {
  margin: 0 !important;
  justify-self: start;
  white-space: nowrap;
}

/* Table Cell Styling */
:deep(.v-data-table-rows-item td) {
  vertical-align: top !important;
  padding: 8px 16px !important;
}

/* Column Widths */
:deep(.v-data-table-header th:nth-child(1)) { width: 20%; } /* Name */
:deep(.v-data-table-header th:nth-child(2)) { width: 15%; } /* Province */
:deep(.v-data-table-header th:nth-child(3)) { width: 15%; } /* Location */
:deep(.v-data-table-header th:nth-child(4)) { width: 30%; } /* RSA Codes */
:deep(.v-data-table-header th:nth-child(5)) { width: 10%; } /* Shipping Zone */
:deep(.v-data-table-header th:nth-child(6)) { width: 10%; } /* Actions */

.cursor-pointer {
  cursor: pointer;
}

.v-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Dialog Form Styling */
.v-form {
  margin-top: 1rem;
}

.v-col {
  padding: 8px 12px;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .rsa-codes-grid {
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  }

  .v-chip {
    font-size: 0.75rem;
  }
}
</style>