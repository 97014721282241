<!-- ./frontend/src/views/SellerInfoPage.vue -->
<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <SellerAccountDrawer />
      </v-col>
      <v-col cols="9">
        <h1>Seller Information</h1>
        <v-form @submit.prevent="saveSeller">
          <v-text-field v-model="seller.name" label="Seller Name" required></v-text-field>

          <v-text-field
            v-model="seller.url"
            label="Seller Page URL"
            required
            @input="checkUrlAvailability"
            :rules="[v => !!v || 'URL is required']"
            :error-messages="urlErrorMessage"
          ></v-text-field>

          <v-text-field v-model="seller.type" label="Seller Type" required></v-text-field>
          <v-text-field v-model="seller.email" label="Email" required></v-text-field>
          <v-text-field v-model="seller.location.street" label="Street"></v-text-field>
          <v-text-field v-model="seller.location.unit" label="Unit"></v-text-field>
          <v-text-field v-model="seller.location.city" label="City"></v-text-field>
          <v-text-field v-model="seller.location.provinceTerritory" label="Province/Territory"></v-text-field>
          <v-text-field v-model="seller.location.postalCode" label="Postal Code"></v-text-field>
          <v-text-field v-model="seller.location.phoneNumber" label="Phone Number"></v-text-field>
          <v-switch v-model="seller.location.visible" label="Location Visible"></v-switch>
          <v-textarea v-model="seller.description" label="Description"></v-textarea>
          <v-combobox v-model="seller.bulkProducts" label="Bulk Products" multiple chips small-chips></v-combobox>
          <v-switch v-model="seller.pickupAvailable" label="Pickup Available"></v-switch>
          <v-switch v-model="seller.deliveryAvailable" label="Delivery Available"></v-switch>
          <v-switch v-model="seller.allowFarmTours" label="Allow Farm Tours"></v-switch>

          <v-subheader>Events</v-subheader>
          <v-btn small color="secondary" @click="addEvent" class="mb-2">Add Event</v-btn>
          <v-row v-for="(event, index) in seller.events" :key="index" class="mb-2">
            <v-col cols="4">
              <v-text-field v-model="event.name" label="Event Name"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-textarea v-model="event.description" label="Event Description"></v-textarea>
            </v-col>
            <v-col cols="3">
              <v-date-picker v-model="event.date" label="Event Date"></v-date-picker>
            </v-col>
            <v-col cols="1">
              <v-btn small color="error" @click="removeEvent(index)">Remove</v-btn>
            </v-col>
          </v-row>

          <v-subheader>Pickup Schedule</v-subheader>
          <v-btn small color="secondary" @click="addPickupSchedule" class="mb-2">Add Pickup Schedule</v-btn>
          <v-row v-for="(schedule, index) in seller.pickupSchedule" :key="index" class="mb-2">
            <v-col cols="3">
              <v-select v-model="schedule.pickupDay" :items="weekDays" label="Pickup Day"></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="schedule.pickupStartTime"
                :items="timeOptions"
                label="Start Time"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="schedule.pickupEndTime"
                :items="timeOptions"
                label="End Time"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="schedule.pickupLocation" label="Pickup Location"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-textarea v-model="schedule.pickupInstructions" label="Pickup Instructions"></v-textarea>
            </v-col>
            <v-col cols="1">
              <v-btn small color="error" @click="removePickupSchedule(index)">Remove</v-btn>
            </v-col>
          </v-row>

          <v-subheader>Delivery Schedule</v-subheader>
          <v-btn small color="secondary" @click="addDeliverySchedule" class="mb-2">Add Delivery Schedule</v-btn>
          <v-row v-for="(schedule, index) in seller.deliverySchedule" :key="index" class="mb-2">
            <v-col cols="2">
              <v-select v-model="schedule.dropOffDay" :items="weekDays" label="Drop-off Day"></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="schedule.dropOffStartTime"
                :items="timeOptions"
                label="Drop-off Start Time"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="schedule.dropOffEndTime"
                :items="timeOptions"
                label="Drop-off End Time"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="schedule.dropOffLocation" label="Drop-off Location"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="schedule.deliveryContactPhoneNumber" label="Contact Phone"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select v-model="schedule.readyForDeliveryDay" :items="weekDays" label="Ready for Delivery Day"></v-select>
            </v-col>
            <v-col cols="1">
              <v-btn small color="error" @click="removeDeliverySchedule(index)">Remove</v-btn>
            </v-col>
          </v-row>

          <v-subheader>Farm Tour Schedule</v-subheader>
          <v-btn small color="secondary" @click="addFarmTourSchedule" class="mb-2">Add Farm Tour Schedule</v-btn>
          <v-row v-for="(schedule, index) in seller.farmTourSchedule" :key="index" class="mb-2">
            <v-col cols="3">
              <v-date-picker v-model="schedule.farmTourDate" label="Farm Tour Date"></v-date-picker>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="schedule.startTime"
                :items="timeOptions"
                label="Start Time"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="schedule.endTime"
                :items="timeOptions"
                label="End Time"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="schedule.farmTourLocation" label="Farm Tour Location"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-textarea v-model="schedule.farmTourInstructions" label="Farm Tour Instructions"></v-textarea>
            </v-col>
            <v-col cols="1">
              <v-btn small color="error" @click="removeFarmTourSchedule(index)">Remove</v-btn>
            </v-col>
          </v-row>

          <v-file-input
            v-model="coverPhoto"
            label="Cover Photo"
            accept="image/*"
            @change="onCoverPhotoChange"
          ></v-file-input>
          <v-img v-if="coverPhotoPreview" :src="coverPhotoPreview" max-width="200" max-height="200" class="mt-2"></v-img>
          <v-btn v-if="coverPhotoPreview" color="error" @click="removeCoverPhoto">Remove Cover Photo</v-btn>

          <v-subheader>Other Images</v-subheader>
          <v-btn small color="secondary" @click="addOtherImageInput" class="mb-2">+ Add Image</v-btn>
          <div v-for="(image, index) in otherImages" :key="index" class="mb-2">
            <v-file-input
              v-model="otherImages[index]"
              label="Other Image"
              accept="image/*"
              @change="(event) => onOtherImageChange(event, index)"
            ></v-file-input>
            <v-img v-if="otherImagesPreview[index]" :src="otherImagesPreview[index]" max-width="100" max-height="100"></v-img>
            <v-btn small color="error" @click="removeOtherImage(index)">Remove</v-btn>
          </div>

          <v-subheader>Existing Other Images</v-subheader>
          <div v-for="(imageUrl, index) in seller.images" :key="'existing-'+index" class="mb-2">
            <v-img :src="imageUrl" max-width="100" max-height="100"></v-img>
            <v-btn small color="error" @click="removeExistingOtherImage(index)">Remove</v-btn>
          </div>

          <v-card class="mb-6">
            <v-card-title>Hero Section Settings</v-card-title>
            <v-card-text>
              <v-switch
                v-model="seller.heroColor"
                :label="`Hero text color: ${seller.heroColor}`"
                true-value="white"
                false-value="black"
              ></v-switch>
            </v-card-text>
          </v-card>

          <v-btn type="submit" color="primary">{{ sellerExists ? 'Update' : 'Save' }} Seller Information</v-btn>
        </v-form>

        <h2 class="mt-8">Your Products</h2>
        <v-data-table
          :headers="headers"
          :items="products"
          :items-per-page="10"
          class="elevation-1"
        >
          <template #[`item.productImage`]="{ item }">
            <v-img :src="item.productImage" max-width="100" max-height="100"></v-img>
          </template>
          <template #[`item.isActive`]="{ item }">
            <v-switch
              v-model="item.isActive"
              @change="updateProductStatus(item)"
            ></v-switch>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              class="text-none mr-2"
              color="primary"
              min-width="0"
              slim
              text="Edit"
              variant="text"
              @click="editProduct(item)"
            />
            <v-btn
              class="text-none"
              color="error"
              min-width="0"
              slim
              text="Delete"
              variant="text"
              @click="deleteProduct(item)"
            />
          </template>
        </v-data-table>

        <v-btn 
          color="primary" 
          class="mt-4" 
          @click="openAddProductDialog" 
          :disabled="!canAddProduct"
        >
          Add New Product
        </v-btn>

        <v-dialog v-model="productDialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="saveProduct" ref="form">
                <v-text-field v-model="editedProduct.name" label="Name" :rules="[v => !!v || 'Name is required']" required></v-text-field>
                <v-select
                  v-model="editedProduct.category"
                  :items="categoryOptions"
                  label="Category"
                  :rules="[v => !!v || 'Category is required']"
                  required
                  @update:model-value="updateSubcategoryOptions"
                ></v-select>
                <v-select
                  v-model="editedProduct.subcategory"
                  :items="subcategoryOptions"
                  label="Subcategory (Meat Type)"
                ></v-select>
                <v-textarea v-model="editedProduct.description" label="Description"></v-textarea>
                <v-text-field
                  v-model.number="editedProduct.price"
                  label="Price"
                  type="number"
                  :rules="[v => !!v || 'Price is required']"
                  required
                  :disabled="editedProduct.weightAdjusted"
                ></v-text-field>
                <v-switch v-model="editedProduct.weightAdjusted" label="Weight Adjusted"></v-switch>
                <v-text-field v-model="editedProduct.weightMeasure" label="Weight Measure"></v-text-field>
                <v-text-field
                  v-if="editedProduct.weightAdjusted"
                  v-model.number="editedProduct.pricePerMeasure"
                  label="Price Per Measure"
                  type="number"
                  :rules="[v => !!v || 'Price Per Measure is required']"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="editedProduct.weightAdjusted"
                  v-model.number="editedProduct.averageWeight"
                  label="Average Weight"
                  type="number"
                ></v-text-field>
                <v-combobox v-model="editedProduct.specifications" label="Specifications" multiple chips small-chips></v-combobox>
                <v-select
                  v-model="editedProduct.deliveryOptions"
                  :items="availableDeliveryOptions"
                  label="Delivery Options"
                  multiple
                  chips
                  small-chips
                ></v-select>
                <v-text-field
                  v-model.number="editedProduct.availableQuantity"
                  label="Available Quantity"
                  type="number"
                ></v-text-field>
                <v-file-input
                  v-model="productImage"
                  label="Product Image"
                  accept="image/*"
                  @change="onFileChange"
                ></v-file-input>
                <v-img v-if="imagePreview" :src="imagePreview" max-width="200" max-height="200" class="mt-2"></v-img>
                <v-btn v-if="imagePreview" color="error" @click="removeProductImage">Remove Image</v-btn>
                
                <v-subheader>Other Images</v-subheader>
                <v-btn small color="primary" @click="addOtherImageInput" class="mb-2">+ Add Image</v-btn>
                <div v-for="(image, index) in otherImages" :key="index" class="mb-2">
                  <v-file-input
                    v-model="otherImages[index]"
                    label="Other Image"
                    accept="image/*"
                    @change="(event) => onOtherImageChange(event, index)"
                  ></v-file-input>
                  <v-img v-if="otherImagesPreview[index]" :src="otherImagesPreview[index]" max-width="100" max-height="100"></v-img>
                  <v-btn small color="error" @click="removeOtherImage(index)">Remove</v-btn>
                </div>

                <v-subheader>Existing Other Images</v-subheader>
                <div v-for="(imageUrl, index) in editedProduct.otherImages" :key="'existing-'+index" class="mb-2">
                  <v-img :src="imageUrl" max-width="100" max-height="100"></v-img>
                  <v-btn small color="error" @click="removeExistingOtherImage(index)">Remove</v-btn>
                </div>

                <v-switch v-model="editedProduct.halal" label="Halal"></v-switch>
                
                <v-switch v-model="editedProduct.isActive" label="Is Active"></v-switch>
                
                <v-subheader>Options</v-subheader>
                <v-btn small color="secondary" @click="addOption" class="mb-2">Add Option</v-btn>
                <v-row v-for="(option, index) in editedProduct.options" :key="index" class="mb-2">
                  <v-col cols="3">
                    <v-text-field v-model="option.option" label="Option"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model="option.optionDescription" label="Description"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field 
                      v-model.number="option.priceAdjustment" 
                      :label="editedProduct.weightAdjusted ? 'Weighted Price Adjustment' : 'Price Adjustment'" 
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn small color="error" @click="removeOption(index)">Remove</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeProductDialog">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveProduct">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axiosInstance from '@/utils/axios';
import SellerAccountDrawer from '@/components/SellerAccountDrawer.vue';

export default {
  name: 'SellerInfoPage',
  components: {
    SellerAccountDrawer,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const seller = ref({
      name: '',
      url: '',
      type: '',
      email: '',
      location: {
        street: '',
        unit: '',
        city: '',
        provinceTerritory: '',
        postalCode: '',
        phoneNumber: '',
        visible: true
      },
      description: '',
      bulkProducts: [],
      pickupAvailable: false,
      deliveryAvailable: false,
      allowFarmTours: false,
      events: [],
      pickupSchedule: [],
      deliverySchedule: [],
      farmTourSchedule: [],
      images: [],
      heroColor: 'white',
      previewImage: '',
      customerPreviewImage: '',
    });

    const user = ref(null);
    const products = ref([]);
    const productDialog = ref(false);
    const editedIndex = ref(-1);
    const editedProduct = ref({
      name: '',
      category: '',
      subcategory: '',
      description: '',
      price: 0,
      weightAdjusted: false,
      weightMeasure: '',
      pricePerMeasure: 0,
      averageWeight: 0,
      specifications: [],
      deliveryOptions: [],
      availableQuantity: 0,
      halal: false,
      options: [],
      isActive: true
    });

    const headers = [
      { title: 'Image', key: 'productImage', sortable: false },
      { title: 'Name', key: 'name' },
      { title: 'Category', key: 'category' },
      { title: 'Subcategory', key: 'subcategory' },
      { title: 'Price', key: 'price' },
      { title: 'Available Quantity', key: 'availableQuantity' },
      { title: 'Is Active', key: 'isActive' },
      { title: 'Actions', key: 'actions', sortable: false }
    ];

    const sellerExists = ref(false);
    const urlErrorMessage = ref('');
    const canAddProduct = computed(() => !!user.value?.sellerId);

    const formTitle = computed(() => editedIndex.value === -1 ? 'New Product' : 'Edit Product');

    const coverPhoto = ref(null);
    const coverPhotoPreview = ref('');
    const otherImages = ref([]);
    const otherImagesPreview = ref([]);
    const imageToDelete = ref(null);
    const otherImagesToDelete = ref([]);

    const categoryOptions = ref([]);
    const subcategoryOptions = ref([]);
    const availableDeliveryOptions = computed(() => {
      const options = [];
      if (seller.value.pickupAvailable) options.push('Pickup');
      if (seller.value.deliveryAvailable) options.push('Delivery');
      return options;
    });

    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const timeOptions = computed(() => {
      const options = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
          options.push(timeString);
        }
      }
      return options;
    });

    const snackbar = ref({
      show: false,
      text: '',
      color: 'success',
      timeout: 3000
    });

    const saveSeller = async () => {
      try {
        const formData = new FormData();
        formData.append('seller', JSON.stringify(seller.value));
    
        if (coverPhoto.value) {
          formData.append('coverPhoto', coverPhoto.value);
        }
        otherImages.value.forEach((image, index) => {
          if (image) {
            formData.append(`otherImages`, image);
          }
        });
    
        if (imageToDelete.value) {
          formData.append('imageToDelete', imageToDelete.value);
        }
        if (otherImagesToDelete.value.length > 0) {
          formData.append('otherImagesToDelete', JSON.stringify(otherImagesToDelete.value));
        }

        if (sellerExists.value) {
          await store.dispatch('updateSeller', { id: user.value.sellerId, formData });
          snackbar.value = {
            show: true,
            text: 'Seller information updated successfully',
            color: 'success',
            timeout: 3000
          };
        } else {
          const createdSeller = await store.dispatch('createSeller', formData);
          sellerExists.value = true;
          seller.value = createdSeller;
          snackbar.value = {
            show: true,
            text: 'Seller account creation is pending review. You will be contacted within 24 hours to finalize the account setup process.',
            color: 'info',
            timeout: 5000
          };
        }
    
        // Reset deleted image trackers
        imageToDelete.value = null;
        otherImagesToDelete.value = [];

        await fetchSellerInfo();
      } catch (error) {
        console.error('Error saving seller information:', error);
        snackbar.value = {
          show: true,
          text: 'Error saving seller information',
          color: 'error',
          timeout: 3000
        };
      }
    };

    const fetchSellerInfo = async () => {
      try {
        user.value = await store.dispatch('fetchUserDetails');
        if (user.value.isSeller && user.value.sellerId) {
          const sellerInfo = await store.dispatch('fetchSellerInfoById', user.value.sellerId);
          seller.value = sellerInfo;
          products.value = await store.dispatch('fetchProductsBySeller', user.value.sellerId);
          sellerExists.value = true;
        } else if (user.value.email) {
          seller.value.email = user.value.email;
        }
      } catch (error) {
        console.error('Error fetching seller information:', error);
      }
    };

    const checkUrlAvailability = async () => {
      if (seller.value.url) {
        try {
          const response = await axiosInstance.get(`/api/sellers/check-url/${seller.value.url}`);
          if (response.data.available) {
            urlErrorMessage.value = '';
          } else {
            urlErrorMessage.value = 'This URL is already taken. Please choose another.';
          }
        } catch (error) {
          console.error('Error checking URL availability:', error);
          urlErrorMessage.value = 'Error checking URL availability. Please try again.';
        }
      } else {
        urlErrorMessage.value = '';
      }
    };

    const openAddProductDialog = () => {
      editedIndex.value = -1;
      editedProduct.value = {
        name: '',
        category: '',
        subcategory: '',
        description: '',
        price: 0,
        weightAdjusted: false,
        weightMeasure: '',
        pricePerMeasure: 0,
        averageWeight: 0,
        specifications: [],
        deliveryOptions: [],
        availableQuantity: 0,
        halal: false,
        options: [],
        isActive: true
      };
      productDialog.value = true;
    };

    const editProduct = (item) => {
      editedIndex.value = products.value.indexOf(item);
      editedProduct.value = JSON.parse(JSON.stringify(item));
      productDialog.value = true;
    };

    const closeProductDialog = () => {
      productDialog.value = false;
      editedProduct.value = {
        name: '',
        category: '',
        subcategory: '',
        description: '',
        price: 0,
        weightAdjusted: false,
        weightMeasure: '',
        pricePerMeasure: 0,
        averageWeight: 0,
        specifications: [],
        deliveryOptions: [],
        availableQuantity: 0,
        halal: false,
        options: [],
        isActive: true
      };
      editedIndex.value = -1;
    };

    const saveProduct = async () => {
      try {
        const formData = new FormData();
        formData.append('product', JSON.stringify(editedProduct.value));
        
        if (coverPhoto.value) {
          formData.append('image', coverPhoto.value);
        }
        otherImages.value.forEach((image, index) => {
          if (image) {
            formData.append(`otherImages`, image);
          }
        });

        if (editedIndex.value > -1) {
          formData.append('_id', editedProduct.value._id);
          await store.dispatch('updateProduct', formData);
        } else {
          formData.append('seller', JSON.stringify({ sellerId: user.value.sellerId }));
          await store.dispatch('createProduct', formData);
        }
        closeProductDialog();
        await fetchSellerInfo();
      } catch (error) {
        console.error('Error saving product:', error);
      }
    };

    const deleteProduct = async (item) => {
      if (confirm('Are you sure you want to delete this product?')) {
        try {
          await store.dispatch('deleteProduct', item._id);
          await fetchSellerInfo();
        } catch (error) {
          console.error('Error deleting product:', error);
        }
      }
    };

    const updateProductStatus = async (item) => {
      try {
        const productData = new FormData();
        productData.append('_id', item._id);
        productData.append('product', JSON.stringify({ isActive: item.isActive }));

        await store.dispatch('updateProduct', productData);
      } catch (error) {
        console.error('Error updating product status:', error);
        item.isActive = !item.isActive; // Revert the switch if the update failed
      }
    };

    const onCoverPhotoChange = (event) => {
      if (event && event.target && event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        coverPhoto.value = file;
        coverPhotoPreview.value = URL.createObjectURL(file);
      } else {
        coverPhoto.value = null;
        coverPhotoPreview.value = '';
      }
    };

    const removeCoverPhoto = () => {
      if (seller.value.coverPhoto) {
        imageToDelete.value = seller.value.coverPhoto;
      }
      coverPhoto.value = null;
      coverPhotoPreview.value = '';
      seller.value.coverPhoto = '';
    };

    const addOtherImageInput = () => {
      otherImages.value.push(null);
      otherImagesPreview.value.push(null);
    };

    const onOtherImageChange = (event, index) => {
      if (event && event.target && event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        otherImages.value[index] = file;
        otherImagesPreview.value[index] = URL.createObjectURL(file);
      } else {
        otherImages.value[index] = null;
        otherImagesPreview.value[index] = null;
      }
    };

    const removeOtherImage = (index) => {
      otherImages.value.splice(index, 1);
      otherImagesPreview.value.splice(index, 1);
    };

    const removeExistingOtherImage = (index) => {
      otherImagesToDelete.value.push(seller.value.images[index]);
      seller.value.images.splice(index, 1);
    };

    const addEvent = () => {
      seller.value.events.push({ name: '', description: '', date: null });
    };

    const removeEvent = (index) => {
      seller.value.events.splice(index, 1);
    };

    const addPickupSchedule = () => {
      seller.value.pickupSchedule.push({ 
        pickupDay: '', 
        pickupStartTime: '', 
        pickupEndTime: '', 
        pickupLocation: '', 
        pickupInstructions: '' 
      });
    };

    const removePickupSchedule = (index) => {
      seller.value.pickupSchedule.splice(index, 1);
    };

    const addDeliverySchedule = () => {
      seller.value.deliverySchedule.push({
        dropOffDay: '',
        dropOffStartTime: '',
        dropOffEndTime: '',
        dropOffLocation: '',
        deliveryContactPhoneNumber: '',
        readyForDeliveryDay: ''
      });
    };

    const removeDeliverySchedule = (index) => {
      seller.value.deliverySchedule.splice(index, 1);
    };

    const addFarmTourSchedule = () => {
      seller.value.farmTourSchedule.push({
        farmTourDate: null,
        startTime: '',
        endTime: '',
        farmTourLocation: '',
        farmTourInstructions: ''
      });
    };

    const removeFarmTourSchedule = (index) => {
      seller.value.farmTourSchedule.splice(index, 1);
    };

    const addOption = () => {
      editedProduct.value.options.push({
        option: '',
        optionDescription: '',
        priceAdjustment: 0,
        weightedPriceAdjustment: 1
      });
    };

    const removeOption = (index) => {
      editedProduct.value.options.splice(index, 1);
    };

    const updateSubcategoryOptions = async () => {
      try {
        const categories = await store.dispatch('fetchCategories');
        const selectedCategory = categories.find(c => c.name === editedProduct.value.category);
        subcategoryOptions.value = selectedCategory ? selectedCategory.subcategories : [];
      } catch (error) {
        console.error('Error updating subcategory options:', error);
      }
    };

    onMounted(async () => {
      await fetchSellerInfo();
      try {
        const categories = await store.dispatch('fetchCategories');
        categoryOptions.value = categories.map(c => c.name);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    });

    watch(() => editedProduct.value.category, updateSubcategoryOptions);

    return {
      seller,
      user,
      products,
      headers,
      productDialog,
      editedIndex,
      editedProduct,
      formTitle,
      sellerExists,
      canAddProduct,
      coverPhoto,
      coverPhotoPreview,
      otherImages,
      otherImagesPreview,
      categoryOptions,
      subcategoryOptions,
      availableDeliveryOptions,
      weekDays,
      timeOptions,
      snackbar,
      saveSeller,
      openAddProductDialog,
      editProduct,
      closeProductDialog,
      saveProduct,
      deleteProduct,
      updateProductStatus,
      onCoverPhotoChange,
      removeCoverPhoto,
      addOtherImageInput,
      onOtherImageChange,
      removeOtherImage,
      removeExistingOtherImage,
      addEvent,
      removeEvent,
      addPickupSchedule,
      removePickupSchedule,
      addDeliverySchedule,
      removeDeliverySchedule,
      addFarmTourSchedule,
      removeFarmTourSchedule,
      addOption,
      removeOption,
      updateSubcategoryOptions,
      checkUrlAvailability,
      urlErrorMessage
    };
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}

:deep(.v-data-table) {
  background-color: transparent;
}
</style>
