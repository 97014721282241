<!-- ./frontend/src/components/BecomeSeller.vue -->
<template>
  <div class="d-flex justify-center px-4">
    <v-btn
      variant="tonal"
      color="white"
      class="mt-4 mb-8 py-6"
      height="64"
      @click="$router.push('/become-seller')"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">mdi-storefront-plus</v-icon>
        <span>Become a seller</span>
      </div>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BecomeSeller'
}
</script>