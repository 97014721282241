<template>
  <v-container :class="{ 'pa-0': $vuetify.display.smAndDown }">
    <!-- Popular Categories Section -->
    <SliderCategory :categories="categories" />

    <!-- Featured Content -->
    <SliderFeature :items="featuredItems" />

    <!-- Best Sellers Section -->
    <SliderSeller :items="localFarms" />
  </v-container>
</template>

<script>
import { ref } from 'vue';
import SliderCategory from '@/components/SliderCategory.vue';
import SliderFeature from '@/components/SliderFeature.vue';
import SliderSeller from '@/components/SliderSeller.vue';

export default {
  name: 'HomePage',
  
  components: {
    SliderCategory,
    SliderFeature,
    SliderSeller
  },

  setup() {
    const categories = ref([
      { 
        title: 'Fruit & Veggies',
        image: require('@/assets/category-icons/produce.png'),
        route: '/category/fruit-vegetables'
      },
      {
        title: 'Daily & Eggs',
        image: require('@/assets/category-icons/dairy.png'),
        route: '/category/dairy-eggs'
      },
      {
        title: 'Meat & Seafood',
        image: require('@/assets/category-icons/meat.png'),
        route: '/category/meat-fish'
      },
      {
        title: 'Herbs & Spices',
        image: require('@/assets/category-icons/herbs-spices.png'),
        route: '/category/herbs-spices'
      },
      {
        title: 'Pantry',
        image: require('@/assets/category-icons/pantry.png'),
        route: '/pantry'
      },
      {
        title: 'Health & Wellness',
        image: require('@/assets/category-icons/health.png'),
        route: '/category/health-wellness'
      },
      {
        title: 'Baby & Kids',
        image: require('@/assets/category-icons/baby.png'),
        route: '/category/baby-kids'
      },
      {
        title: 'Pets',
        image: require('@/assets/category-icons/pet.png'),
        route: '/category/pet-care'
      }
    ]);

    const featuredItems = ref([
      {
        title: 'SHOP YOUR LOCAL FARMS',
        image: require('@/assets/featured/shopLocalFarms.png'),
        route: '/search?q=farm',
        tag: 'NEW'
      },
      {
        title: 'BECOME A VERIFIED SELLER',
        image: require('@/assets/featured/becomeASeller.png'),
        route: '/become-seller',
        tag: 'FEATURED'
      },
      {
        title: 'GET COUPONS',
        subtitle: "REFER FRIENDS & FAMILY",
        image: require('@/assets/featured/coupons.png'),
        route: '/coupon',
        tag: 'DISCOUNTS'
      },
      {
        title: 'AFFILIATE LINKS',
        subtitle: 'GET PAID',
        image: require('@/assets/category-icons/other.png'),
        route: '/affiliate',
        tag: 'FEATURED'
      },
      {
        title: 'LOQUO MEMBERSHIP',
        subtitle: 'DISCOUNTS & EXCLUSIVES',
        image: require('@/assets/featured/membership.png'),
        route: '/member',
        tag: 'DISCOUNTS'
      }
    ]);

    const localFarms = ref([
      {
        title: 'POLK PASTURES',
        subtitle: 'Hammond, ON',
        image: require('@/assets/category-icons/other.png'),
        route: '/product/organic-eggs',
        tag: 'NEW'
      },
      {
        title: 'BEARBOOK GAME MEAT',
        subtitle: 'Ottawa, ON',
        image: require('@/assets/category-icons/other.png'),
        route: '/product/grass-fed-beef',
        tag: 'TOP RATED'
      },
      {
        title: 'ANDREWS APPLES',
        subtitle: 'Ottawa, ON',
        image: require('@/assets/category-icons/other.png'),
        route: '/product/fresh-produce',
        tag: ''
      },
      {
        title: 'STUARTS STRAWBERRIES',
        subtitle: 'Kempville, ON',
        image: require('@/assets/category-icons/other.png'),
        route: '/product/dairy-bundle',
        tag: 'NEW'
      }
    ]);

    return {
      categories,
      localFarms,
      featuredItems
    };
  }
};
</script>

<style scoped>
.v-container {
  max-width: 100%;
}
</style>