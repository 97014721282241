<!-- ./frontend/src/components/search/SearchShippingFilter.vue -->
<template>
  <div class="ships-to-me-container d-flex me-2">
    <!-- Left Pill - Toggle -->
    <v-chip
      :color="shipsToMe ? 'primary' : undefined"
      :variant="shipsToMe ? 'flat' : 'outlined'"
      :disabled="!userLocalShippingZone && shippingType === 'local'"
      class="toggle-pill me-n1 filter-chip"
      @click="handleShipsToMeToggle"
    >
      <div class="d-flex align-center">
        <v-checkbox
          :model-value="shipsToMe"
          @update:model-value="handleShipsToMeToggle"
          hide-details
          density="compact"
          class="me-2"
          :disabled="!userLocalShippingZone && shippingType === 'local'"
        />
        <span class="pe-2">Ships to me</span>
      </div>
    </v-chip>

    <!-- Right Pill - Shipping Type -->
    <v-chip
      class="type-pill filter-chip"
      :class="{
        'connected-active': shipsToMe,
        'connected-inactive': !shipsToMe
      }"
      :variant="!shipsToMe ? 'outlined' : undefined"
      :disabled="!shipsToMe"
      @click="toggleShippingType"
    >
      <div class="d-flex align-center px-2">
        <span class="shipping-type-text me-1">
          {{ shippingType === 'local' ? 'Local' : 'Canada' }}
        </span>
        <v-icon size="small">
          mdi-swap-horizontal
        </v-icon>
      </div>
    </v-chip>
  </div>
</template>

<script>
// Script remains the same
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchShippingFilter',

  props: {
    shipsToMe: {
      type: Boolean,
      required: true
    },
    shippingType: {
      type: String,
      required: true,
      validator: value => ['local', 'canada-wide'].includes(value)
    },
    userLocalShippingZone: {
      type: [Object, null],
      default: null
    }
  },

  emits: ['update:shipsToMe', 'update:shippingType', 'showLocationDialog'],

  setup(props, { emit }) {
    const handleShipsToMeToggle = () => {
      if (!props.shipsToMe && !props.userLocalShippingZone && props.shippingType === 'local') {
        emit('showLocationDialog')
        return
      }
      emit('update:shipsToMe', !props.shipsToMe)
    }

    const toggleShippingType = () => {
      if (!props.shipsToMe) return
      
      const newType = props.shippingType === 'local' ? 'canada-wide' : 'local'
      
      if (newType === 'local' && !props.userLocalShippingZone) {
        emit('showLocationDialog')
        return
      }
      
      emit('update:shippingType', newType)
    }

    return {
      handleShipsToMeToggle,
      toggleShippingType
    }
  }
})
</script>

<style scoped>
.ships-to-me-container {
  display: inline-flex;
  align-items: center;
  min-width: 200px;
}

.toggle-pill {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 36px;
  z-index: 1;
  background-color: white !important;
  min-width: 140px !important;
}

.toggle-pill.v-chip--variant-flat {
  background-color: rgb(var(--v-theme-primary)) !important;
}

.type-pill {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 36px;
  margin-left: -1px;
  min-width: 80px !important;
}

.connected-active {
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-on-primary));
}

.connected-inactive {
  background-color: rgba(var(--v-theme-surface-variant), 0.25);
  border-left: none !important;
}

.shipping-type-text {
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 48px;
  text-align: center;
}

.filter-chip {
  height: 36px !important;
  padding-right: 8px !important;
}

.filter-chip :deep(.v-checkbox) {
  margin: 0 !important;
  padding: 0 !important;
}

.filter-chip :deep(.v-selection-control) {
  min-height: unset !important;
  width: auto !important;
}

.filter-chip :deep(.v-selection-control__wrapper) {
  margin-right: 4px !important;
  width: auto !important;
}

.filter-chip :deep(.v-checkbox-btn) {
  width: auto !important;
}

@media (max-width: 599px) {
  .ships-to-me-container {
    max-width: 100%;
    margin-right: 0.5rem !important;
    min-width: 100%;
  }

  .toggle-pill,
  .type-pill {
    flex: 1;
    justify-content: center;
  }

  .shipping-type-text {
    min-width: 48px;
    text-align: center;
  }
}
</style>