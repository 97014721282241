<template>
  <v-navigation-drawer permanent>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Hi, {{ user?.name || 'Seller' }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item to="/seller-info">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Your Seller Info</v-list-item-title>
        </v-list-item>

        <v-list-item :to="sellerPageUrl">
          <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Your Seller Page</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'SellerAccountDrawer',
  setup() {
    const store = useStore();
    const selectedItem = ref(0);
    const user = computed(() => store.state.user);
    const seller = computed(() => {
      const sellerArray = store.state.seller;
      return Array.isArray(sellerArray) && sellerArray.length > 0 ? sellerArray[0] : null;
    });

    const sellerPageUrl = computed(() => {
      console.log('Computing sellerPageUrl in SellerAccountDrawer:', seller.value);
      if (seller.value && seller.value.url) {
        return `/seller/${seller.value.url}`;
      }
      return '/seller-info';
    });

    const fetchSellerData = async () => {
      if (user.value && user.value.sellerId) {
        try {
          console.log('Fetching seller data in SellerAccountDrawer');
          const sellerData = await store.dispatch('fetchSellersByUserID', user.value._id);
          console.log('Seller data fetched in SellerAccountDrawer:', sellerData);
          if (sellerData.length > 0) {
            await store.dispatch('setSeller', sellerData);
            console.log('Seller set in store from SellerAccountDrawer:', store.state.seller);
          }
        } catch (error) {
          console.error('Error fetching seller data in SellerAccountDrawer:', error);
        }
      }
    };

    onMounted(async () => {
      console.log('SellerAccountDrawer mounted');
      await fetchSellerData();
    });

    return {
      selectedItem,
      user,
      seller,
      sellerPageUrl,
    };
  },
};
</script>
