<!-- ./frontend/src/components/CartControlsButton.vue -->
<template>
 <div class="cart-controls d-flex flex-column">
   <v-chip
     v-if="showBulkDiscount"
     color="error"
     size="x-small"
     variant="flat"
     @click="handleBulkAdd"
     class="bulk-chip mb-2 align-self-end"
   >
     <v-icon start icon="mdi-tag" size="x-small"/>
     Add {{ amountUntilNextDiscount }} to save ${{ formatPrice(nextDiscountMinSavings) }}
   </v-chip>

   <template v-if="cartQuantity > 0">
     <v-btn
       color="primary"
       class="cart-pill"
       variant="flat"
       density="comfortable"
       block
     >
       <div class="d-flex align-center justify-space-between cart-controls-inner">
         <v-btn
           variant="text"
           :icon="cartQuantity === 1 ? 'mdi-trash-can' : 'mdi-minus'"
           color="white"
           @click.stop="handleQuantityDecrease"
           class="control-button"
           density="comfortable"
         />
         <span class="text-white quantity-text">{{ cartQuantity }}</span>
         <v-btn
           variant="text"
           icon="mdi-plus"
           color="white"
           @click.stop="handleQuantityIncrease"
           class="control-button"
           density="comfortable"
         />
       </div>
     </v-btn>
   </template>
   <template v-else>
     <v-btn
       color="primary"
       class="cart-pill"
       variant="flat"
       density="comfortable"  
       @click="handleAddToCart"
       block
     >
       <v-icon icon="mdi-plus" color="white" class="mr-2" />
       Add to Cart
     </v-btn>
   </template>
 </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
 name: 'CartControlsButton',

 props: {
   product: {
     type: Object,
     required: true
   },
   selectedOption: {
     type: Object,
     default: null
   }
 },

 emits: ['show-message'],

 setup(props, { emit }) {
   const store = useStore()

   const cartQuantity = computed(() => 
     store.getters['cart/getCartItemQuantity'](props.product?._id)
   )

   const showBulkDiscount = computed(() => {
     if (!props.product?.bulkPriceGrid?.length) return false
     const nextTier = props.product.bulkPriceGrid
       .filter(grid => grid.minQuantity > cartQuantity.value)
       .sort((a, b) => a.minQuantity - b.minQuantity)[0]
     return nextTier !== undefined && nextTier.minQuantity > cartQuantity.value
   })

   const amountUntilNextDiscount = computed(() => {
     if (!props.product?.bulkPriceGrid?.length) return 0
     const nextTier = props.product.bulkPriceGrid
       .filter(grid => grid.minQuantity > cartQuantity.value)
       .sort((a, b) => a.minQuantity - b.minQuantity)[0]
     return nextTier ? nextTier.minQuantity - cartQuantity.value : 0
   })

   const nextDiscountMinSavings = computed(() => {
     if (!props.product?.bulkPriceGrid?.length) return 0
     const nextTier = props.product.bulkPriceGrid
       .filter(grid => grid.minQuantity > cartQuantity.value)
       .sort((a, b) => a.minQuantity - b.minQuantity)[0]
     return nextTier ? nextTier.discountPerUnit * nextTier.minQuantity : 0
   })

   const formatPrice = (price) => Number(price).toFixed(2)

   const handleAddToCart = async () => {
     try {
       await store.dispatch('cart/addToCart', {
         product: { ...props.product, option: props.selectedOption }
       })
       emit('show-message', { text: 'Added to cart', type: 'success' })
     } catch (error) {
       emit('show-message', { text: 'Error adding to cart', type: 'error' })
     }
   }

   const handleQuantityIncrease = async (event) => {
     event.stopPropagation()
     try {
       await store.dispatch('cart/updateCartItemQuantity', {
         productId: props.product._id,
         quantity: cartQuantity.value + 1
       })
     } catch (error) {
       emit('show-message', { text: 'Error updating quantity', type: 'error' })
     }
   }

   const handleQuantityDecrease = async (event) => {
     event.stopPropagation()
     try {
       if (cartQuantity.value === 1) {
         await store.dispatch('cart/removeFromCart', props.product._id)
       } else {
         await store.dispatch('cart/updateCartItemQuantity', {
           productId: props.product._id,
           quantity: cartQuantity.value - 1
         })
       }
     } catch (error) {
       emit('show-message', { text: 'Error updating quantity', type: 'error' })
     }
   }

   const handleBulkAdd = async () => {
     try {
       const newQuantity = cartQuantity.value > 0 
         ? cartQuantity.value + amountUntilNextDiscount.value
         : amountUntilNextDiscount.value

       await store.dispatch('cart/updateCartItemQuantity', {
         productId: props.product._id,
         quantity: newQuantity
       })
     } catch (error) {
       emit('show-message', { text: 'Error adding bulk quantity', type: 'error' })
     }
   }

   return {
     cartQuantity,
     showBulkDiscount,
     amountUntilNextDiscount,
     nextDiscountMinSavings,
     formatPrice,
     handleAddToCart,
     handleQuantityIncrease,
     handleQuantityDecrease,
     handleBulkAdd
   }
 }
}
</script>

<style scoped>
.cart-controls {
 width: 100%;
}

.cart-pill {
 width: 100%;
 height: 44px !important;
 border-radius: 22px;
 padding: 0 4px !important;
 transition: background-color 0.2s ease;
}

.cart-controls-inner {
 width: 100%;
 padding: 0 4px;
}

.control-button {
 min-width: 72px !important;
 width: 72px !important;
 height: 36px !important;
 border-radius: 18px;
 transition: transform 0.2s ease;
}

.control-button:hover {
 transform: scale(1.1);
}

.quantity-text {
 min-width: 48px;
 text-align: center;
 font-size: 1.1rem;
}

.bulk-chip {
 font-size: 0.625rem;
 height: 20px;
 opacity: 0.9;
 white-space: nowrap;
}

@media (max-width: 600px) {
 .cart-pill {
   height: 32px !important;
 }

 .control-button {
   height: 32px !important;
 }
}
</style>